import {Button,Checkbox,FormControl,IconButton,InputLabel,LinearProgress,ListItemText,MenuItem,Modal,Select,TextField,TextareaAutosize,Tooltip,Typography,RadioGroup,FormControlLabel,Radio,Card,CardContent, ListItemIcon,} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { resetresolvenew } from "../../../helper/ResolveReducer";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";
import store from "../../../store";
import { format } from "date-fns";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InputAdornment from "@mui/material/InputAdornment";
import {alldispositionData,blueCoastaddannualBilling,blueCoastaddunderpayment,BlueCoastDataTrancefer,blueCoastGetPriorityData,bluecoastLeadStatusChang,BlueCoastlist,blueCoastNotes,blueCoastNotesrep,blueCoastNotesViewTable,BlueCoastPayment,blueCoastSetPriority,BlueCoastSpecialModal,clearAllDisposition,clearAnnualBillingData,clearbluecoastDataTranceferStatus,clearBlueCoastList,clearBlueCoastNotesList,clearBlueCoastRepNotesList,clearcurrentdispflag,cleardisposition,clearDispositionData,clearDispositionTaskList,clearNotesViewList,clearPriorityData,clearUnderPaymentData,dispositionCalllist,dispositionDataLoad,DispositonTaskList,exportCSVtoUserMail,getbluecoastannualbilling,getbluecoastunderpayment,getDisposition,getothersplicalpersons,getownerShipTransferData,getsplicalpersons,mailSendToAEProd,SendMailFulList,statusChangeTask,} from "./BlueCoastReducer";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "../../../form/Form";
import {clearCustomButtonClicked,clearTableData,deleteTempSearchEndpoint,resetTempBody,setEnableRowLoader,setReloadTableFlag,unmountTable,} from "../../../listing/listReducer";
import { setSnackbar } from "../../Layout/layoutReducer";
import moment from "moment";
import {resolvefunction,timeConverter,} from "../../../helper/helperFunctions";
import {removeField,resetForm,resetFormData,resetautocompletelist,setField,} from "../../../form/formReducer";
import AddIcon from "@mui/icons-material/Add";
import {addlandingpageninedatabluecosde,clearadddsuccessflag,} from "../../Frontend/LandingPage/LandingpageReducer";
import {useLocation, useNavigate,useSearchParams } from "react-router-dom";
import { setIpinfo, setLogedinUserInfo, stepUpFncEcecutn } from "../../login/loginReducer";
import { getleaddata } from "../LeadManagement/LeadReducer";
import DNotes from "../Dashboard/DNotes";
import { Cookies } from "react-cookie";
import { cloneDeep } from "lodash";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrioritySetForPharmacyAspire from "./PrioritySetForPharmacyAspire";
import TransFerOwnerForPharmacyAspire from "./TransFerOwnerForPharmacyAspire";
import ManageDispositionForPharmacyAspire from "./ManageDispositionForPharmacyAspire";
import DispositionCycleForPharmacyAspire from "./DispositionCycleForPharmacyAspire";
import MeetingListForPharmacyAspire from "./MeetingListForPharmacyAspire";
import SnackBarComp from "../../../commoncomponents/snackbar/snackBar";
import PaymentAeRepNotes from "./PaymentAeRepNotes";
import PaymentProspectNotes from "./PaymentProspectNotes";
import PaymentIpInfo from "./PaymentIpInfo";
import PaymentTotalGrossInsu from "./PaymentTotalGrossInsu";
import PaymentOpportunityUnderpayment from "./PaymentOpportunityUnderpayment ";
import DispositionAfterContract from "./DispositionAfterContract";
import NotesView from "./NotesView";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SendContactMailList from "./SendContactMailList/SendContactMailList";
import DispositionLeadsSummary from "./DispositionLeadsSummary/DispositionLeadsSummary";
import DispositionTaskList from "./DispositionTaskList/DispositionTaskList";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { clearTablerowData } from "../../../listing/listReducer";
import AddTaskForm from "./AddTaskForm";
import TaskStatusChangeModal from "../../../commoncomponents/modal/TaskStatusChangeModal";
import BusinessManagerModal from "./BusinessManagerModal";
import StateArray from "../../../assets/json/state";
import SearchKey from "./SearchKeyword/SearchKey";
import { bookedSlotListData } from "../../Calendar/calenderReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function BlueCoastList(props) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.getAll().token;
  const inputRef = useRef(null);

  const [searchParams] = useSearchParams();
  let leadid = searchParams.get('leadid');

  const route = useLocation();






  // ********************Add/Edit bluecoastLead**********************

  const [addformModalOpen, setAddFormModalOpen] = useState(false);
  // const [taskaddcond, setTaskaddcond] = useState(false);
  const [addformBlueCoastPharmacyOpen, setAddformBlueCoastPharmacyOpen] =
    useState(false);

  // ********************for Pharmacy Bluecoast and aspire**********************

  const [previewModal, setPreviewModal] = useState(false);
  const [previewModalData, setPreviewModalData] = useState(null);
  const [notesModel, setNotesModel] = useState(false);
  const [singalRowData, setSingalRowData] = useState({});

  /******************************Disposition Task Modal****************** */
  let user = useSelector((state) =>
    state.loginSlice?.userInfo ? state.loginSlice.userInfo : null
  );

  useEffect(() => {
    console.log("user====>>>>", user?.email)
  }, [JSON.stringify(user)])
  
  const [dispositionTaskModal, setDispositionTaskModal] = useState(false);
  const [currentDispositionData, setCurrentDispositonData] = useState("");

  /******************For Check box option************* */
  const [checkBoxOne, setCheckBoxOne] = useState(true);
  const [checkBoxTwo, setCheckBoxTwo] = useState(true);
  const [checkBoxThree, setCheckBoxThree] = useState(true);
  const [checkBoxFour, setCheckBoxFour] = useState(true);
  const [checkBoxFive, setCheckBoxFive] = useState(true);
  const [checkBoxSix, setCheckBoxSix] = useState(true);
  const [checkBoxSeven, setCheckBoxSeven] = useState(true);


  const [handleModalPreview, setHandleModalPreview] = useState(false);

  /*********************Business Managers Modal*************** */

  const [businessManagerModalOpen, setBusinessManagerModalOpen] =
    useState(false);

  const businessManagerButtonClicked = useSelector((state) =>
    state.tableSlice.customButtonClicked?.bluecoastdata?.business_manager
      ? state.tableSlice.customButtonClicked.bluecoastdata.business_manager
      : false
  );

  useEffect(() => {
    if (businessManagerButtonClicked) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: rowData._id,
          load: true,
        })
      );
      setBusinessManagerModalOpen(true);

      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "bluecoastdata",
              rowId: rowData._id,
              load: false,
            })
          ),
        1000
      );
    }
  }, [businessManagerButtonClicked]);

  const businessManagerModalClose = () => {
    setBusinessManagerModalOpen(false);
  };

  const handleClosePreview = () => {
    setPreviewModal(false);
    setHandleModalPreview(false);

    setsaveRowdata(null);
    setAddedForId({});
    setPaymentPreviewModal(false);
    setPaymentRowData({});
  };


  const printFunction = (val) => {
    const input = document.getElementById("template1");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);

      const width = pdf.internal.pageSize.getWidth();
      const height = (imgProps.height * width) / imgProps.width;
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${val}_details.pdf`);
    });
  };

  const addBlueCoastPharmacyFormOpen = () => {
    // console.log("hit opopopo");
    setAddformBlueCoastPharmacyOpen(true);
  };
  const addBlueCoastPharmacyFormClose = () => {
    setAddformBlueCoastPharmacyOpen(false);
    setEditBlcstDataLead(null);
    addFormBlueCstPharmacyLead.current.fields[2].disabled = false;


    for (let k in addFormBlueCstPharmacyLead.current.fields) {
      let fieldnew = cloneDeep(addFormBlueCstPharmacyLead.current.fields[k]);
      fieldnew.defaultValue = undefined;
      addFormBlueCstPharmacyLead.current.fields[k] = fieldnew;
    }
    for (let k in addFormBlueCstLead.current.fields) {
      let field = cloneDeep(addFormBlueCstLead.current.fields[k]);
      field.defaultValue = undefined;
      addFormBlueCstLead.current.fields[k] = field;
    }
    // setTimeout(() => dispatch(removeField({ formId: "addBlueCstLead", name: "other" })), 1000)
    setTimeout(
      () =>
        dispatch(
          removeField({ formId: "addBlueCstPharmacyLead", name: "other" })
        ),
      1000
    );
    seteditvalueset(false);
    dispatch(clearadddsuccessflag());
  };

  const addFormOpen = () => {
    setAddFormModalOpen(true);
  };
  const addFormClose = () => {
    setAddFormModalOpen(false);
    setEditBlcstDataLead(null);
    addFormBlueCstLead.current.fields[2].disabled = false;

    for (let k in addFormBlueCstLead.current.fields) {
      let field = cloneDeep(addFormBlueCstLead.current.fields[k]);
      field.defaultValue = undefined;
      addFormBlueCstLead.current.fields[k] = field;
    }
    for (let k in addFormBlueCstPharmacyLead.current.fields) {
      let fieldnew = cloneDeep(addFormBlueCstPharmacyLead.current.fields[k]);
      fieldnew.defaultValue = undefined;
      addFormBlueCstPharmacyLead.current.fields[k] = fieldnew;
    }
    dispatch(clearadddsuccessflag());
  };
  const [editBlcstDataLead, setEditBlcstDataLead] = useState(null);

  /************************Ip-Info Modal********************* */

  const [ipInfoModal, setIpInfoModal] = useState(false);
  const [alreadyaddfield, setalreadyaddfield] = useState(false);
  const [ipInfo, setIpInfo] = useState(null);
  const handleClose = () => {
    setIpInfoModal(false);
    setIpInfo(null);
  };
  const [addedForId, setAddedForId] = useState({});
  const [repPageSlug, setRepPageSlug] = useState(null);
  const [custombtnadd, setcustombtnadd] = useState(false);

  const [menuSelect, setMenuSelect] = useState(1);

  const [loadingkey, setLoadingkey] = useState(false);

  //  ************************************Notes Modal*********************
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const [statesForSpecialModal, setStatesForSpecialModal] = useState("");

  const [statesForModalText, setStatesForModalText] = useState("");

  /**************************Manage Dist Position Modal**************  */

  const [manageDistModalOpen, setManageDistOpenModal] = useState(false);
  const [manageDist, setManageDist] = React.useState("New Lead");

  // **********************Set Priority Modal***************
  const [priorityModalOpen, setPriorityModalOpen] = useState(false);
  const [managePriority, setmanagePriority] = React.useState("");

  const [setprioritydatainfo, setPrioritydatainfo] = useState(null);
  const [priorityLoader, setPriorityLoader] = useState(false);

  const [specialEntityModal, setspecialEntityModal] = useState(false);
  const [specialEntityInfo, setspecialEntityInfo] = useState(null);
  const [actionhitloader, setactionhitloader] = useState(false);
  const specialModalClose = () => setspecialEntityModal(false);

  const [buttonClickedfor, setbuttonClickedfor] = useState(null);
  const [buttonClickedforcal, setbuttonClickedforcal] = useState(null);
  const inputTextProspect = useRef(null);
  const textareayvalue = useRef(null);
  const textareayvalueforrep = useRef(null);


  const tranceFormOwershipText = useRef(null);
  const tranceFormOwershipTextValue = useRef(null);

  const [checkboxvalues, setcheckboxvalues] = useState([]);
  const [scratchaddnoteflag, setscratchaddnoteflag] = useState(false);
  const [allNotesModal, setAllNotesModal] = useState(false); /*****************for all notes modal**** */
  const [openAllNotesModalLoader, setOpenAllNotesModalLoader] = useState(false);
  const [dispositionSucessLoader, setDispositionSucessLoader] = useState(false);
  const [dispositionTaskSucessLoader, setDispositionTaskSucessLoader] =useState(false);

  const [dispositionSelectValueModal, setDispositionSelectValueModal] =
    useState(false);
  const [allDispositionModalOpen, setAllDispositionModalOpen] = useState(false);
  const [dispositionCallListModalOpen, setDispositionCallListModalOpen] =
    useState(false);
  const [timeformeeting, settimeformeeting] = useState(null);

  const [deliteModal, setDeleteModal] = useState(false);

  const [bataTranceferModalState, setBataTranceferModalState] = useState(false); // data transfer Modal state
  // const [saveRowdata,setsaveRowdata]=useState(null)

  const navigate = useNavigate();
  // const [allDispositionModalData, setAllDispositionModalData] = useState([]);

  // ***********************Transfer Ownership Modal***********************
  const [transferOwnershipModlaOpen, setTransferOwnershipModlaOpen] =
    useState(false);
  const [ownerShipLoader, setOwnerShipLoader] = useState(false);
  const [sendEmailtoSpecialEntity, setSendEmailtoSpecialEntity] =
    useState(false);
  const [sendEmailtoTravisModal, setSendEmailtoTravisModal] = useState(false);
  const [sendContractTo, setSendContractTo] = useState(null);
  const [loader, setLoader] = useState(false);

  let bluecoastdata = useSelector((state) =>
    state.BlueCoastSlice?.bluecoastdata &&
      state.BlueCoastSlice?.bluecoastdata.length > 0
      ? state.BlueCoastSlice.bluecoastdata
      : []
  );
  let bluecoastPymtData = useSelector((state) =>
    state.BlueCoastSlice?.bluecoastPymtData
      ? state.BlueCoastSlice.bluecoastPymtData
      : {}
  );



useEffect(() => {
 if (userInfo?.roles?.special_entities == 1) {
  console.log("userInfo=======>>>>>>", userInfo);

  dispatch(stepUpFncEcecutn())
 }
}, [])









  // console.log("bluecoastPymtData", bluecoastPymtData);
  // ***********************Pharmacy Bluecoast And Aspire***********************

  const leaddataforlist = useSelector((state) =>
    state.LeadReducer?.leaddata && state.LeadReducer?.leaddata.length > 0
      ? state.LeadReducer.leaddata
      : []
  );

  const leadLoading = useSelector((state) =>
    state.LeadReducer?.loading ? state.LeadReducer.loading : false
  );

  const dataforalltask = useSelector((state) =>
    state.BlueCoastSlice?.dispositontasklistdata &&
      state.BlueCoastSlice?.dispositontasklistdata.length > 0
      ? state.BlueCoastSlice.dispositontasklistdata
      : []
  );

  const alltaskloading = useSelector((state) =>
    state.BlueCoastSlice?.dispositionTaskLoader
      ? state.BlueCoastSlice.dispositionTaskLoader
      : false
  );
  const previewData = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata &&
      Object.keys(state.tableSlice.tempRowData.bluecoastdata).length > 0
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );
  const viewBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata &&
      state.tableSlice?.customButtonClicked?.bluecoastdata?.viewBtnAction
      ? state.tableSlice?.customButtonClicked?.bluecoastdata?.viewBtnAction
      : false
  );

  const operational_dispositionClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata
      ?.operational_disposition
      ? state.tableSlice.customButtonClicked.bluecoastdata
        .operational_disposition
      : false
  );

  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/businesslead-list"]
      ? state.ResolveReducer.resolved["/businesslead-list"]
      : false
  );
  const loadinginbluecoast = useSelector((state) =>
    state.BlueCoastSlice?.loading ? state.BlueCoastSlice.loading : false
  );
  const otherspicialusers = useSelector((state) =>
    state.BlueCoastSlice?.othersplicialdata &&
      state.BlueCoastSlice?.othersplicialdata.length > 0
      ? state.BlueCoastSlice.othersplicialdata
      : []
  );
  let spicialusersall = useSelector((state) =>
    state.BlueCoastSlice?.splicialuserdata &&
      state.BlueCoastSlice?.splicialuserdata.length > 0
      ? state.BlueCoastSlice.splicialuserdata
      : []
  );





  const [rowIdEvent, setRowIdEvent] = useState(null)
  useEffect(() => {
    if (previewData && previewData !== null && previewData !== undefined) {
      setRowIdEvent(previewData)
      console.log("rowIdEvent=========>>>>", previewData)

    }
  }, [JSON.stringify(previewData)])

  useEffect(() => {
    console.log("rowIdEvent=========>>>>", rowIdEvent)
  }, [])




  // *********************************Add Form Selector Clicked*********************************
  const otherRadioButtonClicked = useSelector((state) =>
    state.formSlice?.formData?.addBlueCstLead &&
      Object.keys(state.formSlice.formData.addBlueCstLead)
      ? state.formSlice.formData.addBlueCstLead
      : undefined
  );

  const submissionState = useSelector((state) =>
    state.formSlice?.formSubmissionState?.addBlueCstLead
      ? state.formSlice?.formSubmissionState?.addBlueCstLead
      : 0
  );
  const loading = useSelector((state) =>
    state.LandingpagefontReducer?.bluecoseloader
      ? state.LandingpagefontReducer?.bluecoseloader
      : false
  );
  const leadaddeddata = useSelector((state) =>
    state.LandingpagefontReducer?.bluecouseadddata
      ? state.LandingpagefontReducer?.bluecouseadddata
      : null
  );
  const leadaddeddataFlag = useSelector((state) =>
    state.LandingpagefontReducer?.adddsuccessflag
      ? state.LandingpagefontReducer?.adddsuccessflag
      : false
  );
  const ipinfo = useSelector((state) =>
    state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0
      ? state.loginSlice.ipInfo
      : null
  );

  // console.log("ipinfo====>>>>", ipinfo);

  const formInputData = useSelector((state) =>
    state.formSlice.formData.addBlueCstLead
      ? state.formSlice.formData.addBlueCstLead
      : null
  );

  const formInputDatanew = useSelector((state) =>
    state.formSlice.formData.addBlueCstPharmacyLead
      ? state.formSlice.formData.addBlueCstPharmacyLead
      : null
  );
  const submissionStatenew = useSelector((state) =>
    state.formSlice?.formSubmissionState?.addBlueCstPharmacyLead
      ? state.formSlice?.formSubmissionState?.addBlueCstPharmacyLead
      : 0
  );
  const otherRadioButtonClickednew = useSelector((state) =>
    state.formSlice?.formData?.addBlueCstPharmacyLead &&
      Object.keys(state.formSlice.formData.addBlueCstPharmacyLead)
      ? state.formSlice.formData.addBlueCstPharmacyLead
      : undefined
  );

  const editBlCoastBtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.editBlCoastLead
      ? state.tableSlice.customButtonClicked.bluecoastdata.editBlCoastLead
      : false
  );
  const editDataLead = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  /******************************************Ip-Info Button Clicked*************** */

  const ipInfoButton = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.previewIpInfo
      ? state.tableSlice.customButtonClicked.bluecoastdata.previewIpInfo
      : false
  );

  const ipInfoButton1 = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.lead_data?.previewIpInfo
      ? state.tableSlice.customButtonClicked.lead_data.previewIpInfo
      : false
  );

  const rowData = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  const rowDataAllTask = useSelector((state) =>
    state.tableSlice?.tempRowData?.alltaskdata
      ? state.tableSlice.tempRowData.alltaskdata
      : null
  );

  const [saveRowAllTask, setSaveRowAllTask] = useState(null);

  useEffect(() => {
    if (rowDataAllTask && Object.keys(rowDataAllTask).length > 0) {
      setSaveRowAllTask(rowDataAllTask);
    }

    // console.log("saveRowdata>>>>>>>>>>>>>>", saveRowAllTask);
  }, [JSON.stringify(rowDataAllTask)]);

  const userInfo = useSelector((state) =>
    state.loginSlice && state.loginSlice?.userInfo
      ? state.loginSlice?.userInfo
      : null
  );

  // console.log("rowData", rowData)

  // **********************button and data fetching for special entity action icons****************************

  const acceptbtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.accept
      ? state.tableSlice.customButtonClicked.bluecoastdata.accept
      : false
  );
  const accceptdata = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  const declinebtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.decline
      ? state.tableSlice.customButtonClicked.bluecoastdata.decline
      : false
  );
  const declinedata = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  const setdiscoveryClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.setdiscoverycall
      ? state.tableSlice.customButtonClicked.bluecoastdata.setdiscoverycall
      : false
  );
  const setDiscoverydata = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  const canceldiscoveryClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.canceldiscoverycall
      ? state.tableSlice.customButtonClicked.bluecoastdata.canceldiscoverycall
      : false
  );
  const cancelDiscoverydata = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  // *****************************************Notes Button Clicked************************
  const notesButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.notes
      ? state.tableSlice.customButtonClicked.bluecoastdata.notes
      : false
  );

  const notesClick = useSelector((state) =>
    state?.tableSlice?.customButtonClicked?.lead_data?.notes
      ? state.tableSlice.customButtonClicked.lead_data.notes
      : false
  );

  const notesButtonClickedforrep = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.notes_for_rep
      ? state.tableSlice.customButtonClicked.bluecoastdata.notes_for_rep
      : false
  );

  const notesButtonClickedforrep1 = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.lead_data?.notes_for_rep
      ? state.tableSlice.customButtonClicked.lead_data.notes_for_rep
      : false
  );

  const notesButtonClickedforrepROWDATA = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );

  //*******************************Delete Leade********************************** */
  const delete_lead = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.delete_lead
      ? state.tableSlice.customButtonClicked.bluecoastdata.delete_lead
      : false
  );

  // console.log("delete_lead============>>>>>>>>>>", delete_lead);
  // *************************Rep notes data*****
  const bluecoastrepnotedata = useSelector((state) =>
    state.BlueCoastSlice?.repnotebluecoast &&
      state.BlueCoastSlice?.repnotebluecoast.length > 0
      ? state.BlueCoastSlice.repnotebluecoast
      : []
  );
  const loadinginRepnotes = useSelector((state) =>
    state.BlueCoastSlice?.repNoteloading
      ? state.BlueCoastSlice.repNoteloading
      : false
  );

  // ********************************Notes data:********************************
  const bluecoastnotedata = useSelector((state) =>
    state.BlueCoastSlice?.bluecoastnotesdatas &&
      state.BlueCoastSlice?.bluecoastnotesdatas.length > 0
      ? state.BlueCoastSlice.bluecoastnotesdatas
      : []
  );

  // console.log("bluecoastnotedata=====>>", bluecoastnotedata);
  const loadinginbluecoastnotes = useSelector((state) =>
    state.BlueCoastSlice?.notesloading
      ? state.BlueCoastSlice.notesloading
      : false
  );
  const notesaddfromsubmit = useSelector((state) =>
    state.formSlice?.formSubmissionState?.bluecoastleadnotes
      ? state.formSlice?.formSubmissionState?.bluecoastleadnotes
      : 0
  );
  const loadinginbluecoastallnotes = useSelector((state) =>
    state.BlueCoastSlice?.notesloading
      ? state.BlueCoastSlice.notesloading
      : false
  );

  /***********************************************Blue Coast all Notes List************ */
  const allNotesButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.allnotes
      ? state.tableSlice.customButtonClicked.bluecoastdata.allnotes
      : false
  );

  /*********************************************All disposition Modal Clicked******* */
  const allDispositionModalButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.all_dispositions
      ? state.tableSlice.customButtonClicked.bluecoastdata.all_dispositions
      : false
  );

  const allDispositionData = useSelector((state) =>
    state.BlueCoastSlice?.alldisposition &&
      state.BlueCoastSlice?.alldisposition.length > 0
      ? state.BlueCoastSlice.alldisposition
      : []
  );

  // ----------------------------data Tarcefer funtion -----------------------------------
  const dataTransferbtnClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.data_transfer
      ? state.tableSlice.customButtonClicked.bluecoastdata.data_transfer
      : false
  );
  const bluecoastDataTranceferLoader = useSelector((state) =>
    state.BlueCoastSlice?.bluecoastDataTranceferLoader
      ? state.BlueCoastSlice.bluecoastDataTranceferLoader
      : false
  );

  const [dataTrancferFlag, setDataTranceferFlag] = useState(
    "underpayment-bluecostlead"
  );
  const [chosePathModal, setChosePathModal] = useState(false);
  const [listType, setListType] = useState(null);

  // snack bar configaration

  const bluecoastDataTranceferStatus = useSelector((state) =>
    state.BlueCoastSlice?.bluecoastDataTranceferStatus
      ? state.BlueCoastSlice.bluecoastDataTranceferStatus
      : {}
  );

  const [snakebarStatus, setSnakebarStatus] = useState(false);
  const [snakebarMassage, setSnakebarMassage] = useState(null);
  const [snakebarModalStrate, setSnakebarModalState] = useState(null);

  //--------------------------Add User Button---------------------------------------

  const addUserwButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.addUser
      ? state.tableSlice.customButtonClicked.bluecoastdata.addUser
      : false
  );

  // --------------------------Notes View Button----------------------------------------
  const notesViewButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.notesView
      ? state.tableSlice.customButtonClicked.bluecoastdata.notesView
      : false
  );
  const notesViewtableData = useSelector((state) =>
    state.BlueCoastSlice?.notesViewTablesuccess &&
      state.BlueCoastSlice?.notesViewTablesuccess.length > 0
      ? state.BlueCoastSlice.notesViewTablesuccess
      : []
  );

  const [notesViewOpen, setNotesViewOpen] = useState(false);

  // ---------------------------------Leads COunt Dispostion-----------------------------------
  const dispLeadsSummryBtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.dispositionLeadSummary
      ? state.tableSlice.customButtonClicked.bluecoastdata
        .dispositionLeadSummary
      : false
  );

  const [dispLeadCountMOdalOpn, setDispLeadCountMOdalOpn] = useState(false);

  // ------------------------------Export to CSV-----------------------------------------------
  const exportCSVBtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.exportCSV
      ? state.tableSlice.customButtonClicked.bluecoastdata.exportCSV
      : false
  );

  // -------------------------Send mail to Beto--------------------------------------------
  const sendMailBetoClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.send_for_beto
      ? state.tableSlice.customButtonClicked.bluecoastdata.send_for_beto
      : false
  );
  // const sendMailRowDataBeto = useSelector((state) => state.tableSlice?.tempRowData?.bluecoastdata ? state.tableSlice.tempRowData.bluecoastdata : null);

  const sendMailTravisClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.send_for_travis
      ? state.tableSlice.customButtonClicked.bluecoastdata.send_for_travis
      : false
  );

  const [sendMailBetoModal, setSendMailBetoModal] = useState(false);
  // const [sendMailDataBeto, setSendMailDataBeto] = useState();
  const [sendMailTravisModal, setSendMailTravisModal] = useState(false);

  const handleCloseBetoModal = () => {
    setSendMailBetoModal(false);
  };

  const handleClosetravisModal = () => {
    setSendMailTravisModal(false);
  };

  // ---------------------------Sent Mail List to AEs-----------------------------------------------
  const sentMailListAEsClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.sent_mail_list_to_AEs
      ? state.tableSlice.customButtonClicked.bluecoastdata.sent_mail_list_to_AEs
      : false
  );

  const [sentMailAEsOpen, setSentMailAEsOpen] = useState(false);

  /*****************************Dispositon Task List Action Button******************************** */
  const dispositionTaskListButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.manage_dist_task_list
      ? state.tableSlice.customButtonClicked.bluecoastdata.manage_dist_task_list
      : false
  );

  const [dispoTaskListModalOpen, setDispoTaskListModalOpen] = useState(false);

  const dispTaskListClose = () => {
    setsaveRowdata(null);
    setDispoTaskListModalOpen(false);
    dispatch(clearTableData({ tableId: "managedisptask" }));
    clearTablerowData();
    clearDispositionTaskList();
  };
  // -------------------------------Annual Revenue-------------------------------------------------------
  const annualrevenueBtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.annual_revenue
      ? state.tableSlice.customButtonClicked.bluecoastdata.annual_revenue
      : false
  );

  // -------------------------------Event Listing Link --------------------------------------------
  const eventLinkBtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.event_list_link
      ? state.tableSlice.customButtonClicked.bluecoastdata.event_list_link
      : false
  );

  //------------------------Pyment Details Configration-----------------------------------------------------------------------------
  const bluecoastPaymentList = useSelector((state) =>
    state.BlueCoastSlice?.bluecoastPaymentList
      ? state.BlueCoastSlice.bluecoastPaymentList
      : []
  );
  const paymentloading = useSelector((state) =>
    state.BlueCoastSlice?.paymentloading
      ? state.BlueCoastSlice.paymentloading
      : false
  );
  const paymentbtnclickgetrowdata = useSelector((state) =>
    state.tableSlice?.tempRowData?.paymentDetials
      ? state.tableSlice.tempRowData.paymentDetials
      : {}
  );
  const previewBtn = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials &&
      state.tableSlice?.customButtonClicked?.paymentDetials?.viewBtnAction
      ? state.tableSlice?.customButtonClicked?.paymentDetials?.viewBtnAction
      : false
  );
  const manageDistButtonClickedForPharmaAspire = useSelector((state) =>
    state.tableSlice.customButtonClicked?.paymentDetials?.manage_dist
      ? state.tableSlice.customButtonClicked?.paymentDetials?.manage_dist
      : false
  );
  const allDispositionModalButtonClickedForPharmaAspire = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.all_dispositions
      ? state.tableSlice.customButtonClicked.paymentDetials.all_dispositions
      : false
  );
  const dispositionCallButtonClickedForPharmaAspire = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.dist_call_list
      ? state.tableSlice.customButtonClicked.paymentDetials.dist_call_list
      : false
  );
  const TrnsfrOwnrshpClickedforNewTable = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.trasfer_ownership
      ? state.tableSlice.customButtonClicked.paymentDetials.trasfer_ownership
      : false
  );
  const setPrioritybtnClickedforNweList = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.set_priority
      ? state.tableSlice.customButtonClicked.paymentDetials.set_priority
      : false
  );
  const paymentIpInfoClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.previewIpInfo
      ? state.tableSlice.customButtonClicked.paymentDetials.previewIpInfo
      : false
  );
  const paymentnotesButtonClickedforrep = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.notes_for_rep
      ? state.tableSlice.customButtonClicked.paymentDetials.notes_for_rep
      : false
  );
  const paymentnotesButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.notes
      ? state.tableSlice.customButtonClicked.paymentDetials.notes
      : false
  );
  const paymentannulBillingClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.annul_billing
      ? state.tableSlice.customButtonClicked.paymentDetials.annul_billing
      : false
  );
  const paymentunderpaymentClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.paymentDetials?.under_payment
      ? state.tableSlice.customButtonClicked.paymentDetials.under_payment
      : false
  );
  const annulsuccess = useSelector((state) =>
    state.BlueCoastSlice?.success ? state.BlueCoastSlice.success : false
  );
  const undersuccess = useSelector((state) =>
    state.BlueCoastSlice?.undersuccess
      ? state.BlueCoastSlice.undersuccess
      : false
  );
  const prioritysuccess = useSelector((state) =>
    state.BlueCoastSlice?.prioritysuccess
      ? state.BlueCoastSlice.prioritysuccess
      : false
  );
  const alldispositionsuccess = useSelector((state) =>
    state.BlueCoastSlice?.alldispositionsuccess
      ? state.BlueCoastSlice.alldispositionsuccess
      : false
  );
  const noteSuccess = useSelector((state) =>
    state.BlueCoastSlice?.noteSuccess ? state.BlueCoastSlice.noteSuccess : false
  );
  const dispositionsuccess = useSelector((state) =>
    state.BlueCoastSlice?.dispositionsuccess
      ? state.BlueCoastSlice.dispositionsuccess
      : false
  );

  let singalAnnualBill = useSelector((state) =>
    state.tableSlice?.otherResData?.paymentDetials?.totalAnnualBilling
      ? state.tableSlice.otherResData.paymentDetials.totalAnnualBilling
      : state.BlueCoastSlice?.bluecoastPymtData?.totalAnnualBilling
        ? state.BlueCoastSlice.bluecoastPymtData.totalAnnualBilling
        : null
  );
  let sinaglUnderPayment = useSelector((state) =>
    state.tableSlice?.otherResData?.paymentDetials?.totalUnderPayment
      ? state.tableSlice.otherResData.paymentDetials.totalUnderPayment
      : state.BlueCoastSlice?.bluecoastPymtData
        ? state.BlueCoastSlice.bluecoastPymtData.totalUnderPayment
        : null
  );

  // console.log("paymentnotesButtonClickedforrep", singalAnnualBill);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState(false);
  const [paymentRowData, setPaymentRowData] = useState({});
  const [paymentPreviewModal, setPaymentPreviewModal] = useState(false);
  const [paymentIpInfoModal, setPaymentIpInfoModal] = useState(false);
  const [paymentNotesforRepmodal, setPaymentNotesforRepmodal] = useState(false);
  const [paymentNotesModalOpen, setPaymentNotesModalOpen] = useState(false);
  const [paymentAnnulBillingModaOpenl, setPaymentAnnulBillingModalOpen] =
    useState(false);
  const [paymentUnderPaymentModaOpenl, setPaymentUnderPaymentModaOpenl] =
    useState(false);
  const [paymentTransferOwnerModal, setPaymentTransferOwnerModal] =
    useState(false);
  const [paymentPriority, setPaymentPriority] = useState(false);
  const [paymentDispositionCycle, setPaymentDispositionCycle] = useState(false);
  const [totalAnnualBill, setTotalAnnualBill] = useState(null);
  const [totlaUnderPayment, setTotalUnderPayment] = useState(null);
  const [ForLoading, setForLoading] = useState(false);
  const [paymentDisposition, setPaymentDisposition] = useState(false);

  const [previewNotesPropspect, setPreviewNotesPropspect] = useState(null);

  useEffect(() => {
    if (Object.keys(paymentbtnclickgetrowdata).length > 0) {
      setPaymentRowData(paymentbtnclickgetrowdata);
    }
  }, [paymentbtnclickgetrowdata]);

  const handlePymentDetals = () => {
    let reqBody = {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        annual_billing: { $exists: true },
        under_payment: { $exists: true },
        // "bluecoastphblue": { $exists: false }
      },
      project: {},
      token: "",
      email: "",
      count: false,
    };

    dispatch(BlueCoastPayment(reqBody));
  };

  useEffect(() => {
    if (bluecoastPaymentList.length > 0) {
      setPaymentDetailsModal(true);
    }
  }, [bluecoastPaymentList]);

  const closePymentDetals = () => {
    setPaymentDetailsModal(false);
  };

  var paymentModifyTableHeaders = [
    { val: "ae_name_show", name: `AE/REP/Advisor (Name/Phone No./Email)` },
    { val: "prospect_info", name: "Prospect's (Name/Phone No./Email)" },
    { val: "annual_billing_str", name: "Annual Billing" },
    { val: "under_payment_str", name: "Under Payment" },
    // { val: "prospecttitle", name: "Prospect's Title" },
    // { val: "prospectbusinessname_type", name: "Prospect's Medical Facility Business Name/Type" },
    // { val: "annualbilling_underpayment", name: "Total Gross insurance gross receipts / Underpayment Opportunity " },
    // { val: "disposition_show", name: "Last Disposition" },
    { val: "owner_name", name: "Owner Name" },
    // { val: "priority_show", name: "Priority" },
    {
      val: "createdon_datetime",
      name: "Createdon Time",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
    {
      val: "updatedon_datetime",
      name: "Updation Time",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ];

  let tableDataForUsersPayment = useRef({
    tableId: "paymentDetials",
    tableTitle: "Opportunity Report List",
    showReload: true,
    showFilter: true,
    upperPagination: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/bcl-lead-list",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "bclusers/bcl-lead-list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        annual_billing: { $exists: true },
        under_payment: { $exists: true },
      },
      project: {},
      token: "",
      email: "",
      count: false,
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: [
      "fullname",
      "prospectfullname",
      "email",
      "phone",
      "createdon_datetime",
      "updatedon_datetime",
      "prospectemail",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [
      {
        id: "viewBtnAction",
        label: "Preview",
        type: "other",
        icon_type: "preview",
        name: "preview",
      },

      {
        id: "previewIpInfo",
        type: "other",
        icon_type: "router_icon",
        label: "Ip-Info",
        name: "router_icon",
      },
      {
        id: "notes",
        type: "other",
        icon_type: "summarize_icon_icon",
        label: "Note About Prospect's",
        name: "summarize_icon_icon",
        showcount: true,
        countFiled: "notes_count",
      },
      {
        id: "manage_dist",
        type: "other",
        icon_type: "diversity_3_icon",
        label: "Manage Disposition",
        name: "diversity_3_icon",
      },
      {
        id: "all_dispositions",
        type: "other",
        icon_type: "speaker_notes_icon",
        label: "Show All Disposition",
        name: "speaker_notes_icon",
      },
      {
        id: "set_priority",
        type: "other",
        icon_type: "move_up_icon",
        label: "Set Priority",
        name: "move_up_icon",
      },

      {
        id: "trasfer_ownership",
        type: "other",
        icon_type: "transfer_within_a_station_icon",
        label: "Trasnfer Ownership",
        name: "transfer_within_a_station_icon",
      },
      {
        id: "annul_billing",
        type: "other",
        icon_type: "credit_score_icon",
        label: "Total Gross Insurance Gross Receipts",
        name: "credit_score_icon",
      },
    ],

    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },

    searchData: {
      heading: "Search Lead List",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchFormId_bluecoast",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by AE/Rep/Advisor Name",
            name: "fullname",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            // other: true,
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/bcl-leadlist-autocomplete",
            preload: false,
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "fullname",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "rep_id" },
          },
          {
            id: 2,
            label: "Search by AE/Rep/Advisor Email",
            name: "email",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/bcl-leadlist-autocomplete",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                annual_billing: { $exists: true },
                under_payment: { $exists: true },
              },
              type: "email",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 5,
            label: "Search by Prospect's Name",
            name: "prospectfullname",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/bcl-leadlist-autocomplete",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                // "annual_billing": { $exists: true },
                // "under_payment": { $exists: true },
              },
              type: "prospect_name",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 20,
            label: "Search by Prospect's Email",
            name: "prospectemail",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/bcl-leadlist-autocomplete",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {
                annual_billing: { $exists: true },
                under_payment: { $exists: true },
              },
              type: "prospectemail",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },

          {
            id: 25,
            label: "Search by Owner",
            name: "owner",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            multiple: true,
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/spcialautocompletename",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "prospectemail",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "owner" },
          },

          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "createdon_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "createdtedon_datetime",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },

          {
            id: 10,
            label: "Search by Update on Start Date",
            name: "update_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "updatedon_datetime", param: "$gte" },
          },
          {
            id: 11,
            label: "Search by Update on End Date",
            name: "updateon_datetime",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "updatedon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  //----------------configration of payment modal list privew----------------------------------------
  useEffect(() => {
    if (previewBtn && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );
      setPaymentPreviewModal(true);
      // setPreviewModalData(paymentRowData)

      // console.log("");

      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "paymentDetials",
              rowId: paymentRowData._id,
              load: false,
            })
          ),
        1000
      );
    }
  }, [previewBtn, paymentRowData]);
  //===============================================================================================================

  //---------------------------Manage Disposition for payment modall -------------------------------------
  useEffect(() => {
    if (manageDistButtonClickedForPharmaAspire && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );

      dispatch(
        dispositionDataLoad({
          _id: paymentRowData?._id,
          source: "bluecoast_leads",
          token: token,
        })
      );
    }
  }, [manageDistButtonClickedForPharmaAspire, paymentRowData]);

  useEffect(() => {
    if (dispositionsuccess === "success" && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: false,
        })
      );
      setPaymentDisposition(true);
    }
  }, [dispositionsuccess]);

  //========================================================================================================

  // dispositionCallButtonClickedForPharmaAspire

  useEffect(() => {
    if (dispositionCallButtonClickedForPharmaAspire && paymentRowData) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );

      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "paymentDetials",
              rowId: paymentRowData._id,
              load: false,
            })
          ),
        2000
      );
    }
  }, [dispositionCallButtonClickedForPharmaAspire]);

  //----------------------------Mangage of Payment model list Ip Info detail-------------------------

  useEffect(() => {
    if (paymentIpInfoClick && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );
      setPaymentIpInfoModal(true);
      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "paymentDetials",
              rowId: paymentRowData._id,
              load: false,
            })
          ),
        2000
      );
    }
  }, [paymentIpInfoClick, paymentRowData]);

  //=================================================================================================

  //--------------------------manage Payment Rep Notes Model-----------------------------
  // paymentnotesButtonClickedforrep

  useEffect(() => {
    if (paymentnotesButtonClickedforrep && paymentRowData) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );
      setPaymentNotesforRepmodal(true);
      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "paymentDetials",
              rowId: paymentRowData._id,
              load: false,
            })
          ),
        2000
      );
    }
  }, [paymentnotesButtonClickedforrep, paymentRowData]);

  //===================================================================================

  //-------------------------------------Manage Prospect Notes for Payment model-----------------------------------

  // paymentnotesButtonClicked

  useEffect(() => {
    if (paymentnotesButtonClicked && paymentRowData) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );

      let reqBody = {
        condition: {
          limit: 30,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {
          addedfor: paymentRowData?._id,
        },
        project: {},
        token: "",
        email: "",
        count: false,
      };

      dispatch(blueCoastNotes(reqBody));
    }
  }, [paymentRowData]);

  useEffect(() => {
    if (noteSuccess === "success" && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: false,
        })
      );
      setPaymentNotesModalOpen(true);
    }
  }, [noteSuccess]);

  //================================================================================================================

  //-----------------------------------------Manage Payment allDisposition  Cycle--------------------------------------

  useEffect(() => {
    if (
      allDispositionModalButtonClickedForPharmaAspire &&
      paymentRowData?._id
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );

      dispatch(
        alldispositionData({
          _id: paymentRowData?._id,
          source: "bluecoast_leads",
          token: token,
        })
      );
    }
  }, [allDispositionModalButtonClickedForPharmaAspire, paymentRowData]);

  useEffect(() => {
    if (alldispositionsuccess === "success" && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: false,
        })
      );
      setPaymentDispositionCycle(true);
    }
  }, [alldispositionsuccess]);
  //-----------------------------------------------------------------------------------------------------------------

  //----------------------------------------------Manage Payment setPrioritybtnClickedforNweList----------------------------------

  // setPrioritybtnClickedforNweList

  useEffect(() => {
    if (setPrioritybtnClickedforNweList && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );
      paymentprioritydatafetchedapi(paymentRowData);
    }
  }, [setPrioritybtnClickedforNweList, paymentRowData]);

  const paymentprioritydatafetchedapi = async (acccept) => {
    let rowDataId = {
      _id: acccept._id,
      priority: acccept.priority,
      source: "bluecoast_leads",
      token: token,
    };
    dispatch(blueCoastGetPriorityData(rowDataId));
  };

  useEffect(() => {
    if (prioritysuccess === "success" && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: false,
        })
      );
      setPaymentPriority(true);
    }
  }, [prioritysuccess]);

  //===============================================================================================================

  //-------------------------------------------Manage Transfer Ownership-------------------------------------------

  useEffect(() => {
    if (TrnsfrOwnrshpClickedforNewTable && paymentRowData?._id) {
      // dispatch(
      //   setEnableRowLoader({
      //     tableId: "paymentDetials",
      //     rowId: paymentRowData._id,
      //     load: true,
      //   }))

      setPaymentTransferOwnerModal(true);
      // setTimeout(() => {
      //   dispatch(
      //     setEnableRowLoader({
      //       tableId: "bluecoastdata",
      //       rowId: paymentRowData._id,
      //       load: false,
      //     }))
      // }, 2000)
    }
  }, [TrnsfrOwnrshpClickedforNewTable, paymentRowData]);

  //===============================================================================================================

  //-------------------------------Manage Anuall Bill for payment modal list----------------------------------------

  // paymentannulBillingClick
  // console.log("this is row data for annul payment", paymentRowData);
  useEffect(() => {
    if (paymentannulBillingClick === true && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );
      dispatch(
        getbluecoastannualbilling({
          _id: paymentRowData._id,
          source: "bluecoast_leads",
          token: token,
        })
      );
    }
  }, [paymentannulBillingClick, paymentRowData]);

  useEffect(() => {
    if (annulsuccess === "success" && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: false,
        })
      );

      setPaymentAnnulBillingModalOpen(true);
    }
  }, [annulsuccess]);

  //================================================================================================================

  //--------------------------------Manage Underpayment Opportunity for Payment Modal list----------------------

  useEffect(() => {
    if (paymentunderpaymentClick && paymentRowData?._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: true,
        })
      );
      dispatch(
        getbluecoastunderpayment({
          _id: paymentRowData._id,
          source: "bluecoast_leads",
          token: token,
        })
      );
    }
  }, [paymentunderpaymentClick, paymentRowData]);

  useEffect(() => {
    if (undersuccess === "success" && paymentRowData._id) {
      dispatch(
        setEnableRowLoader({
          tableId: "paymentDetials",
          rowId: paymentRowData._id,
          load: false,
        })
      );

      setPaymentUnderPaymentModaOpenl(true);
    }
  }, [undersuccess]);

  //============================================================================================================

  ///////-------------------------------Manage Opportunity Report list update on any changes-----------------------

  useEffect(() => {
    if (ForLoading === true) {
      dispatch(setReloadTableFlag({ tableId: "paymentDetials" }));
    }

    setTimeout(() => {
      setForLoading(false);
    }, 3000);
  }, [ForLoading]);

  //================================================================================================================

  // console.log("data of list amount",bluecoastdata.totalAnnualBilling);
  // console.log("data of list amount----",bluecoastdata.totalUnderPayment);

  //==========================================================================

  //-----------------------------data truncefer button handeler funtion-------------------

  // useEffect(()=>{
  //   if(listType !== null && listType !== undefined){
  //     // setBataTranceferModalState(true)
  //   }
  // },[listType])

  // Modal Open
  useEffect(() => {
    if (dataTransferbtnClick === true) {
      setChosePathModal(true);
      setsaveRowdata(rowData);
    }
  }, [dataTransferbtnClick]);

  //Mddal Close

  const CloseDataTrancferMosal = () => {
    setChosePathModal(false);
    setBataTranceferModalState(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    setsaveRowdata(null);
    setListType(null);
    setsaveRowdata(null);
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }))
  };

  //Modal Iner Funtion
  const HandelDataTreancefer = () => {
    // dispatch(BlueCoastDataTrancefer({ _id:saveRowdata._id, source: "bluecoast_leads",flag:dataTrancferFlag ,list_type:listType}))
    dispatch(
      BlueCoastDataTrancefer({
        _id: saveRowdata._id,
        flag: dataTrancferFlag,
        list_type: listType,
        token: token,
      })
    );
    setTimeout(() => {
      CloseDataTrancferMosal();
    }, 4000);
  };

  useEffect(() => {
    if (Object.keys(bluecoastDataTranceferStatus).length > 0) {
      setSnakebarModalState(true);
      setSnakebarMassage(bluecoastDataTranceferStatus?.message);
      setSnakebarStatus(
        bluecoastDataTranceferStatus?.status === "success"
          ? "success"
          : "failed"
      );
    }
  }, [bluecoastDataTranceferStatus]);

  useEffect(() => {
    if (snakebarModalStrate === false) {
      setSnakebarMassage(null);
      setSnakebarStatus(null);
      dispatch(clearbluecoastDataTranceferStatus());
    }
  }, [snakebarModalStrate]);
  // ---------------------------------------------------------------------------------------------------------------------------------------

  // console.log("allDispositionData======>", allDispositionData);
  const [saveRowdata, setsaveRowdata] = useState(null);

  useEffect(() => {
    if (rowData && Object.keys(rowData).length > 0) {
      setsaveRowdata(rowData);
    }

    // console.log("saveRowdata>>>987", saveRowdata);
  }, [JSON.stringify(rowData)]);

  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=b76955ba6450f9")
      .then((results) => results.json())
      .then((data) => {
        // console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
    // .catch(e => {
    //   console.log("errrrrrrrrrrrrrrrrrrrr++++++++++",e);
    // })
  }, []);

  // *************************useEffect for Add BlueCoast Form****************
  useEffect(() => {
    if (
      otherRadioButtonClicked &&
      otherRadioButtonClicked.medicalfacilitytype
    ) {
      if (
        otherRadioButtonClicked.medicalfacilitytype &&
        otherRadioButtonClicked.medicalfacilitytype == "Other"
      ) {
        // console.log("otherRadioButtonClicked", otherRadioButtonClicked);

        let field = {
          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: "formfild formfild1 SD_input_field",
          // multiple: true,
          type: "text",
        };
        setTimeout(
          () =>
            dispatch(
              setField({
                formId: "addBlueCstLead",
                after: "medicalfacilitytype",
                fieldData: field,
              })
            ),
          200
        );
      } else {
        setTimeout(
          () =>
            dispatch(removeField({ formId: "addBlueCstLead", name: "other" })),
          2000
        );
      }
    }
  }, [JSON.stringify(otherRadioButtonClicked)]);

  useEffect(() => {
    if (
      otherRadioButtonClickednew &&
      otherRadioButtonClickednew.medicalfacilitytype
    ) {
      if (
        otherRadioButtonClickednew.medicalfacilitytype &&
        otherRadioButtonClickednew.medicalfacilitytype == "Other"
      ) {
        // console.log("otherRadioButtonClicked", otherRadioButtonClicked);

        let field = {
          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: "formfild formfild1",
          // multiple: true,
          type: "text",
        };
        setTimeout(
          () =>
            dispatch(
              setField({
                formId: "addBlueCstPharmacyLead",
                after: "medicalfacilitytype",
                fieldData: field,
              })
            ),
          200
        );
      } else {
        setTimeout(
          () =>
            dispatch(
              removeField({ formId: "addBlueCstPharmacyLead", name: "other" })
            ),
          2000
        );
      }
    }
  }, [JSON.stringify(otherRadioButtonClickednew)]);

  const [editvalueset, seteditvalueset] = useState(false);
  useEffect(() => {
    if (
      editBlcstDataLead &&
      Object.keys(editBlcstDataLead).length > 0 &&
      !editvalueset
    ) {
      if (
        editBlcstDataLead.medicalfacilitytype &&
        editBlcstDataLead.medicalfacilitytype == "Other"
      ) {
        // console.log("editBlcstDataLead+++", editBlcstDataLead);

        let field = {
          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: "formfild formfild1 SD_input_field",

          // multiple: true,
          type: "text",
          defaultValue:
            editBlcstDataLead && editBlcstDataLead.other
              ? editBlcstDataLead.other
              : undefined,
        };
        setTimeout(
          () =>
            dispatch(
              setField({
                formId: "addBlueCstLead",
                after: "medicalfacilitytype",
                fieldData: field,
              })
            ),
          500
        );
        setTimeout(
          () =>
            dispatch(
              setField({
                formId: "addBlueCstPharmacyLead",
                after: "medicalfacilitytype",
                fieldData: field,
              })
            ),
          1000
        );
      } else {
        setTimeout(
          () =>
            dispatch(removeField({ formId: "addBlueCstLead", name: "other" })),
          1500
        );
        setTimeout(
          () =>
            dispatch(
              removeField({ formId: "addBlueCstPharmacyLead", name: "other" })
            ),
          2000
        );
      }
      seteditvalueset(true);
    }
  }, [JSON.stringify(editBlcstDataLead)]);

  useEffect(() => {
    if (leadaddeddata && Object.keys(leadaddeddata) && leadaddeddataFlag) {
      // console.log("hit in form submitionstate");
      dispatch(
        setSnackbar({
          open: true,
          message: "Thank You for Submiting!",
          status: "success",
        })
      );
      setTimeout(() => {
        addFormClose();
      }, 1000);
      dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));

      // navigate('')
      // resolvefunction(navigate, `/thankyou/${leadaddeddata._id}`, fetchbluecodelead(leadaddeddata._id))
      // navigate(`/thankyou/${leadaddeddata._id}`)
      dispatch(clearadddsuccessflag());
    }
  }, [leadaddeddataFlag, JSON.stringify(leadaddeddata)]);

  // console.warn("leadaddeddata123456", leadaddeddata)

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      // console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);
  useEffect(() => {
    if (submissionStatenew && submissionStatenew === 4) {
      addapicall(formInputDatanew);
      // console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionStatenew]);

  const addapicall = async (val) => {
    // console.log("val>>>", val);
    // console.log("menuSelect",menuSelect);
    val = { ...val };
    let body = {
      first_name: val.first_name,
      last_name: val.last_name,
      email: val.email,
      phone: val.phone,
      prospectbusinessname: val.prospectbusinessname,
      prospectfirstname: val.prospectfirstname.trim(),
      prospectlastname: val.prospectlastname.trim(),
      prospecttitle: val.prospecttitle,
      prospectemail: val.prospectemail,
      prospectcontactnumber: val.secondary_phone,
      city: val.city,
      state: val.state,
      medicalfacilitytype: val.medicalfacilitytype,
      // "facilitytype": val.facility_type,
      other: val.other,
      natureofrelationship: val.natureofrelationship,
      relationshipdescription: val.relationshipdescription,
      approxgrossreciept: val.approxgrossreciept,
      createdon_datetime: new Date().getTime(),
      added_type: "Manual",
      other_info: val.other_info,
      extensive_notes: val.extensive_notes,
      ipinfo: ipinfo,
      _id:
        editBlcstDataLead && Object.keys(editBlcstDataLead).length > 0
          ? editBlcstDataLead._id
          : undefined,
      company_website: val.company_website,
    };
    if (menuSelect == 2) {
      body["outsideData"] = true;
    }
    if (menuSelect == 5) {
      body["bluecoastPh"] = true;
      body["subscription_membership"] = val.subscription_membership;
      body["number_of_people_type"] = val.number_of_people_type;
    }
    if (menuSelect == 6) {
      body["bluecoastphblue"] = true;
      body["subscription_membership"] = val.subscription_membership;
      body["number_of_people_type"] = val.number_of_people_type;
    }

    // outsideData

    if (Object.keys(body).length > 0) {
      // console.log("body-----", body);
      // return
      // setloading(true)
      await store.dispatch(addlandingpageninedatabluecosde(body));

      for (let k in addFormBlueCstLead.current.fields) {
        let field = cloneDeep(addFormBlueCstLead.current.fields[k]);
        field.defaultValue = undefined;
        addFormBlueCstLead.current.fields[k] = field;

        // let fieldnew =  addFormBlueCstLead.current.fields[k]
        // fieldnew = {...fieldnew,defaultValue:undefined}
      }
      for (let k in addFormBlueCstPharmacyLead.current.fields) {
        if (addFormBlueCstPharmacyLead?.current?.fields[k]) {
          let field = cloneDeep(addFormBlueCstPharmacyLead.current.fields[k]);
          field.defaultValue = undefined;
          addFormBlueCstPharmacyLead.current.fields[k] = field;
        }
      }
      dispatch(
        setSnackbar({
          open: true,
          message:
            editBlcstDataLead && Object.keys(editBlcstDataLead).length > 0
              ? "Updated Successfully"
              : "Added Successfully",
          status: "success",
        })
      );
      setEditBlcstDataLead(null);
      addFormClose();
      addBlueCoastPharmacyFormClose();
      dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
      dispatch(clearadddsuccessflag());
      // dispatch(clearAddEditblcstData())

      // setActive(false)
      // setloading(false)
    }
  };

  useEffect(() => {
    if (
      editBlCoastBtnClicked &&
      editDataLead &&
      Object.keys(editDataLead).length > 0
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: rowData._id,
          load: true,
        })
      );
      setEditBlcstDataLead(editDataLead);
      addFormBlueCstLead.current.fields[2].disabled = true
      // console.log("editDataLead+++ lucky", editDataLead)

      for (let k in addFormBlueCstLead.current.fields) {
        // console.log("editDataLead+++ ", addFormBlueCstLead.current.fields[k])

        if (editDataLead[addFormBlueCstLead.current.fields[k].name]) {
          addFormBlueCstLead.current.fields[k].defaultValue =
            editDataLead[addFormBlueCstLead.current.fields[k].name];
          // console.warn("editDataLead+++ ", addFormBlueCstLead.current.fields[k].name)
        }
        if (editDataLead["prospectcontactnumber"]) {
          if (addFormBlueCstLead.current.fields[k].name == "secondary_phone") {
            addFormBlueCstLead.current.fields[k].defaultValue =
              editDataLead["prospectcontactnumber"];
          }
        }
      }

      addFormBlueCstPharmacyLead.current.fields[2].disabled = true

      for (let k in addFormBlueCstPharmacyLead.current.fields) {
        if (editDataLead[addFormBlueCstPharmacyLead.current.fields[k].name]) {
          addFormBlueCstPharmacyLead.current.fields[k].defaultValue =
            editDataLead[addFormBlueCstPharmacyLead.current.fields[k].name];
        }
        if (editDataLead["prospectcontactnumber"]) {
          if (
            addFormBlueCstPharmacyLead.current.fields[k].name ==
            "secondary_phone"
          ) {
            addFormBlueCstPharmacyLead.current.fields[k].defaultValue =
              editDataLead["prospectcontactnumber"];
          }
        }
      }

      setTimeout(() => {
        if (menuSelect == 5 || menuSelect == 6) {
          addBlueCoastPharmacyFormOpen(true);
          dispatch(
            setEnableRowLoader({
              tableId: "bluecoastdata",
              rowId: rowData._id,
              load: false,
            })
          );
        } else {
          setAddFormModalOpen(true);
          dispatch(
            setEnableRowLoader({
              tableId: "bluecoastdata",
              rowId: rowData._id,
              load: false,
            })
          );
        }
      }, 1000);
    }
  }, [editBlCoastBtnClicked, JSON.stringify(editDataLead)]);

  /******************************All disposition data Clicked*************** */
  useEffect(() => {
    if (
      (notesButtonClickedforrepROWDATA &&
        Object.keys(notesButtonClickedforrepROWDATA).length > 0,
        notesButtonClickedforrep)
    ) {
      callrepnodemodal(notesButtonClickedforrepROWDATA);
    }

    if (
      previewData &&
      Object.keys(previewData).length > 0 &&
      notesButtonClickedforrep1
    ) {
      callrepnodemodal(previewData);

      dispatch(
        setEnableRowLoader({
          tableId: "lead_data",
          rowId: previewData._id,
          load: true,
        })
      );

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "lead_data",
            rowId: previewData._id,
            load: false,
          })
        );
      }, 1000);
    }
  }, [
    notesButtonClickedforrep,
    JSON.stringify(notesButtonClickedforrepROWDATA),
    JSON.stringify(previewData),
    notesButtonClickedforrep1,
  ]);

  const [temprowDtafornote, settemprowDtafornote] = useState(null);
  const [notesforrepmodal, setnotesforrepmodal] = useState(false);

  const callrepnodemodal = async (val) => {
    setIpInfo(rowData);

    // console.log("callrepnodemodalval", val);
    settemprowDtafornote(val);
    tableDataForRepNotes.current.reqBody.searchcondition.notes_for = val.rep_id;
    tableDataForRepNotes.current.searchData.formData.fields[0].reqBody.searchcondition.notes_for =
      val.rep_id;
    tableDataForRepNotes.current.searchData.formData.fields[1].reqBody.searchcondition.notes_for =
      val.rep_id;

    await dispatch(blueCoastNotesrep(val.rep_id));
    setnotesforrepmodal(true);
  };

  useEffect(() => {
    if (allDispositionModalButtonClicked) {
      allDisposition();
    }
  }, [allDispositionModalButtonClicked]);

  const handleCloseAllDispositionModal = () => {
    setAllDispositionModalOpen(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(clearAllDisposition());
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata"}))
  };

  const allDisposition = async () => {
    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: true,
      })
    );
    await dispatch(
      alldispositionData({ _id: rowData?._id, source: "bluecoast_leads" })
    );

    setAllDispositionModalOpen(true);
    // setAllDispositionModalData(allDispositionData)

    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: false,
      })
    );
  };

  // --------------------------------All Notes Button Clicked Functions----------------------

  useEffect(() => {
    if (allNotesButtonClicked) {
      allNotesOpne();
    }
  }, [allNotesButtonClicked]);

  const allNotesOpne = async () => {
    setOpenAllNotesModalLoader(true);
    if (menuSelect == 1) {
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "outsideData"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "bluecoastPh"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "bluecoastphblue"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "accessfree"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "is_deleted"
      ] = { $exists: false };
    } else if (menuSelect == 2) {
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        outsideData: true,
        is_deleted: { $exists: false },
      };
    } else if (menuSelect == 5) {
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        bluecoastPh: { $exists: true },
        is_deleted: { $exists: false },
      };
    } else if (menuSelect == 6) {
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        bluecoastphblue: { $exists: true },
        is_deleted: { $exists: false },
      };
    } else if (menuSelect == 9) {
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        $or: [
          { bluecoastphblue: { $exists: true } },
          { bluecoastPh: { $exists: true } },
        ],
      };
    } else if (menuSelect == 10) {
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        accessfree: { $exists: false },
        bluecoastPh: { $exists: false },
        bluecoastphblue: { $exists: false },
        is_deleted: { $exists: false },
      };
    }
    await dispatch(
      blueCoastNotes(tableDataForBlueCoastAllNotes.current.reqBody)
    );
    setAllNotesModal(true);
    setTimeout(() => {
      setOpenAllNotesModalLoader(false);
    }, 2000);
  };

  const handleCloseAllNotesModal = () => {
    tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;

    if (
      tableDataForBlueCoastAllNotes.current.reqBody &&
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition &&
      Object.keys(tableDataForBlueCoastAllNotes.current.reqBody.searchcondition)
        .length > 0
    ) {
      Object.keys(
        tableDataForBlueCoastAllNotes.current.reqBody.searchcondition
      ).forEach(
        (key) =>
          delete tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
          key
          ]
      );
    } else if (
      tableDataForBlueCoastAllNotes.current.reqBody &&
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition &&
      Object.keys(tableDataForBlueCoastAllNotes.current.reqBody.searchcondition)
        .length > 0
    ) {
      Object.keys(
        tableDataForBlueCoastAllNotes.current.reqBody.searchcondition
      ).forEach((key) => {
        if (key == "addedfor")
          delete tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
            key
          ];
      });
    }

    setAllNotesModal(false);
    dispatch(clearBlueCoastNotesList());
    // dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(setReloadTableFlag({ tableId: "bluecoastallnotes" }));
    dispatch(clearTableData({ tableId: "bluecoastallnotes" }));
    // dispatch(clearTableData({ tableId: "bluecoastdata" }))

    dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastallnotes" }));
    dispatch(resetTempBody({ tableId: "bluecoastallnotes" }));
    dispatch(resetForm({ formId: "searchNotesForm" }));
    dispatch(resetFormData({ formId: "searchNotesForm" }));
    // dispatch(setReloadTableFlag({ tableId: "bluecoastallnotes" }));

    //  tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {};
  };

  // *****************************Add User Button Functioanlity*****************************
  useEffect(() => {
    if (addUserwButtonClicked === true) {
      navigate("/newuseraddedit");
    }
  }, [addUserwButtonClicked]);

  // ---------------------************Notes View Button Data*********------------------------

  useEffect(() => {
    if (notesViewButtonClicked) {
      // console.log("notesViewButtonClicked>>>>>>>>>>", notesViewButtonClicked);

      if (
        tableDataForUsers.current.reqBody &&
        tableDataForUsers.current.reqBody.searchcondition &&
        Object.keys(tableDataForUsers.current.reqBody.searchcondition).length >
        0
      ) {
        Object.keys(tableDataForUsers.current.reqBody.searchcondition).forEach(
          (key) => delete tableDataForUsers.current.reqBody.searchcondition[key]
        );
      }

      if (menuSelect == 1) {
        // console.log("menuSelect====>>>>", menuSelect);
        tableDataForUsers.current.reqBody.searchcondition["outsideData"] = {
          $exists: false,
        };
        tableDataForUsers.current.reqBody.searchcondition["bluecoastPh"] = {
          $exists: false,
        };
        tableDataForUsers.current.reqBody.searchcondition["bluecoastphblue"] = {
          $exists: false,
        };
        tableDataForUsers.current.reqBody.searchcondition["accessfree"] = {
          $exists: false,
        };
        tableDataForUsers.current.reqBody.searchcondition["is_deleted"] = {
          $exists: false,
        };
      } else if (menuSelect == 2) {
        tableDataForUsers.current.reqBody.searchcondition = {
          outsideData: true,
          is_deleted: { $exists: false },
        };
      } else if (menuSelect == 5) {
        tableDataForUsers.current.reqBody.searchcondition = {
          bluecoastPh: { $exists: true },
          is_deleted: { $exists: false },
        };
      } else if (menuSelect == 6) {
        tableDataForUsers.current.reqBody.searchcondition = {
          bluecoastphblue: { $exists: true },
          is_deleted: { $exists: false },
        };
      } else if (menuSelect == 9) {
        tableDataForUsers.current.reqBody.searchcondition = {
          $or: [
            { bluecoastphblue: { $exists: true } },
            { bluecoastPh: { $exists: true } },
          ],
        };
      } else if (menuSelect == 10) {
        tableDataForUsers.current.reqBody.searchcondition = {
          accessfree: { $exists: false },
          bluecoastPh: { $exists: false },
          bluecoastphblue: { $exists: false },
          is_deleted: { $exists: false },
        };
      }

      setOpenAllNotesModalLoader(true);

      dispatch(blueCoastNotesViewTable(tableDataForUsers.current.reqBody));
      // dispatch(blueCoastNotesViewTable(reqBody1))
    }
  }, [notesViewButtonClicked]);

  useEffect(() => {
    if (notesViewtableData && notesViewtableData.length > 0) {
      // console.log("notesViewtableData>>>123", notesViewtableData);
      setNotesViewOpen(true);
      setOpenAllNotesModalLoader(false);
    }
  }, [notesViewtableData]);

  // *********************************Leads Count Dispostion**********************************
  useEffect(() => {
    if (dispLeadsSummryBtnClicked) {
      setDispLeadCountMOdalOpn(true);
    }
  }, [dispLeadsSummryBtnClicked]);

  // **************************************Export CSV Funtionality**********************************
  useEffect(() => {
    if (exportCSVBtnClicked) {
      excportCSVcallapi();
    }
  }, [exportCSVBtnClicked]);

  const excportCSVcallapi = async () => {
    let body = {
      flag: menuSelect,
      email: userInfo.email,
    };
    setOpenAllNotesModalLoader(true);

    await dispatch(exportCSVtoUserMail(body));
    setOpenAllNotesModalLoader(false);
    dispatch(
      setSnackbar({
        open: true,
        message: `CSV will be send to ${userInfo.email} email.`,
        status: "success",
      })
    );
  };

  /********************************************************************************** */

  useEffect(() => {
    if (notesaddfromsubmit && notesaddfromsubmit == 2) {
      dispatch(setReloadTableFlag({ tableId: "bluecoastnotes" }));
      dispatch(
        setSnackbar({
          open: true,
          message: "Notes Added Successfully",
          status: "success",
        })
      );
    }
  }, [notesaddfromsubmit]);

  /**************************************Use Selector for Manage Dispostion Task***************************************************** */

  // const manageDispostionTaskButtonClicked = useSelector((state) => state.tableSlice.customButtonClicked?.bluecoastdata?.manage_dist_task
  //   ? state.tableSlice.customButtonClicked?.bluecoastdata?.manage_dist_task
  //   : false)

  const [currentDispVal, setCurrentDispVal] = useState(null);
  // const currDisp = useSelector((state) => state.BlueCoastSlice?.dispostionData?.disposition_last?.disposition_name ? state.BlueCoastSlice.dispostionData.disposition_last.disposition_name : null)

  const currDisp = useSelector((state) =>
    state.tableSlice?.tempRowData.bluecoastdata?.last_disposition_name
      ? state.tableSlice.tempRowData.bluecoastdata.last_disposition_name
      : null
  );

  const currentdispflag = useSelector((state) =>
    state.BlueCoastSlice.currentdispflag
      ? state.BlueCoastSlice.currentdispflag
      : false
  );

  useEffect(() => {
    if (currDisp !== null && currDisp !== undefined) {
      // console.log("currDisp------>", currDisp)
      setCurrentDispVal(currDisp);
    }
  }, [currDisp]);

  // console.log("currentDispval",currentDispVal );

  const [markAsDoneButton, setMarkAsDoneButton] = useState(false);
  const [markAsDoneVal, setMarkAsDoneVal] = useState(1);

  // const dispDataExists = useSelector((state) => state.tableSlice?.tempRowData?.bluecoastdata?.disposition_data_exist ? state.tableSlice.tempRowData.bluecoastdata.disposition_data_exist : false)

  // useEffect(() => {
  //   if (manageDispostionTaskButtonClicked && rowData !== null  ) {
  //     setMarkAsDoneButton(true)
  //     setIpInfo(rowData);
  //     disposTask()
  //   }
  // }, [manageDispostionTaskButtonClicked])

  // useEffect(()=>{
  //   if(dispostionTaskButtonClickedforAllTask  && Object.keys(rowDataAllTask).length > 0){
  // //     console.log("rowDataAllTask",rowDataAllTask)
  // //  console.log("all task disposition");

  //  setDispositionTaskModal(true);

  //   }
  // },[dispostionTaskButtonClickedforAllTask])

  // useEffect(()=>{
  //   if(markAsDoneValue ){
  //     setMarkAsDoneValueChanged(markAsDoneValue)
  //   }

  // },[markAsDoneValue])

  // const disposTask = async (event) => {
  //   dispatch(
  //     setEnableRowLoader({
  //       tableId: "bluecoastdata",
  //       rowId: rowData._id,
  //       load: true,
  //     })
  //   );

  //   await dispatch(
  //     dispositionDataLoad({ _id: rowData?._id, source: "bluecoast_leads" })
  //   );
  // };

  // const disposTaskAllTask = async (event) => {

  //   dispatch(
  //     setEnableRowLoader({
  //       tableId: "alltaskdata",
  //       rowId: rowDataAllTask._id,
  //       load: true,
  //     })
  //   );
  //   await dispatch(
  //     dispositionDataLoad({ _id: rowDataAllTask?._id, source: "bluecoast_leads" })
  //   );
  // };

  // useEffect(() => {
  //   if (currDisp !== null && markAsDoneButton === true) {

  //     let request = {
  //       "condition": {
  //         "limit": 30,
  //         "skip": 0
  //       },
  //       "sort": {
  //         "field": "created_on",
  //         "type": "desc"
  //       },
  //       "searchcondition": {
  //         "addedfor": ipInfo?._id,
  //         "currentdisposition": currDisp

  //       },
  //       "project": {},
  //       "token": "",
  //       "email": "",
  //       "count": false
  //     }

  //     dispatch(CurrentDispositonTaskList(request))

  //   }

  //   if (currentdispflag === true) {

  //     dispatch(
  //       setEnableRowLoader({
  //         tableId: "bluecoastdata",
  //         rowId: ipInfo._id,
  //         load: false,
  //       })
  //     );

  //     setDispositionTaskModal(true);
  //   }

  //   // dispatch(clearcurrentdispflag())
  //   dispatch(clearDispositionData())

  // }, [JSON.stringify(currDisp), currentdispflag, markAsDoneButton])

  const currentDisposTask = useSelector((state) =>
    state.BlueCoastSlice?.currentdispositontaskdata &&
      Object.keys(state.BlueCoastSlice.currentdispositontaskdata).length > 0
      ? state.BlueCoastSlice.currentdispositontaskdata
      : {}
  );

  /*********************************************Use Selector For Manage Dist****************** */

  const manageDistButtonClicked = useSelector((state) =>
    state.tableSlice.customButtonClicked?.bluecoastdata?.manage_dist
      ? state.tableSlice.customButtonClicked?.bluecoastdata?.manage_dist
      : false
  );

  const dispositionData = useSelector((state) =>
    state.BlueCoastSlice?.dispostionData?.disposition_last
      ? state.BlueCoastSlice?.dispostionData?.disposition_last
      : {}
  );

  const dispositioDateTimePicker = useSelector((state) =>
    state.formSlice?.formData?.datetimeform?.dateTimePicker
      ? state.formSlice.formData.datetimeform
      : null
  );
  const dispositionDateTimeFormSubmission = useSelector((state) =>
    state.formSlice?.formSubmissionState?.datetimeform
      ? state.formSlice.formSubmissionState.datetimeform
      : 0
  );

  useEffect(() => {
    if (manageDistButtonClicked == true && rowData !== null) {
      setIpInfo(rowData);
      disposData();
    }
  }, [manageDistButtonClicked]);

  useEffect(() => {
    if (dispositionData && dispositionData.disposition_name) {
      setManageDist(dispositionData.disposition_name);
    }
  }, [dispositionData]);

  const disposData = async (event) => {
    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: true,
      })
    );
    await dispatch(
      dispositionDataLoad({
        _id: rowData?._id,
        source: "bluecoast_leads",
        token: token,
      })
    );

    setManageDistOpenModal(true);

    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: false,
      })
    );
  };

  const handleChange = async (event) => {
    // console.log("event.target.value", event.target.value);
    if (manageDist && event.target.value && event.target.value != manageDist) {
      setManageDist(event.target.value);
      // setCurrentDispositonData(event.target.value)
      if (
        event.target.value == "Discovery Call Set" ||
        event.target.value == "Advisor/AE Meeting Set"
      ) {
        setDispositionSelectValueModal(true);
      }
    }
  };

  // useEffect(()=>{
  //   console.log("currDisp",currDisp);
  // },[currDisp])

  const handleSubmitdisp = async () => {
    let dataSet = {
      addedby: userInfo._id,
      addedfor: specialEntityInfo._id,
      disposition_name: manageDist,
      action_added_time_human: timeConverter({
        time: Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      }),
    };

    setDispositionSucessLoader(true);
    await dispatch(getDisposition(dataSet));
    // console.log("managedist113>>", manageDist);

    dispatch(
      setSnackbar({
        open: true,
        message: "Disposition Updated Successfully",
        status: "success",
      })
    );

    setTimeout(() => {
      setDispositionSucessLoader(false);
    }, 1000);

    setTimeout(() => {
      dispatch(
        setSnackbar({
          open: false,
        })
      );
    }, 1000);

    // await dispatch(
    //   dispositionDataLoad({ _id: saveRowdata?._id, source: "bluecoast_leads" })
    // );

    // setTimeout(() => {
    //   setDispositionTaskModal(true)
    // }, 1000);

    // {(setDispositionTaskModal(true))}
    // console.log("currentDispositionData++++++++++++++", currentDispositionData)

    setManageDistOpenModal(false);

    dispatch(cleardisposition());
    dispatch(clearDispositionData());

    setManageDist("New Lead");
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
  };

  const callnewdisposition = async (val) => {
    // console.log("vall in date picker", val);
    var options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    let dataSet = {
      addedby: userInfo._id,
      addedfor: specialEntityInfo._id,
      disposition_name: manageDist,
      action_added_time_human: timeConverter({
        time: Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      }),
      set_up_time: new Date(val.time).toLocaleTimeString("en-US", options),
      set_up_date: val.dateTimePicker,
    };

    setDispositionSucessLoader(true);
    await dispatch(getDisposition(dataSet));

    // dispatch(
    //   setSnackbar({
    //     open: true,
    //     message: "Disposition Updated Successfully",
    //     status: "success",
    //   })
    // );
    setTimeout(() => {
      setDispositionSucessLoader(false);
    }, 1000);
    dispatch(cleardisposition());
    // dispatch(clearcurrentdispflag())
  };
  useEffect(() => {
    // console.log("dispositionDateTimeFormSubmission", dispositionDateTimeFormSubmission, dispositioDateTimePicker);
    if (
      dispositioDateTimePicker &&
      Object.keys(dispositioDateTimePicker).length > 0 &&
      dispositionDateTimeFormSubmission == 4
    ) {
      setDispositionSelectValueModal(false);
      callnewdisposition(dispositioDateTimePicker);
    }
    // setManageDist("New Lead");
    dispatch(clearDispositionData());
    dispatch(cleardisposition());
    dispatch(clearcurrentdispflag());
  }, [
    dispositionDateTimeFormSubmission,
    JSON.stringify(dispositioDateTimePicker),
  ]);

  // const dispositioDateTimePicker = useSelector((state) => state.formSlice?.formFieldsData?.datetimeform?.dateTimePicker ? state.formSlice.formFieldsData.datetimeform.dateTimePicker : '')
  // const dispositionDateTimeFormSubmission = useSelector((state) => state.formSlice?.formSubmissionState?.datetimeform ? state.formSlice.formSubmissionState.datetimeform : 0)

  const handleCloseDistManageModal = () => {
    setManageDistOpenModal(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    setManageDist("New Lead");
    dispatch(clearDispositionData());
    dispatch(cleardisposition());
    dispatch(clearcurrentdispflag());
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
  };

  const handleCloseDistSelectValueModal = () => {
    setDispositionSelectValueModal(false);
  };

  const handleCloseEmailtoSpecialEntity = () => {
    setSendEmailtoSpecialEntity(false);
  };

  const handleCloseEmailtoTravis = () => {
    setSendEmailtoTravisModal(false);
  };

  //  ************************added by id form user login****************
  const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);

  // ************added for by id from row data******************
  const addedFor = useSelector(
    (state) => state.tableSlice?.tempRowData?.bluecoastdata?._id
  );

  // console.log("addedForId",addedForId)
  useEffect(() => {
    if (addedFor != null && addedFor != undefined) {
      console.log("addedfor===>>>", addedFor);
      setAddedForId(addedFor);
    }
  }, [addedFor]);

  // **********ipInfo section************************
  useEffect(() => {
    if (ipInfoButton == true && rowData !== null) {
      setIpInfo(rowData);
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: rowData._id,
          load: true,
        })
      );
      setIpInfoModal(true);

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "bluecoastdata",
            rowId: rowData._id,
            load: false,
          })
        );
      }, 1000);
    }
  }, [ipInfoButton]);

  useEffect(() => {
    if (ipInfoButton1 == true && previewData !== null) {
      setIpInfo(previewData);
      dispatch(
        setEnableRowLoader({
          tableId: "lead_data",
          rowId: previewData._id,
          load: true,
        })
      );
      setIpInfoModal(true);

      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "lead_data",
            rowId: previewData._id,
            load: false,
          })
        );
      }, 1000);
    }
  }, [ipInfoButton1]);

  // *******************Set Priority action button**************
  const setPrioritybtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.set_priority
      ? state.tableSlice.customButtonClicked.bluecoastdata.set_priority
      : false
  );

  const setpriorityrowdata = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );
  const priorityData = useSelector((state) =>
    state.BlueCoastSlice?.prioritydata ? state.BlueCoastSlice?.prioritydata : {}
  );

  useEffect(() => {
    if (setPrioritybtnClicked && accceptdata !== undefined) {
      // console.log("specialEntityInfo113131", accceptdata)
      prioritydatafetchedapi(accceptdata);
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: accceptdata._id,
          load: true,
        })
      );
    }
  }, [setPrioritybtnClicked, JSON.stringify(accceptdata)]);

  useEffect(() => {
    if (priorityData && priorityData.priority) {
      setmanagePriority(priorityData.priority);
    }
  }, [priorityData]);

  const prioritydatafetchedapi = async (accceptdata) => {
    setIpInfo(rowData);

    let rowDataId = {
      _id: accceptdata._id,
      priority: accceptdata.priority,
      source: "bluecoast_leads",
      token: token,
    };
    await dispatch(blueCoastGetPriorityData(rowDataId));
    setPriorityModalOpen(true);
    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: accceptdata._id,
        load: false,
      })
    );
  };

  useEffect(() => {
    if (accceptdata) {
      console.warn("accceptdata12344>>>123", accceptdata);
      setspecialEntityInfo(accceptdata);
    }
  }, [accceptdata]);

  const selectpriorities = ["Low", "Medium", "High"];

  const handleChangeOnPriority = async (event) => {
    // console.log("event.target.value>>>123", event.target.value)
    setPriorityLoader(true);

    setmanagePriority(event.target.value);
    await dispatch(
      blueCoastSetPriority({
        priority: event.target.value,
        addedfor: specialEntityInfo._id,
        source: "bluecoast_leads",
        token: token,
      })
    );
    setPriorityLoader(false);

    dispatch(
      setSnackbar({
        open: true,
        message: "Priority Updated Successfully",
        status: "success",
      })
    );
    setTimeout(() => {
      CloseSetPriorityModal();
    }, 2000);
  };
  const CloseSetPriorityModal = () => {
    setPriorityModalOpen(false);
    setmanagePriority((selectpriorities.length = 0));
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
    dispatch(clearPriorityData());
  };

  // ********************specialbutton actions****************************
  useEffect(() => {
    if (acceptbtnClicked === true && accceptdata !== null) {
      setspecialEntityInfo(accceptdata);
      setbuttonClickedfor("accept");
      setStatesForSpecialModal("LEAD ACCEPTED SUCCESSFULLY");
      setspecialEntityModal(true);
      setStatesForModalText("ACCEPT THE PROSPECT'S");
    }
  }, [acceptbtnClicked]);

  useEffect(() => {
    if (declinebtnClicked === true && declinedata !== null) {
      setspecialEntityInfo(declinedata);
      setbuttonClickedfor("decline");
      setStatesForSpecialModal("LEAD DECLINED SUCCESSFULLY");
      setspecialEntityModal(true);
      setStatesForModalText("DECLINE THE PROSPECT'S");
    }
  }, [declinebtnClicked]);

  useEffect(() => {
    if (setdiscoveryClicked === true && setDiscoverydata !== null) {
      setspecialEntityInfo(setDiscoverydata);
      setbuttonClickedforcal("accept");
      setStatesForSpecialModal("DISCOVERY CALL ADDED SUCCESSFULLY");
      setspecialEntityModal(true);
      setStatesForModalText("SET THE DISCOVERY CALL");
    }
  }, [setdiscoveryClicked]);

  useEffect(() => {
    if (canceldiscoveryClicked === true && cancelDiscoverydata !== null) {
      setspecialEntityInfo(cancelDiscoverydata);
      setbuttonClickedforcal("decline");
      setStatesForSpecialModal("DISCOVERY CALL CANCELED SUCCESSFULLY");
      setspecialEntityModal(true);
      setStatesForModalText("CANCEL THE DISCOVERY CALL");
    }
  }, [canceldiscoveryClicked]);

  // --- UserTableList Call ---
  useEffect(() => {
    if (!resolved) {
      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    }

    dispatch(getothersplicalpersons({ _id: userInfo._id }));
    dispatch(getsplicalpersons());
    return () => {
      // dispatch(resetresolvenew())
      dispatch(clearBlueCoastList());
      dispatch(resetresolvenew("/businesslead-list"));

      //whenever the component removes it will executes
    };
  }, []);

  useEffect(() => {
    console.log("menuSelect==========>", menuSelect);
  }, [menuSelect]);

  const searchFormData = useSelector((state) =>
    state.formSlice?.formData?.searchFormId_bluecoast
      ? state.formSlice.formData.searchFormId_bluecoast
      : {}
  );
  useEffect(() => {
    if (searchFormData)
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = searchFormData[data?.name]
            ? searchFormData[data.name]
            : undefined;
          // console.log("data1234", data, searchFormData);
        }
      );
    // console.log("searchFormData", searchFormData);
  }, [JSON.stringify(searchFormData)]);

  // ********************************UseEffect for Notes Button Prospect************
  useEffect(() => {
    // console.log("notesButtonClicked", notesButtonClicked);
    if (notesButtonClicked == true && saveRowdata) {
      // console.log("saveRowdata===>>>123", saveRowdata);
      notesmodalopen();
    }
  }, [notesButtonClicked, saveRowdata]);

  const notesmodalopen = async (val) => {
    setIpInfo(saveRowdata);

    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: saveRowdata._id,
        load: true,
      })
    );
    if (
      tableDataForBlueCoastNotes.current.reqBody &&
      tableDataForBlueCoastNotes.current.reqBody.searchcondition
    ) {
      tableDataForBlueCoastNotes.current.reqBody.searchcondition.addedfor =
        saveRowdata._id;
    }

    tableDataForBlueCoastNotes.current.searchData.formData.fields[0].reqBody.searchcondition.addedfor =
      addedForId;

    setAddedForId(saveRowdata._id);

    await dispatch(blueCoastNotes(tableDataForBlueCoastNotes.current.reqBody));
    setNotesModalOpen(true);

    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: saveRowdata._id,
        load: false,
      })
    );
  };

  const handleCloseNotesModal = () => {
    if (handleModalPreview == false) {
      console.warn("consoleforifBlock===>>>");
      setsaveRowdata(null);
      setAddedForId({});

      if (
        tableDataForBlueCoastNotes.current.reqBody &&
        tableDataForBlueCoastNotes.current.reqBody.searchcondition &&
        Object.keys(tableDataForBlueCoastNotes.current.reqBody.searchcondition)
          .length > 0
      ) {
        Object.keys(
          tableDataForBlueCoastNotes.current.reqBody.searchcondition
        ).forEach(
          (key) =>
            delete tableDataForBlueCoastNotes.current.reqBody.searchcondition[
            key
            ]
        );
        // console.log("searchConsidition====>>>");
        // delete tableDataForBlueCoastNotes.current.reqBody.searchcondition.addedfor;
      }
    } else {
      if (
        tableDataForBlueCoastNotes.current.reqBody &&
        tableDataForBlueCoastNotes.current.reqBody.searchcondition &&
        Object.keys(tableDataForBlueCoastNotes.current.reqBody.searchcondition)
          .length > 0
      ) {
        Object.keys(
          tableDataForBlueCoastNotes.current.reqBody.searchcondition
        ).forEach((key) => {
          if (key != "addedfor")
            delete tableDataForBlueCoastNotes.current.reqBody.searchcondition[
              key
            ];
        });
        // console.log("searchConsidition====>>>");
        // delete tableDataForBlueCoastNotes.current.reqBody.searchcondition.addedfor;
      }
      console.warn("consoleforelseBlock===>>>");
    }

    setIpInfo(null);
    dispatch(clearBlueCoastNotesList());
    setNotesModalOpen(false);
    dispatch(clearTableData({ tableId: "bluecoastnotes" }));
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastnotes" }));
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
    textareayvalue.current = null;
    // dispatch(clearBlueCoastRepNotesList());
    // inputTextProspect.current.value = null
    // setIpInfo(rowData)
  };

  // ---------------------Notes in  Preview modal for prospect--------------------
  const notesDetailsPreview = () => {
    notesmodalopen();
  };

  // ------------------------useEffect for send mail to Beto and Travis and Jeannette for prod----------------------------------
  useEffect(() => {
    if (sendMailBetoClicked && saveRowdata !== undefined) {
      //  console.log("sendMailRowDataBeto323232>>>", sendMailRowDataBeto);
      setSendMailBetoModal(true);
      // console.log("saveRowdata>>>321", saveRowdata);
    }
  }, [sendMailBetoClicked, JSON.stringify(saveRowdata)]);

  useEffect(() => {
    if (sendMailTravisClicked && saveRowdata !== undefined) {
      setSendMailTravisModal(true);
      // console.log("saveRowdata>>>321", saveRowdata);
    }
  }, [sendMailTravisClicked, JSON.stringify(saveRowdata)]);

  const sendMailApiCall = async () => {
    // console.log("saveRowdata>>>>>123", saveRowdata, saveRowdata._id);

    let sendamilBody = {
      _id: saveRowdata._id,
      sendcontract:
        saveRowdata?.bluecoastPh == true || saveRowdata?.bluecoastphblue == true
          ? 1
          : 2,
    };
    await dispatch(mailSendToAEProd(sendamilBody));

    dispatch(
      setSnackbar({
        open: true,
        message: "Contact Email Send Successfully!",
        status: "success",
      })
    );

    let dataSet = {
      addedby: userInfo._id,
      addedfor: saveRowdata._id,
      disposition_name: "Advisor/AE Contacted",
      action_added_time_human: timeConverter({
        time: Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      }),
    };
    // console.log("dataSet>>>321", dataSet);
    if (
      saveRowdata?.bluecoastPh == true ||
      saveRowdata?.bluecoastphblue == true
    ) {
      dataSet["sendcontract"] = 1;
      dispatch(getDisposition(dataSet));
    } else {
      dataSet["sendcontract"] = 2;
      dispatch(getDisposition(dataSet));
    }
    // console.log("sendContractTo123>>", saveRowdata?.bluecoastPh)

    setSendMailBetoModal(false);

    setSendMailTravisModal(false);

    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
  };

  // --------------------------------------UseEffect for Sent Mail Lsit AEs----------------------------------
  useEffect(() => {
    if (
      sentMailListAEsClicked &&
      saveRowdata !== undefined &&
      saveRowdata !== null
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata?._id,
          load: true,
        })
      );

      let reqBody = {
        condition: {
          limit: 30,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {
          recipients: saveRowdata?.email,
        },
        project: {},
        token: token,
        email: "",
        count: false,
      };

      sentMailApiCall(reqBody);

      // console.log(reqBody, "reqBody>>>>>123");

      // console.log("saveRowdata>>>>123", saveRowdata)
    }
  }, [sentMailListAEsClicked, JSON.stringify(saveRowdata)]);

  const sentMailApiCall = async (reqBody) => {
    // console.log("saveRowdata>>>>321  ", saveRowdata);

    await dispatch(SendMailFulList(reqBody));

    setSentMailAEsOpen(true);

    setTimeout(() => {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata?._id,
          load: false,
        })
      );
    }, 1000);
  };

  useEffect(() => {
    if (annualrevenueBtnClicked && rowData) {
      // console.log("annualrevenueBtnClicked========>>", annualrevenueBtnClicked);
      // annualreviewCall()
      if (rowData && rowData.city && rowData.state) {
        let url = `https://www.google.com/search?q=${rowData?.prospectbusinessname} ${rowData?.city} ${rowData?.state} annual revenue`;
        encodeURI(url);
        // console.log("first===>>", rowData?.prospectbusinessname)
        window.open(url, "_blank");
      } else {
        let url = `https://www.google.com/search?q=${rowData?.prospectbusinessname} annual revenue`;
        encodeURI(url);
        // console.log("first===>>", rowData)

        window.open(url, "_blank");
      }
    }
  }, [annualrevenueBtnClicked]);

  /***********************UseEffect for Manage Task List Modal Open**************** */
  useEffect(() => {
    if (
      dispositionTaskListButtonClicked &&
      saveRowdata !== undefined &&
      saveRowdata !== null
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata?._id,
          load: true,
        })
      );

      // setDispoTaskListModalOpen(true)
      let requestBody = {
        condition: {
          limit: 30,
          skip: 0,
        },
        sort: {
          field: "createdon_datetime",
          type: "desc",
        },
        searchcondition: {
          prospect_id: saveRowdata?._id,
        },
        project: {},
        token: "",
        email: "",
        count: false,
      };

      dispTaskApiCall(requestBody);

    }
  }, [dispositionTaskListButtonClicked, JSON.stringify(saveRowdata)]);

  const dispTaskApiCall = async (requestBody) => {
    // console.log("saveRowdata>>>>321", saveRowdata);

    await dispatch(DispositonTaskList(requestBody));

    setDispoTaskListModalOpen(true);

    setTimeout(() => {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata?._id,
          load: false,
        })
      );
    }, 1000);
  };

  // useEffect(() => {
  //   if (
  //     userInfo?.email && (userInfo?.email == "bbutters@aspirepartnersusa.com" ||
  //       userInfo?.email == "wayne@aspirepartnersusa.com" ||
  //       userInfo?.email == "sellisha@aspirepartnersusa.com")
  //   ) {
  //     // console.log("Hitt btcy");
  //     setMenuSelect(2);
  //     // setbtcyapicall(true);
  //   }
  //  else{
  //   setMenuSelect(1);
  //  }
  // }, [JSON.stringify(userInfo)]);


  //-----------------------------------Event Link Button functionality--------------------------------
  useEffect(() => {
    if (eventLinkBtnClicked) {
      dispatch(setEnableRowLoader({ tableId: "bluecoastdata", rowId: saveRowdata?._id, load: true, }));
        resolvefunction(navigate, `/calendar-event-listing/${previewData?._id}${previewData.prospectemail?"?leademail="+previewData.prospectemail:""}`, bookedSlotListData({
        "condition": {
          "limit": 30,
          "skip": 0
        },
        "sort": {
          "field": "tstamp",
          "type": "desc"
        },
        "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "searchcondition": {
          "tstamp": {
            "$gte": moment().valueOf(),
            "userid":{"$in":['659d30c8fddb573879253cc2','645b27af0308b0288a86a4ff','645b27d70308b0288a86a501','645b27230308b0288a86a4fb','645b27550308b0288a86a4fd']},
            "atten_email":previewData.prospectemail?previewData.prospectemail:undefined,
            "status": {"$ne": 2},
          }
        },
        "count": false
      }));

    }
  }, [eventLinkBtnClicked])


  // ----------------------------------------List Navigation funtionality------------------------------
  const mainListbtnClicked = (val) => {
    // return
    console.log("val===>>>", val);

    setMenuSelect(val);

    if (val == 2) {
      dispatch(unmountTable({ tableId: "partnerCommisionReport" }));

      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {};
      tableDataForUsers.current.reqBody.searchcondition = {};

      let values = [
        { key: "Hospital", val: "Hospital" },
        { key: "Surgical Center", val: "Surgical Center" },
        { key: "Urgent Care", val: "Urgent Care" },
        {
          key: "Medical Practice (Not Primary Care Physician)",
          val: `Medical Practice (Not Primary Care Physician)`,
        },
      ];

      addFormBlueCstLead.current.fields[12].values = [...values];

      // addFormBlueCstLead.current.fields[10].values = [{ key: 'Hospital', val: 'Hospital' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Medical Practice', val: `Medical Practice \\(Not Primary Care Physician\\)` }];

      // console.log(addFormBlueCstLead.current.fields[10].values, "fields=======>>>>")

      // tableDataForUsers.current.tableTitle = "Aspire Lead List"
      tableDataForUsers.current.searchData.heading =
        "Search Underpayments Leads (Aspire)";
      setDataTranceferFlag("underpayment-aspire");

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
      dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
      dispatch(resetFormData({ formId: "searchFormId_bluecoast" }));
      dispatch(resetautocompletelist());

      // tableDataForUsers.current.reqBody.searchcondition['outsideData'] = true;

      tableDataForUsers.current.reqBody.searchcondition = {
        outsideData: true,
        is_deleted: { $exists: false },
        from_event: { $exists: false },
      };

      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        outsideData: true,
        is_deleted: { $exists: false },
      };

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody && data.name != "owner") {
            data.reqBody.searchcondition["outsideData"] = true;
          }
          if (data.reqBody?.searchcondition["or"]) {
            delete data.reqBody.searchcondition["or"];
          }
          if (data.reqBody?.searchcondition["bluecoastPh"]) {
            delete data.reqBody.searchcondition["bluecoastPh"];
          }
          if (data.reqBody?.searchcondition["bluecoastphblue"]) {
            delete data.reqBody.searchcondition["bluecoastphblue"];
          }
        }
      );

      setNotesViewOpen(false);
      dispatch(clearNotesViewList());

      dispatch(clearBlueCoastNotesList());

      tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;
      tableDataForUsers.current.reqBody.condition["skip"] = 0;

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 1) {
      tableDataForUsers.current.reqBody.searchcondition = {};
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {};

      let values = [
        { key: "Medical Practice", val: "Medical Practice" },
        { key: "Surgical Center", val: "Surgical Center" },
        { key: "Urgent Care", val: "Urgent Care" },
        { key: "Hospital", val: "Hospital" },
        { key: "Medical Practice Group", val: "Medical Practice Group" },
        { key: "Other", val: "Other" },
      ];

      addFormBlueCstLead.current.fields[12].values = [...values];

      // console.log(addFormBlueCstLead.current.fields[12].values, "fields=======>>>>")

      // tableDataForUsers.current.tableTitle = "Blue Coast Lead List"
      tableDataForUsers.current.searchData.heading =
        "Search Underpayments Leads (Blue Coast)";
      setDataTranceferFlag("underpayment-bluecostlead");
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
      dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
      dispatch(resetFormData({ formId: "searchFormId_bluecoast" }));
      dispatch(resetautocompletelist());
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "outsideData"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "bluecoastPh"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "bluecoastphblue"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "accessfree"
      ] = { $exists: false };
      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
        "is_deleted"
      ] = { $exists: false };

      tableDataForUsers.current.reqBody.searchcondition["outsideData"] = {
        $exists: false,
      };
      tableDataForUsers.current.reqBody.searchcondition["bluecoastPh"] = {
        $exists: false,
      };
      tableDataForUsers.current.reqBody.searchcondition["bluecoastphblue"] = {
        $exists: false,
      };
      tableDataForUsers.current.reqBody.searchcondition["accessfree"] = {
        $exists: false,
      };
      tableDataForUsers.current.reqBody.searchcondition["is_deleted"] = {
        $exists: false,
      };
      tableDataForUsers.current.reqBody.searchcondition["from_event"] = {
        $exists: false,
      };


      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody?.searchcondition["bluecoastPh"]) {
            data.reqBody.searchcondition["bluecoastPh"] = { $eq: null };
          }
        }
      );

      tableDataForBlueCoastAllNotes?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody?.searchcondition["bluecoastPh"]) {
            data.reqBody.searchcondition["bluecoastPh"] = { $eq: null };
          }
        }
      );
      // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
      // dispatch(resetFormData({ formId: "searchFormId_bluecoast" }));
      // dispatch(resetautocompletelist());
      // tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
      //   "outsideData"
      // ] = { $exists: false };
      // tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
      //   "bluecoastPh"
      // ] = { $exists: false };
      // tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
      //   "bluecoastphblue"
      // ] = { $exists: false };
      // tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
      //   "accessfree"
      // ] = { $exists: false };
      // tableDataForBlueCoastAllNotes.current.reqBody.searchcondition[
      //   "is_deleted"
      // ] = { $exists: false };

      // tableDataForUsers.current.reqBody.searchcondition["outsideData"] = {
      //   $exists: false,
      // };
      // tableDataForUsers.current.reqBody.searchcondition["bluecoastPh"] = {
      //   $exists: false,
      // };
      // tableDataForUsers.current.reqBody.searchcondition["bluecoastphblue"] = {
      //   $exists: false,
      // };
      // tableDataForUsers.current.reqBody.searchcondition["accessfree"] = {
      //   $exists: false,
      // };
      // tableDataForUsers.current.reqBody.searchcondition["is_deleted"] = {
      //   $exists: false,
      // };

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody?.searchcondition["bluecoastPh"]) {
            data.reqBody.searchcondition["bluecoastPh"] = { $eq: null };
          }
          if (data.reqBody?.searchcondition["bluecoastphblue"]) {
            data.reqBody.searchcondition["bluecoastphblue"] = { $eq: null };
          }
          if (data.reqBody && data.name != "owner") {
            data.reqBody.searchcondition["outsideData"] = { $eq: null };
          }
        }
      );

      tableDataForBlueCoastAllNotes?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody?.searchcondition["bluecoastPh"]) {
            data.reqBody.searchcondition["bluecoastPh"] = { $eq: null };
          }
          if (data.reqBody?.searchcondition["bluecoastphblue"]) {
            data.reqBody.searchcondition["bluecoastphblue"] = { $eq: null };
          }
          if (data.reqBody && data.name != "owner") {
            data.reqBody.searchcondition["outsideData"] = { $eq: null };
          }
        }
      );
      setNotesViewOpen(false);

      dispatch(clearNotesViewList());
      dispatch(clearBlueCoastNotesList());

      tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;
      tableDataForUsers.current.reqBody.condition["skip"] = 0;

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 5) {
      tableDataForUsers.current.reqBody.searchcondition = {};
      // tableDataForUsers.current.tableTitle = "Pharmacy Lead List (Aspire)"
      let values = [
        { key: "Affinity Group", val: "Affinity Group" },
        // { key: "Chamber of Commerce", val: "Chamber of Commerce" },
        { key: "MLM", val: "MLM" },
        { key: "Affiliate Organization", val: "Affiliate Organization" },
        { key: "Employer", val: "Employer" },
        { key: "Other", val: "Other" },
      ];

      addFormBlueCstPharmacyLead.current.fields[10].values = [...values];

      tableDataForUsers.current.searchData.heading =
        "Search Pharmacy Lead(Aspire)";

      setDataTranceferFlag("pharmacy-aspire");
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
      dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
      dispatch(resetFormData({ formId: "searchFormId_bluecoast" }));
      dispatch(resetautocompletelist());
      if (tableDataForUsers.current.reqBody.searchcondition["outsideData"]) {
        delete tableDataForUsers.current.reqBody.searchcondition["outsideData"];
      }
      // tableDataForUsers.current.reqBody.searchcondition['bluecoastPh'] = { $exists: true }

      tableDataForUsers.current.reqBody.searchcondition = {
        bluecoastPh: { $exists: true },
        is_deleted: { $exists: false },
        from_event: { $exists: false },
      };

      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        bluecoastPh: { $exists: true },
        is_deleted: { $exists: false },
      };

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody && data.name != "owner") {
            if (data.reqBody.searchcondition["outsideData"]) {
              delete data.reqBody.searchcondition["outsideData"];
            }
            if (data.reqBody.searchcondition["bluecoastphblue"]) {
              delete data.reqBody.searchcondition["bluecoastphblue"];
            }
            data.reqBody.searchcondition["bluecoastPh"] = true;
          }
        }
      );
      setNotesViewOpen(false);

      dispatch(clearNotesViewList());

      tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;

      tableDataForUsers.current.reqBody.condition["skip"] = 0;

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 6) {
      tableDataForUsers.current.reqBody.searchcondition = {};

      let values = [
        { key: "Affinity Group", val: "Affinity Group" },
        { key: "Chamber of Commerce", val: "Chamber of Commerce" },
        { key: "MLM", val: "MLM" },
        { key: "Affiliate Organization", val: "Affiliate Organization" },
        { key: "Employer", val: "Employer" },
        { key: "Other", val: "Other" },
      ];

      addFormBlueCstPharmacyLead.current.fields[10].values = [...values];
      // tableDataForUsers.current.tableTitle = "Pharmacy Lead List (Blue Coast)"
      tableDataForUsers.current.searchData.heading =
        "Search Pharmacy Lead (Blue Coast)";

      setDataTranceferFlag("pharmacy-Bluecoust");
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
      dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
      dispatch(resetFormData({ formId: "searchFormId_bluecoast" }));
      dispatch(resetautocompletelist());
      if (tableDataForUsers.current.reqBody.searchcondition["outsideData"]) {
        delete tableDataForUsers.current.reqBody.searchcondition["outsideData"];
      }
      if (tableDataForUsers.current.reqBody.searchcondition["bluecoastPh"]) {
        delete tableDataForUsers.current.reqBody.searchcondition["bluecoastPh"];
      }
      // tableDataForUsers.current.reqBody.searchcondition['bluecoastphblue'] = { $exists: true }

      tableDataForUsers.current.reqBody.searchcondition = {
        bluecoastphblue: { $exists: true },
        is_deleted: { $exists: false },
        from_event: { $exists: false },
      };

      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        bluecoastphblue: { $exists: true },
        is_deleted: { $exists: false },
      };

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody && data.name != "owner") {
            if (data.reqBody.searchcondition["outsideData"]) {
              delete data.reqBody.searchcondition["outsideData"];
            }
            if (data.reqBody.searchcondition["bluecoastPh"]) {
              delete data.reqBody.searchcondition["bluecoastPh"];
            }
            data.reqBody.searchcondition["bluecoastphblue"] = true;
          }
        }
      );
      setNotesViewOpen(false);

      dispatch(clearNotesViewList());

      tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;

      tableDataForUsers.current.reqBody.condition["skip"] = 0;

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 8) {
      tableDataForUsers.current.reqBody.searchcondition = {};
      // tableDataForUsers.current.tableTitle = "All Leads (Underpayments & Pharmacy)"
      tableDataForUsers.current.searchData.heading =
        "Search All Leads (Underpayments & Pharmacy)";
      if (
        tableDataForUsers.current.reqBody.searchcondition[
        ("outsideData",
          "bluecoastPh",
          "bluecoastphblue",
          "is_deleted",
          "accessfree")
        ]
      ) {
        delete tableDataForUsers.current.reqBody.searchcondition[
          ("outsideData",
            "bluecoastPh",
            "bluecoastphblue",
            "is_deleted",
            "accessfree")
        ];
      }

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody && data.name != "owner") {
            if (data.reqBody.searchcondition["outsideData"]) {
              delete data.reqBody.searchcondition["outsideData"];
            }
            if (data.reqBody.searchcondition["bluecoastphblue"]) {
              delete data.reqBody.searchcondition["bluecoastphblue"];
            }
          }
        }
      );

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 9) {
      tableDataForUsers.current.reqBody.searchcondition = {};
      // tableDataForUsers.current.tableTitle = "All Leads (Underpayments & Pharmacy)"
      tableDataForUsers.current.searchData.heading =
        "Search All Pharmacy Leads";
      if (
        tableDataForUsers.current.reqBody.searchcondition[
        ("outsideData", "is_deleted", "accessfree")
        ]
      ) {
        delete tableDataForUsers.current.reqBody.searchcondition[
          ("outsideData", "is_deleted", "accessfree")
        ];
      }
      setDataTranceferFlag("pharmacy-Bluecoust");
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );

      tableDataForUsers.current.reqBody.searchcondition = {
        $or: [
          { bluecoastphblue: { $exists: true } },
          { bluecoastPh: { $exists: true } },
        ],
        is_deleted: {
          $exists: false,
        },
        from_event: { $exists: false },
      };

      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        $or: [
          { bluecoastphblue: { $exists: true } },
          { bluecoastPh: { $exists: true } },
        ],
      };

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody && data.name != "owner") {
            if (data.reqBody.searchcondition["outsideData"]) {
              delete data.reqBody.searchcondition["outsideData"];
            }

            data.reqBody.searchcondition[
              ("bluecoastphblue", "bluecoastPh")
            ] = true;
          }
        }
      );

      setNotesViewOpen(false);

      dispatch(clearNotesViewList());
      tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;
      tableDataForUsers.current.reqBody.condition["skip"] = 0;

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 14) {
      tableDataForAllTask.current.reqBody.searchcondition = {};
      // tableDataForAllTask.current.tableTitle = "All Leads (Underpayments & Pharmacy)"
      tableDataForAllTask.current.searchData.heading = "Search All Added Task";

      tableDataForAllTask?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
      setTaskListToggle(1);



      store.dispatch(DispositonTaskList(tableDataForAllTask.current.reqBody));
    } else if (val == 10) {
      tableDataForUsers.current.reqBody.searchcondition = {};
      // tableDataForUsers.current.tableTitle = "All Leads (Underpayments & Pharmacy)"
      tableDataForUsers.current.searchData.heading =
        "Search All Underpayments Leads";
      if (
        tableDataForUsers.current.reqBody.searchcondition[
        ("outsideData", "is_deleted", "accessfree")
        ]
      ) {
        delete tableDataForUsers.current.reqBody.searchcondition[
          ("outsideData", "is_deleted", "accessfree")
        ];
      }
      setDataTranceferFlag("pharmacy-Bluecoust");
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );

      tableDataForUsers.current.reqBody.searchcondition = {
        accessfree: { $exists: false },
        bluecoastPh: { $exists: false },
        bluecoastphblue: { $exists: false },
        is_deleted: { $exists: false },
        from_event: { $exists: false },

      };

      tableDataForBlueCoastAllNotes.current.reqBody.searchcondition = {
        accessfree: { $exists: false },
        bluecoastPh: { $exists: false },
        bluecoastphblue: { $exists: false },
        is_deleted: { $exists: false },
      };

      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          if (data.reqBody && data.name != "owner") {
            if (data.reqBody.searchcondition["outsideData"]) {
              delete data.reqBody.searchcondition["outsideData"];
            }
          }
        }
      );

      setNotesViewOpen(false);

      dispatch(clearNotesViewList());

      tableDataForBlueCoastAllNotes.current.reqBody.condition["skip"] = 0;

      tableDataForUsers.current.reqBody.condition["skip"] = 0;

      store.dispatch(BlueCoastlist(tableDataForUsers.current.reqBody));
    } else if (val == 10) {
      tableDataForUsers.current.reqBody.searchcondition = {};
      // tableDataForUsers.current.tableTitle = "All Leads (Underpayments & Pharmacy)"
      tableDataForUsers.current.searchData.heading =
        "Search All Leads (Underpayments & Pharmacy)";
      if (
        tableDataForUsers.current.reqBody.searchcondition[
        ("outsideData", "is_deleted", "accessfree")
        ]
      ) {
        delete tableDataForUsers.current.reqBody.searchcondition[
          ("outsideData", "is_deleted", "accessfree")
        ];
      }

      setNotesViewOpen(false);

      setDataTranceferFlag("pharmacy-Bluecoust");
      tableDataForUsers?.current?.searchData?.formData?.fields?.forEach(
        (data) => {
          data.defaultValue = undefined;
        }
      );
    } else if (val == 3) {
      // setRepPageSlug(3)
      tableDataForLead.current.reqBody.searchcondition = {
        rep_page_slug: "gc7MqHWxe7HIW",
      };
      tableDataForLead.current.tableTitle =
        "Pharmacy Savings Blue Coast Lead List";
      tableDataForLead.current.searchData.heading =
        "Search Pharmacy Savings Blue Coast Lead";

      store.dispatch(getleaddata(tableDataForLead.current.reqBody));
      // console.log("data12345", repPageSlug);

      // tableDataForLead?.current?.searchData?.formData?.fields?.forEach((data) => {
      //   data.searchcondition ={ "rep_page_slug": "gc7MqHWxe7HIW" }
      // })
    } else {
      // setRepPageSlug(4)
      tableDataForLead.current.reqBody.searchcondition = {
        rep_page_slug: "NycjyqRnjFUEd",
      };
      tableDataForLead.current.tableTitle = "Pharmacy Savings Aspire Lead List";
      tableDataForLead.current.searchData.heading =
        "Search Blue Coast Pharmacy Savings Aspire Lead";

      // console.log("data12345", repPageSlug);
      store.dispatch(getleaddata(tableDataForLead.current.reqBody));

      // tableDataForLead?.current?.searchData?.formData?.fields?.forEach((data) => {
      //   data.searchcondition ={ "rep_page_slug": "NycjyqRnjFUEd" }
      //   // console.log("data1234", data, searchFormData);
      // })
    }
  };

  const dotClick = useSelector(
    (state) => state.tableSlice?.tableCellClicked?.bluecoastdata?.last_note
  );
  const [openImgModal, setOpenImgModal] = useState(false);

  const [lastNoteOpen, setLastNoteOpen] = useState(null);

  useEffect(() => {
    if (dotClick == true && lastNoteOpen !== null) {
      setOpenImgModal(true);
      // console.log("hitttttt=============>>>>>")
    }
    // console.log("imgUrl", imgUrl);
  }, [dotClick]);

  // useEffect(() => {
  //   console.log("lastNoteOpen=============>>>>>>>>", lastNoteOpen, addedForId);
  // }, [lastNoteOpen, addedForId])

  const closeImageModalView = () => {
    setLastNoteOpen(null);
    setOpenImgModal(false);
    setsaveRowdata(null);
    setAddedForId({});
    setIpInfo(null);

    // dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
  };

  store.subscribe(() => {
    const storeData = store.getState();

    if (
      storeData.tableSlice.tempRowData.bluecoastdata?.last_note != undefined ||
      storeData.tableSlice.tempRowData.bluecoastdata?.last_note != null ||
      storeData.tableSlice.tempRowData.bluecoastdata?.last_note?.length > 0
    ) {
      setLastNoteOpen(
        storeData.tableSlice.tempRowData.bluecoastdata.last_note
          ? storeData.tableSlice.tempRowData.bluecoastdata.last_note
          : null
      );
    }
  });

  var modifyTableHeaders = [
    route?.pathname === "/businesslead-list-access-deal" ? { val: "confidence", name: "Confidence (H, M, L)" } : { val: "ae_name_show", name: "AE/REP/Advisor  (Name / Phone No. / Email)" },
    route?.pathname === "/businesslead-list-access-deal" ? { val: "ae_name_show", name: `AE/REP Name` } : { val: "prospect_info", name: "Prospect's (Name / Phone No./ Email)" },
    route?.pathname === "/businesslead-list-access-deal" ? { val: "prospect_info", name: `Client Name` } :{ val: "prospectbusinessname_type", name: "Prospect's Medical Facility Business Name/Type", },
    { val: "prospecttitle", name: "Prospect's Title" },
    route?.pathname === "/businesslead-list-access-deal" ? { val: "prospectbusinessname_type", name: "Company Name", } :  {val: "annual_billing",name: "Total Gross Insurance Gross Receipts (in $) "},
    { val: "disposition_show", name: "Last Disposition" },
    { val: "last_note", name: "Last Note" },
    { val: "owner_name", name: "Owner Name" },
    { val: "priority_show", name: "Priority" },
    route?.pathname === "/businesslead-list-access-deal" ? {val: "dc_date",name: "DC Date",type: "datetime",format: "MM/DD/YYYY",} : {val: "createdon_datetime",name: "Updation Time",type: "datetime",format: "MM/DD/YYYY",},
  ];

  

  if (route?.pathname == "/businesslead-list-access-deal") {
    const tableHeader = modifyTableHeaders.filter((x) => { return x.val !== "prospecttitle" && x.val !== "disposition_show" && x.val !== "owner_name" && x.val !== "priority_show" && x.val !== "last_note" })

 
    modifyTableHeaders = tableHeader


    const headersForAccessDeal = [
      {val: "approxgrossreciept",name: "Revenue",},
      { val: "other_info", name: "Other" },
      { val: "contarct_review_date", name: "Contarct Review Date",type: "datetime",format: "MM/DD/YYYY",},
      { val: "next_action_date", name: "Next Action Date",type: "datetime",format: "MM/DD/YYYY",},
      { val:"next_steps_view" ,name:"Next Steps"},
      { val:"lost_declined" ,name:"Lost / Declined"},
      { val:"why" ,name:"Why"}
    ]

    headersForAccessDeal.map((x) => modifyTableHeaders.push(x))


  }



  const searchbtn = (item) => {
    return item?.id === "delete_lead";
  };
  // adding extra button 6450fa3a771fdbeb3bbdbfa6
  useEffect(() => {
    if (
      userInfo._id === "64193221a4a0f3904296b104" ||
      userInfo._id === "644b10c46967259798e75978" ||
      userInfo._id === "6426b8597fec19a24e5186fa" ||
      userInfo._id === "6426b9d0fab6d78fa2e2f06b" ||
      userInfo._id === "6426bab1fab6d78fa2e2f075"
    ) {
      const isDbtnAbalabel = tableDataForUsers.current.buttons.find(searchbtn);
      if (isDbtnAbalabel === undefined) {
        tableDataForUsers.current.buttons.push({
          id: "delete_lead",
          type: "other",
          icon_type: "delete_icon",
          label: "Delete",
          name: "delete_icon",
        });
      }

      // console.log("isDbtnAbalabel", isDbtnAbalabel);
    }
  }, [userInfo]);


  let tableDataForUsers = useRef({
    tableId: "bluecoastdata",
    showReload: true,
    showFilter: true,
    upperPagination: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "bclusers/bcl-lead-list",
      tableCountEndpoint: "bclusers/bcl-lead-list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: leadid ? {
        _id: leadid
      } :
        {
          outsideData: { $exists: false },
          bluecoastPh: { $exists: false },
          bluecoastphblue: { $exists: false },
          is_deleted: { $exists: false },
          accessfree: { $exists: false },
          from_event: { $exists: false },
        },
      project: {},
      token: token,
      email: "",
      count: false,
    },
    //******************************** By Default Rows per page option will be available**************************//
    clickableColumn: ["last_note"],
    rowsPerPageOptions: [30],
    sortFields: [
      "fullname",
      "prospectfullname",
      "email",
      "phone",
      "createdon_datetime",
      "prospectemail",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      process.env.REACT_APP_ENVIRONMENT !== "prod" && !leadid && {
        id: "addUser",
        type: "group_add_icon",
        title: "Add User",
        className: "group_add_icon",
        default: true,
      },
      !leadid && {
        id: "notesView",
        type: "chrome_reader_mode_icon",
        title: "Notes View",
        className: "chrome_reader_mode_icon",
        default: true,
      },
      {
        id: "exportCSV",
        type: "ios_share_icon",
        title: "Export to CSV",
        className: "ios_share_icon",
        default: true,
      },
      process.env.REACT_APP_ENVIRONMENT !== "prod" && !leadid && {
        id: "allnotes",
        type: "format_list_bulleted",
        title: "All Notes for Prospect's",
        className: "format_list_bulleted",
        default: true,
      },
      process.env.REACT_APP_ENVIRONMENT !== "prod" && !leadid && {
        id: "dispositionLeadSummary",
        type: "display_settings_icon",
        title: "Disposition at a Glance",
        className: "display_settings_icon",
        default: true,
      },
    ],
    buttons: [
      {
        id: "editBlCoastLead",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "viewBtnAction",
        label: "Preview",
        type: "other",
        icon_type: "preview",
        name: "preview",
      },
    
      {
        id: "previewIpInfo",
        type: "other",
        icon_type: "router_icon",
        label: "Ip-Info",
        name: "router_icon",
      },
      {
        id: "notes",
        type: "other",
        icon_type: "summarize_icon_icon",
        label: "Note About Prospect's",
        name: "summarize_icon_icon",
        showcount: true,
        countFiled: "notes_count",
      },
      {
        id: "manage_dist",
        type: "other",
        icon_type: "diversity_3_icon",
        label: "Manage Disposition",
        name: "diversity_3_icon",
      },
      {
        id: "business_manager",
        type: "other",
        icon_type: "people_icon",
        label: "Business Manager(s)",
        name: "people_icon",
      },
      process.env.REACT_APP_ENVIRONMENT != "prod" && {
        id: "manage_dist_task_list",
        type: "other",
        icon_type: "list_alt",
        label: "Task List",
        name: "list_alt",
      },
      {
        id: "all_dispositions",
        type: "other",
        icon_type: "speaker_notes_icon",
        label: "Show All Disposition",
        name: "speaker_notes_icon",
      },
      {
        id: "set_priority",
        type: "other",
        icon_type: "move_up_icon",
        label: "Set Priority",
        name: "move_up_icon",
      },

      {
        id: "trasfer_ownership",
        type: "other",
        icon_type: "transfer_within_a_station_icon",
        label: "Transfer Ownership",
        name: "transfer_within_a_station_icon",
      },
      {
        id: "annul_billing",
        type: "other",
        icon_type: "credit_score_icon",
        label: "Total Gross Insurance Gross Receipts",
        name: "credit_score_icon",
      },
      {
        id: "data_transfer",
        type: "other",
        icon_type: "Data_transfer_icon",
        label: "Data transfer",
        name: "send_to_mobile_icon",
      },
      {
        id: "operational_disposition",
        type: "other",
        icon_type: "disposition_after_contract",
        label: "Operational Disposition",
        name: "settings_input_composite_icon",
        condField: "contract_signed_value",
        // condField:'disposition_show',
        condVal: "true",
      },
      {
        id: "send_for_beto",
        type: "other",
        icon_type: "send_time_extension_icon",
        label: 'Resend "Next Steps" email',
        name: "send_time_extension_icon",
        condField: "is_beto",
        condVal: "Beto",
      },
      {
        id: "send_for_travis",
        type: "other",
        icon_type: "send_time_extension_icon",
        label: 'Resend "Next Steps" email',
        name: "send_time_extension_icon",
        condField: "is_jeannette",
        condVal: "Jeannette",
      },
      {
        id: "sent_mail_list_to_AEs",
        type: "other",
        icon_type: " forward_to_inbox_two_tone_icon",
        label: "Sent Mail List to AEs",
        name: " forward_to_inbox_two_tone_icon",
      },
      {
        id: "annual_revenue",
        type: "other",
        icon_type: "monetization_on_icon",
        label: "Annual Revenue",
        name: "monetization_on_icon",
      },
      (user?.email === "beto@betoparedes.com" || user?.email === "beto@yopmail.com") && {
        id: "event_list_link",
        type: "other",
        icon_type: "calendar_month_icon",
        label: "Calendar Events",
        name: "calendar_month_icon",
      },
    ],

    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
// wark space

    searchData: {
      heading: "Search Underpayments Leads (Blue Coast)",
      type: "drawer",
      formData: {
        id: "searchFormId_bluecoast",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by AE/Rep/Advisor Name",
            name: "fullname",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
            preload: false,
            reqBody: {condition: {limit: 5,skip: 0,},
            sort: {type: "asc",field: "email",},
            searchcondition: {outsideData: { $eq: null },bluecoastPh: { $eq: null },},
            type: "fullname",
            source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "rep_id" },
          },
          {
            id: 2,
            label: "Search by AE/Rep/Advisor Email",
            name: "email",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
            reqBody: {
              condition: {limit: 5,skip: 0,},
              sort: {type: "asc",field: "email",},
              searchcondition: {outsideData: { $eq: null },bluecoastPh: { $eq: null },},
              type: "email",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "rep_id" },
          },
          {
            id: 5,
            label: "Search by Prospect's Name",
            name: "prospectfullname",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
            reqBody: {
              condition: {limit: 5,skip: 0,},
              sort: {type: "asc",field: "email",},
              searchcondition: {outsideData: { $eq: null },bluecoastPh: { $eq: null },},
              type: "prospect_name",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },
          {
            id: 20,
            label: "Search by Prospect's Email",
            name: "prospectemail",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
            reqBody: {
              condition: {limit: 5,skip: 0,},
              sort: {type: "asc",field: "email",},
              searchcondition: {outsideData: { $eq: null },bluecoastPh: { $eq: null },},
              type: "prospectemail",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },
          {
            id: 23,
            label: "Search by Disposition",
            name: "disposition_show",
            type: "select",
            multiple: false,
            values: [
              { val: "New Lead", name: "New Lead" },
              { val: "Accepted", name: "Accepted" },
              { val: "Declined", name: "Declined" },
              { val: "Advisor/AE Contacted", name: "Advisor/AE Contacted" },
              {val: "Advisor/AE Meeting Set",name: "Advisor/AE Meeting Scheduled",},
              {val: "Advisor/AE Meeting Completed",name: "Advisor/AE Meeting Completed",},
              {val: "Advisor/AE Meeting Missed",name: "Advisor/AE Meeting Missed",},
              { val: "Discovery Call Set", name: "Discovery Call Scheduled" },
              {val: "Discovery Call Completed",name: "Discovery Call Completed",},
              {val: "Discovery Call Cancelled",name: "Discovery Call Cancelled",},
              {val: "Discovery Call Missed",name: "Discovery Call Missed",},
              { val: "Contract Sent", name: "Contract Sent" },
              {val: "Contract Review Scheduled",name: "Contract Review Scheduled",},
              {val: "Contract Review Completed",name: "Contract Review Completed",},
              { val: "Contract Signed", name: "Contract Signed" },
              { val: "Contract Completed", name: "Contract Completed" },
              { val: "Data Call Requested", name: "Data Call Requested" },
              { val: "Data Call Scheduled", name: "Data Call Scheduled" },
              { val: "Data Call Completed", name: "Data Call Completed" },
              { val: "Link Sent", name: "Link Sent" },
              {val: "Has Contracts No Claims Uploaded",name: "Has Contracts No Claims Uploaded",},
              {val: "Has Claims No Contracts Uploaded",name: "Has Claims No Contracts Uploaded",},
              {val: "Data and Contracts Upload Completed",name: "Data and Contracts Upload Completed",},
              { val: "Initial Evaluation", name: "Initial Evaluation" },
              { val: "On Hold", name: "On Hold" },
              { val: "Collection Begun", name: "Collection Begun" },
              { val: "Follow-Up Collection", name: "Follow-Up Collection" },
              { val: "In Settlement", name: "In Settlement" },
              { val: "Account Cancelled", name: "Account Cancelled" },
              { val: "Lost", name: "Lost" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: {
              key: "last_disposition_name",
              param: "$regex",
              options: "im",
            },
          },
          {
            id: 109,
            label: "Search by Keyword",
            name: "all_field",
            className: "list_search_item countDiv__column--col6 ",
            type: "text",
            payloadFormat: { key: "all_field", param: "$regex", options: "i" },
          },

 
        ],
      },
    },
  });

  // let data1=[{name:"<span>Jhon Snow</span><hr>8965478523</hr><hr>snow@yopmail.com</hr>"}]

  // ********************for Pharmacy Bluecoast and aspire**********************

  var modifyTableHeadersForLead = [
    { val: "name", name: "AE/REP (Name/Phone No./Email)" },
    { val: "prospect_info", name: "Prospect's (Name/Phone No./Email)" },
    { val: "owner_name", name: "Owner" },
    { val: "priority_show", name: "Priority" },
    { val: "disposition_show", name: "Last Disposition" },
    {val: "updatedon_datetime",name: "Updation Time",type: "datetime",format: "MM/DD/YYYY",},
  ];

  let tableDataForLead = useRef({
    tableId: "lead_data",
    tableTitle: "",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/lead-list-count",
      tableCountEndpoint: "lead-manage/lead-list-count",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: token,
      email: "",
      count: false,
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: [
      "lead_uniq_id",
      "fullname",
      "email",
      "status",
      "createdon_datetime",
      "updatedon_datetime",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this record? This can't be undone.</p>",
        modalHeader: "<p> Alert! </p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          body: {
            source: "training_list",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status?</p>",
        modalHeader: "<p> Alert! </p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          body: {
            source: "training_list",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      {
        id: "viewBtnAction",
        label: "Preview",
        type: "other",
        icon_type: "preview",
        name: "preview",
      },
      {
        id: "notes",
        type: "other",
        icon_type: "summarize_icon_icon",
        label: "Note About Prospect's",
        name: "summarize_icon_icon",
        showcount: true,
        countFiled: "notes_count",
      },
      {
        id: "previewIpInfo",
        type: "other",
        icon_type: "router_icon",
        label: "Ip-Info",
        name: "router_icon",
      },
      {
        id: "set_priority",
        type: "other",
        icon_type: "move_up_icon",
        label: "Set Priority",
        name: "move_up_icon",
      },
      {
        id: "trasfer_ownership",
        type: "other",
        icon_type: "transfer_within_a_station_icon",
        label: "Trasnfer Ownership",
        name: "transfer_within_a_station_icon",
      },
      {
        id: "manage_dist",
        type: "other",
        icon_type: "diversity_3_icon",
        label: "Manage Disposition",
        name: "diversity_3_icon",
      },
      {
        id: "business_manager",
        type: "other",
        icon_type: "people_icon",
        label: "Business Manager's",
        name: "people_icon",
      },
      {
        id: "all_dispositions",
        type: "other",
        icon_type: "speaker_notes_icon",
        label: "Show All Disposition",
        name: "speaker_notes_icon",
      },
    ],

    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },

    searchData: {
      heading: "Search Blue Coast Lead",
      type: "drawer",
      formData: {
        id: "search_lead",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search by Prospect's Name",
            name: "fullname",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/auto-complete-lead-name",
            reqBody: {
              source: "google_events",
              sort: { type: "desc", field: "fullname" },
              condition: { limit: 10, skip: 0 },
              searchcondition: {
              },

              autocomplete_for: "fullname",
            },
            payloadFormat: { key: "_id" },
          },
          {
            id: 10,
            label: "Search By Prospect's Email",
            name: "email",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/auto-complete-lead-search",
            reqBody: {
              source: "google_events",
              condition: { limit: 10, skip: 0 },
              sort: { type: "desc", field: "email" },
              searchcondition: {},
              autocomplete_for: "email",
            },
            payloadFormat: { key: "email", options: "i" },
          },

          {
            id: 12,
            label: "Search by Prospect's Phone Number",
            name: "phone",
            type: "text",
            inputType: "phone",
            className: "inputblock2line",
            payloadFormat: {
              key: "phone_trimmed",
              param: "$regex",
              options: "i",
            },
            errorMessage: {
              pattern:
                "Phone no does not contain any special characters (@#%&$)",
              maxLength: "Phone number can not be greater than 10 digits",
            },
          },

          {
            id: 5,
            label: "Search By AE",
            name: "rep",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "users",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "name",
                type: "asc",
              },
              searchcondition: {},
              project: {},
              autocomplete_for: "rep",
            },
            payloadFormat: { key: "rep_id" },
          },

          {
            id: 25,
            label: "Search by Owner",
            name: "user",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            // multiple: true,
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/spcialautocompletename",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
              type: "prospectemail",
              source: "bluecoast_leads",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "owner" },
          },
          {
            id: 37,
            label: "Search by Priority",
            name: "priority",
            // className: 'Status',
            type: "select",
            multiple: false,
            values: [
              { val: "Low", name: "Low" },
              { val: "Medium", name: "Medium" },
              { val: "High", name: "High" },
              // { val: "N/A", name: "N/A" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "priority" },
          },
          {
            id: 23,
            label: "Search by Disposition",
            name: "disposition_show",
            // className: 'Status',
            type: "select",
            multiple: false,
            values: [
              { val: "New Lead", name: "New Lead" },
              { val: "Accepted", name: "Accepted" },
              { val: "Declined", name: "Declined" },
              { val: "Advisor/AE Contacted", name: "Advisor/AE Contacted" },
              // { val: "Strategy Call", name: "Strategy Call" },
              {
                val: "Advisor/AE Meeting Set",
                name: "Advisor/AE Meeting Scheduled",
              },
              {
                val: "Advisor/AE Meeting Completed",
                name: "Advisor/AE Meeting Completed",
              },

              { val: "Discovery Call Set", name: "Discovery Call Scheduled" },
              {
                val: "Discovery Call Completed",
                name: "Discovery Call Completed",
              },
              {
                val: "Discovery Call Cancelled",
                name: "Discovery Call Cancelled",
              },
              { val: "Contract Sent", name: "Contract Sent" },
              {
                val: "Contract Review Scheduled",
                name: "Contract Review Scheduled",
              },
              {
                val: "Contract Review Completed",
                name: "Contract Review Completed",
              },

              { val: "Contract Signed", name: "Decline" },
              // { val: "Cold Lead", name: "Cold Lead" },
              { val: "Lost", name: "Lost" },
              // { val: "N/A", name: "N/A" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: {
              key: "last_disposition_name",
              param: "$regex",
              options: "im",
            },
          },

          {
            id: 8,
            label: "Search by Start Date",
            name: "createdon_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "inputblock2line",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },

          {
            id: 9,
            label: "Search by End Date",
            name: "createdon_datetime1",
            className: "inputblock2line",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY'
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  // ***************************Edit for view task List*******************************************
  const editTaskBtnClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.alltaskdata?.editalltask
      ? state.tableSlice.customButtonClicked.alltaskdata.editalltask
      : false
  );
  const editDataTask = useSelector((state) =>
    state.tableSlice?.tempRowData?.alltaskdata
      ? state.tableSlice.tempRowData.alltaskdata
      : null
  );

  useEffect(() => {
    if (
      editTaskBtnClicked &&
      editDataTask &&
      Object.keys(editDataTask).length > 0
    ) {
      setAddTaskModalOpen(true);
    }
  }, [editTaskBtnClicked, JSON.stringify(editDataTask)]);

  /******************************View All Task List********************************************** */
  var modifyTableHeadersForAllTask = [
    { val: "tasktitle", name: "Task Title" },
    // { val: "taskdescription", name: "Task Description" },
    { val: "assignee_name", name: "Assignee" },
    { val: "contact_name", name: "Contact" },
    { val: "prospect_name", name: "Prospect" },
    {
      val: "tasktargetdate",
      name: "Due Date & Time",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss a",
    },

    { val: "markasdone_type", name: "Task Status" },
  ];

  let tableDataForAllTask = useRef({
    tableId: "alltaskdata",
    tableTitle: "",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/dispositiontasklist",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/dispositiontasklist",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: token,
      email: "",
      count: false,
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [30],
    sortFields: [
      "addedfor_name",
      "prospect_name",
      "addedbyname",
      "markasdone_type",
      "tasktargetdate",
      // "updatedon_datetime",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this record? This can't be undone.</p>",
        modalHeader: "<p> Alert! </p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          // endpoint: "training-manage/delete-training",
          body: {
            source: "training_list",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      // {
      //   id: "status",
      //   label: "Status",
      //   type: "api",
      //   category: "status",
      //   icon_type: "toggle_off",
      //   className: "status_data",
      //   modalClassName: "delete_modal",
      //   modalTitle: "<p>Are you sure you want to change the status?</p>",
      //   modalHeader: "<p> Alert! </p>",
      //   btn_label: ["Active", "Inactive"],
      //   api_data: {
      //     base_url: process.env.REACT_APP_API_URL,
      //     // endpoint: "training-manage/training-status-update",
      //     body: {
      //       source: "training_list",
      //       secret: "AZ|lepL`",
      //       token:
      //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
      //     },
      //   },
      // },
      // {
      //   id: "allnotes",
      //   type: "format_list_bulleted",
      //   title: "All Notes",
      //   className: "format_list_bulleted",
      //   default: true,
      // },
    ],
    buttons: [
      {
        id: "editalltask",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },

      {
        id: "viewApi",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Task Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/dispositiontasklist",
          body: {
            source: "",
            condition: { limit: 1, skip: 0 },
            sort: { type: "asc", field: "fullname" },
            searchcondition: {},
            token: token,
          },
        },
        headers: [
          // { val: "addedfor_name", label: "AE/REP/Advisor Name" },
          { val: "tasktitle", label: "Task Title" },
          { val: "taskdescription", label: "Task Description" },
          { val: "assignee_name", label: "Assignee" },
          { val: "contact_name", label: "Contact" },
          { val: "prospect_name", label: "Prospect's Name" },
          {
            val: "tasktargetdate",
            label: "Due Date & Time",
            type: "datetime",
            format: "MM/DD/YYYY",
          },
          { val: "markasdone_type", label: "Task Status" },
        ],
      },
      //   {
      //     id: "status",
      //     label: "Change Task Status",
      //     type: "api",
      //     category: "status",
      //     icon_type: "toggle_off",
      //     className: "status_data",
      //     modalClassName: "delete_modal",
      //     modalHeader: "<p> Alert !!</p>",
      //     modalTitle: "<p>Are you sure you want to change the status ?</p>",
      //     btn_label: ['Done', "Not Done"],
      //     api_data: {
      //         base_url: process.env.REACT_APP_API_URL,
      //         endpoint: "lead-manage/disposiontaskstatusupdate",
      //         body: {
      //             "source": "users",
      //             "secret": "AZ|lepL`",
      //             "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
      //         }
      //     }
      // },
      {
        id: "Block1",
        type: "other",
        label: "Change Status",
        name: "history_toggle_off",
      },
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this Task ? This can't be undone. </p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/deletedispositiontask",
          body: {
            // source: "users",

            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],

    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },

    searchData: {
      heading: "Search All Task",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "search",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          // {
          //   id: 12,
          //   label: "Search by AE/REP/Advisor Name",
          //   name: "taskaddedfor",
          //   type: "autoComplete",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   base_url: process.env.REACT_APP_API_URL + "lead-manage/dispositiontasksaddedforautocomplete",
          //   reqBody: {

          //     "condition": {
          //       "limit": 10,
          //       "skip": 0
          //     },
          //     "sort": {
          //       "type": "desc",
          //       "field": "name"
          //     },
          //     "searchcondition": {}

          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "addedfor_name", param: "$regex", options: "i" },
          //   // payloadFormat:{key:"_id"}

          // },
          {
            id: 19,
            label: "Search by Assignee",
            name: "assignee_name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/autocompleteforspecialentities",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "desc",
                field: "name",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "assignee" },
          },
          {
            id: 2,
            label: "Search by Task",
            name: "task",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/autocompletefordispositiontask",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "desc",
                field: "name",
              },
              searchcondition: {
                addedfor: rowData?._id,
              },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "tasktitle" },
          },
          // {
          //     id: 10,
          //     label: "Search by Disposition",
          //     name: "notessearch",
          //     type: "autoComplete",
          //     className: "list_search_item countDiv__column--col6 ",
          //     base_url:
          //       process.env.REACT_APP_API_URL +
          //       "lead-manage/autocompletedispositionscurrentdisposition",
          //     reqBody: {
          //       condition: {
          //         limit: 5,
          //         skip: 0,
          //       },
          //       sort: {
          //         type: "asc",
          //         field: "email",
          //       },
          //       searchcondition: { "addedfor": rowData?._id },
          //     },
          //     sx: { m: 1, minWidth: 300 },
          //     payloadFormat: { key: "currentdisposition" },
          //   },
          // {
          //   id: 23,
          //   label: "Search by Disposition",
          //   name: "disposition_show",
          //   // className: 'Status',
          //   type: "select",
          //   multiple: false,
          //   values: [
          //     { val: "New Lead", name: "New Lead" },
          //     { val: "Accepted", name: "Accept" },
          //     { val: "Declined", name: "Decline" },
          //     { val: "Advisor/AE Contacted", name: "Advisor/AE Contacted" },
          //     // { val: "Strategy Call", name: "Strategy Call" },
          //     {
          //       val: "Advisor/AE Meeting Scheduled",
          //       name: "Advisor/AE Meeting Scheduled",
          //     },
          //     {
          //       val: "Advisor/AE Meeting Completed",
          //       name: "Advisor/AE Meeting Completed",
          //     },

          //     { val: "Discovery Call Scheduled", name: "Discovery Call Scheduled" },
          //     {
          //       val: "Discovery Call Completed",
          //       name: "Discovery Call Completed",
          //     },
          //     {
          //       val: "Discovery Call Cancelled",
          //       name: "Discovery Call Cancelled",
          //     },
          //     { val: "Contract Sent", name: "Contract Sent" },
          //     {
          //       val: "Contract Review Scheduled",
          //       name: "Contract Review Scheduled",
          //     },
          //     {
          //       val: "Contract Review Completed",
          //       name: "Contract Review Completed",
          //     },

          //     { val: "Contract Signed", name: "Decline" },
          //     // { val: "Cold Lead", name: "Cold Lead" },
          //     { val: "Lost", name: "Lost" },
          //     // { val: "N/A", name: "N/A" },
          //   ],
          //   sx: { m: 1, minWidth: 120 },
          //   className: "list_search_item countDiv__column--col6 ",
          //   payloadFormat: {
          //     key: "currentdisposition",
          //     // param: "$regex",
          //     // options: "im",
          //   },
          // },

          {
            id: 3,
            label: "Search By Contact",
            name: "contact_name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/disposionsrepfetch",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "desc",
                field: "name",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "contact_id" },
          },

          // {
          //   id: 31,
          //   label: "Search By Prospect",
          //   name: "prospect_name",
          //   type: "autoComplete",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   base_url: process.env.REACT_APP_API_URL + "lead-manage/disposionsrepfetch",
          //   reqBody: {

          //     "condition": {
          //       "limit": 10,
          //       "skip": 0
          //     },
          //     "sort": {
          //       "type": "desc",
          //       "field": "name"
          //     },
          //     "searchcondition": {

          //     }
          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "prospect_id" },

          // },
          {
            id: 21,
            label: "Search by Task Status",
            name: "status_show",
            type: "select",
            multiple: false,
            values: [
              { val: 1, name: "Pending" },
              { val: 2, name: "Completed" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "list_search_item countDiv__column--col6 ",
            payloadFormat: { key: "markasdone" },
          },
          {
            id: 8,
            //   heading: "Search By Creation Time",
            label: "Search by Task Start Date",
            name: "due_date_time",
            className: "datePicker list_search_item countDiv__column--col6",
            type: "datePicker",
            // fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "tasktargetdate", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Task End Date",
            name: "end_date_by_update",
            className: "list_search_item countDiv__column--col6 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "tasktargetdate", param: "$lte" },
          },

          // {
          //   id: 8,
          //   //   heading: "Search By Creation Time",
          //   label: "Search by Updated on Start Date",
          //   name: "start_date_by_update",
          //   className: 'datePicker list_search_item countDiv__column--col6',
          //   type: 'datePicker',
          //   sx: { m: 1, width: 300 },
          //   payloadFormat: { key: "createdon_datetime", param: "$gte" }
          // },
          // {
          //   id: 9,
          //   label: "Search by Updated on End Date",
          //   name: "end_date_by_update",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   type: 'datePicker',
          //   fullDay: true,
          //   sx: { m: 1, width: 300 },
          //   payloadFormat: { key: "createdon_datetime", param: "$lte" }
          // },
        ],
      },
    },
  });

  // ********************for Pharmacy Bluecoast and aspire useEffect**********************

  useEffect(() => {
    if (viewBtn) {
      // console.log("bluecoastnotedata==========>>>>", );

      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: previewData._id,
          load: true,
        })
      );

      // dispatch(blueCoastNotes(bluecoastnotedata));

      setPreviewModal(true);
      setPreviewModalData(previewData);

      // console.log("previewData====>>>", previewData);
      setHandleModalPreview(true);

      // console.log("");

      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "bluecoastdata",
              rowId: previewData._id,
              load: false,
            })
          ),
        1000
      );
    }
  }, [viewBtn]);

  useEffect(() => {
    if (notesClick === true) {
      setSingalRowData(previewData);
      setNotesModel(true);

      dispatch(
        setEnableRowLoader({
          tableId: "lead_data",
          rowId: previewData._id,
          load: true,
        })
      );

      setTimeout(
        () =>
          dispatch(
            setEnableRowLoader({
              tableId: "lead_data",
              rowId: previewData._id,
              load: false,
            })
          ),
        500
      );
    }
  }, [notesClick]);

  useEffect(() => {
    // console.log("otherspicialusers", otherspicialusers);

    if (otherspicialusers && otherspicialusers.length > 0 && !alreadyaddfield) {
      setalreadyaddfield(true);
    }
  }, [otherspicialusers]);

  // *********** table for Notes**********
  var modifyTableHeadersfornotes = [
    { val: "notes", name: "Notes" },
    { val: "addedby_name", name: "Added By" },
    {
      val: "createdon_datetime",
      name: "Added On",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ];

  let tableDataForBlueCoastNotes = useRef({
    tableId: "bluecoastnotes",
    tableTitle: "Note About Prospect's",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/bluecoast_under_note_list",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/bluecoast_under_note_list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        addedfor: addedForId && addedForId,
      },
      project: {},
      token: token,
      email: "",
      count: false,
    },

    rowsPerPageOptions: [30],
    sortFields: ["createdon_datetime", "notes", "addedby_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this Note ? This can't be undone. </p>",
        modalHeader: "<p> Alert!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/deletebluecoastlead",
          body: {
            // source: "users",

            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search Note About Prospect's",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchNotesForm",
        formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Keyword",
            name: "notes",
            // type: "autoComplete",
            type: "text",

            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/bluecoast_under_note_list",
            // "lead-manage/autocompleteforbclnotes",
            reqBody: {
              condition: {
                limit: 30,
                skip: 0,
              },
              sort: {
                field: "createdon_datetime",
                type: "asc",
              },
              searchcondition: {
                addedfor: addedForId,
                notes: {},
              },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "notes", param: "$regex" },
          },
          {
            id: 1,
            label: "Search by Added By",
            name: "addedby",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/bclautocompleteforaddedby",
            reqBody: {
              condition: {
                limit: 30,
                skip: 0,
              },
              sort: {
                field: "createdon_datetime",
                type: "desc",
              },
              searchcondition: {
                label: {
                  $regex: "",
                  $options: "i",
                },
              },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedby" },
          },
          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "createdon_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col4 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "createdtedon_datetime",
            className: "list_search_item countDiv__column--col4 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  // =======================useEffect for four new action icon for special entity====================
  useEffect(() => {
    if (
      userInfo &&
      Object.keys(userInfo).length > 0 &&
      userInfo.main_role == "special_entities" &&
      !custombtnadd
    ) {
   
      setcustombtnadd(true);
    }
  }, [JSON.stringify(userInfo)]);

  // =========================function for proceed click event in action modal==========
  const proceed = async () => {
    setactionhitloader(true);
    // console.log("specialEntityInfo", specialEntityInfo, userInfo, buttonClickedfor, buttonClickedforcal)
    let dataSet = {
      addedby: userInfo._id,
      addedfor: specialEntityInfo._id,
      action: buttonClickedfor && buttonClickedfor,
      discovary_action: buttonClickedforcal && buttonClickedforcal,
      action_added_time_human: timeConverter({
        time: Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      }),
    };
    // console.log("dataSet+++", dataSet);
    await dispatch(BlueCoastSpecialModal(dataSet));
    setspecialEntityInfo(null);
    setbuttonClickedfor(null);
    setbuttonClickedforcal(null);
    setactionhitloader(false);
    setStatesForSpecialModal("");
    setspecialEntityModal(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(
      setSnackbar({
        open: true,
        message: statesForSpecialModal,
        status: "success",
      })
    );
  };
  const chnagecheckbox = (val, name) => {
    // console.log("val+++++++", val, name);

    let arry = [...checkboxvalues];
    if (val) {
      arry.push(name);
      setcheckboxvalues(arry);
    } else {
      const index = arry.indexOf(name);
      if (index > -1) {
        // only splice array when item is found
        arry.splice(index, 1); // 2nd parameter means remove one item only
        setcheckboxvalues(arry);
      }
    }

  };

  /**************************************Add Notes For rep****************** */
  const addnoteforrep = async () => {
    if (textareayvalueforrep.current) {
      setscratchaddnoteflag(true);
      // console.log("textareayvalue", textareayvalue, checkboxvalues);
      let reqbody = {
        notes_by: addedById,
        notes_for: temprowDtafornote.rep_id,
        notes: textareayvalueforrep.current,
        token: token,
      };
      // console.log("reqbody", reqbody);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqbody),
      };
      // console.log("process.env==>", process.env);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "lead-manage/add_note_bluecoast_rep",
        requestOptions
      );
      const respdata = await response.json();
      dispatch(
        setSnackbar({
          open: true,
          message: "Added Successfully",
          status: "success",
        })
      );
      setscratchaddnoteflag(false);
      // setnotesforrepmodal(false)

      textareayvalueforrep.current = undefined;
      textareayvalueforrep.current = null;
      inputRef.current.value = null;

      // console.log("textareayvalueforrep123",textareayvalueforrep.current);
      // settextareayvalueforrep(null)
      await dispatch(blueCoastNotesrep(temprowDtafornote.rep_id));
      // tableDataForRepNotes
      dispatch(setReloadTableFlag({ tableId: "repnotes_list" }));
      // setcheckboxvalues([])
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: "Please Add a Note",
          status: "error",
        })
      );
    }
  };

  /******************************************************************************************************** */
  const addnote = async () => {
    if (textareayvalue.current) {
      setscratchaddnoteflag(true);
      let reqbody = {
        addedby: addedById,
        addedfor: addedForId,
        notes: textareayvalue.current,
        send_notification: checkboxvalues,
        token: token,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqbody),
      };
      // console.log("process.env==>", process.env);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "lead-manage/add_note_bluecoast_under",
        requestOptions
      );
      const respdata = await response.json();
      dispatch(
        setSnackbar({
          open: true,
          message: "Added Successfully",
          status: "success",
        })
      );
      setscratchaddnoteflag(false);
      textareayvalue.current = null;
      inputTextProspect.current.value = null;

      await dispatch(
        blueCoastNotes(tableDataForBlueCoastNotes.current.reqBody)
      );
      // dispatch(blueCoastNotesViewTable(tableDataForUsers.current.reqBody))
      dispatch(setReloadTableFlag({ tableId: "bluecoastnotes" }));
      setcheckboxvalues([]);
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: "Please Add a Note",
          status: "error",
        })
      );
    }
  };

  // *********** table for ALL Notes***************************
  var modifyTableHeadersforAllnotes = [
    { val: "notes", name: "Notes" },
    { val: "addedby_name", name: "Added By" },
    { val: "addedfor_name", name: "AE/REP/Advisor Name" },
    { val: "prospect_name", name: "Prospect's Name" },

    {
      val: "createdon_datetime",
      name: "Creation Time",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ];

  let tableDataForBlueCoastAllNotes = useRef({
    tableId: "bluecoastallnotes",
    tableTitle: "All Notes List for Prospect's",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/bluecoast_under_note_list",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/bluecoast_under_note_list",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        outsideData: { $exists: false },
        bluecoastPh: { $exists: false },
        bluecoastphblue: { $exists: false },
        is_deleted: { $exists: false },
        accessfree: { $exists: false },
      },
      project: {},
      token: token,
      email: "",
      count: false,
    },

    rowsPerPageOptions: [30],
    sortFields: [
      "createdon_datetime",
      "notes",
      "addedby_name",
      "addedfor_name",
      "prospect_name",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [

    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search All Notes",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchNotesForm",
        formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 10,
            label: "Search by Notes",
            name: "notessearch",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/notesForAutocompletealllist",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "notes" },
          },

          {
            id: 1,
            label: "Search by Added By",
            name: "addedby",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/autocompletebyadvisorname",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "addedfor_name",
              },
              searchcondition: {},
              type: "fullname",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedby" },
          },
          {
            id: 11,
            label: "Search by AE/REP/Advisor Name",
            name: "addedfor",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/autocompletebyprospectname",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "addedfor_name",
              },
              searchcondition: {},
              type: "fullname",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedfor" },
          },

          {
            id: 7,
            label: "Search by Prospect's Name",
            name: "prospect",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "bclusers/autocompletebclunderlistae",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "addedfor_name",
              },
              searchcondition: {},
              type: "fullname",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedfor" },
          },

          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "createdon_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col4 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "createdtedon_datetime",
            className: "list_search_item countDiv__column--col4 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          }
        ],
      },
    },
  });

  // **************************AddForm for BlueCoastList*********************
  const addFormBlueCstLead = useRef({
    id: "addBlueCstLead",
    formtype: "add",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: "Submit",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      status: 0,
      // "roles": { is_rep: 1 },
    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "first_name".trim(),
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue:
          editBlcstDataLead &&
            Object.keys(editBlcstDataLead).length > 0 &&
            editBlcstDataLead.first_name !== undefined
            ? editBlcstDataLead.first_name
            : undefined,
      },
      {
        id: 15,
        heading: "Your Last Name",
        label: "Last Name",
        name: "last_name".trim(),
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 13,
        heading: "Your Email",
        label: "Email",
        name: "email".trim(),
        className: "form_item inputBlock inputBlock4line",
        disabled: false,
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 14,
        heading: "Your Phone Number",
        label: "Phone Number Format: (123) 456-7890",
        name: "phone",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "phone",
        // rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
        rules: { required: true, pattern: /^[^@#%&$]*$/ },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
      },

      {
        id: 16,
        heading: "Prospect's Medical Facility Business Name",
        label: "Prospect's Medical Facility Business Name",
        name: "prospectbusinessname",
        className: "form_item inputBlock inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 21,
        heading: "Prospect's Title",
        label: "Prospect's Title",
        name: "prospecttitle",
        className: "form_item inputBlock inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 17,
        heading: "Prospect's First Name",
        label: "Prospect's First Name",
        name: "prospectfirstname".trim(),
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 18,
        heading: "Prospect's Last Name",
        label: "Prospect's Last Name",
        name: "prospectlastname".trim(),
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 19,
        heading: "Prospect's Email",
        label: "Prospect's Email",
        name: "prospectemail".trim(),
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 20,
        heading: "Prospect's Phone Number ",
        label: "Prospect's Phone Number Format: (123) 456-7890",
        name: "secondary_phone",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "phone",
        rules: { required: true, pattern: /^[^@#%&$]*$/ },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
      },

      {
        id: 42,
        heading: "Town / City",
        label: "Town / City",
        name: "city",
        className: "formfild inputBlock inputBlock2line city",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 41,
        heading: "State",
        label: "State",
        name: "state",
        className: "formfild inputBlock inputBlock2line state",
        type: "select",
        // other: true,
        values: StateArray,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: "",
      },

      {
        id: 10,
        heading: "Type of medical facility",
        name: "medicalfacilitytype",
        className: "form_radio inputBlock1line",
        values: [
          { key: "Medical Practice", val: "Medical Practice" },
          { key: "Surgical Center", val: "Surgical Center" },
          { key: "Urgent Care", val: "Urgent Care" },
          { key: "Hospital", val: "Hospital" },
          { key: "Medical Practice Group", val: "Medical Practice Group" },
          { key: "Other", val: "Other" },
        ],
        // values: [],
        type: "radio",
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        // defaultValue: "No"
      },
      {
        id: 25,
        heading: "What is your relationship with the prospect?  ",
        name: "natureofrelationship",
        className: "form_radio inputBlock1line",
        values: [
          { key: "Client", val: "Client" },
          { key: "Client/Friend", val: "Client/Friend" },
          { key: "Friend", val: "Friend" },
        ],
        type: "radio",
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        // defaultValue: "No"
      },

      {
        id: 51,
        heading: "Please describe your relationship",
        placeholder: "Please describe your relationship",
        label: "Please describe your relationship",
        name: "relationshipdescription",
        className: "formfild inputBlock1line grossreceipts",
        type: "text",
      },

      {
        id: 3,
        heading: "Annual Revenue Must be a minimum of $50M",
        placeholder: "Annual Revenue Must be a minimum of $50M",
        label: "Annual Revenue Must be a minimum of $50M",
        name: "approxgrossreciept",
        className: "formfild inputBlock1line grossreceipts",
        type: "text",
        // inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 31,
        heading: "Website",
        label: "Website",
        name: "company_website",
        className: "formfild formfild1 webSite",
        type: "text",
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 30,
        // heading: "Any other information we should know?",
        placeholder: "Any other information we should know?",
        label: "Any other information we should know?",
        name: "other_info",
        className: "formfild textareafild inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 22,
        // heading: "Must add extensive notes for this to be reviewed for qualification",
        placeholder:
          "Must add extensive notes for this to be reviewed for qualification",
        label:
          "Must add extensive notes for this to be reviewed for qualification",
        name: "extensive_notes",
        className: "formfild textareafild inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
    ],
  });

  /*******************************Add Form For Blue Coast Pharmacy*********** */

  const addFormBlueCstPharmacyLead = useRef({
    id: "addBlueCstPharmacyLead",
    formtype: "add",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: "Submit",
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      status: 0,
      // "roles": { is_rep: 1 },
    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "first_name",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        // defaultValue: (editBlcstDataLead && Object.keys(editBlcstDataLead).length > 0 && editBlcstDataLead.first_name !== undefined) ? editBlcstDataLead.first_name : undefined,
      },
      {
        id: 1,
        heading: "Your Last Name",
        label: "Last Name",
        name: "last_name",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 2,
        heading: "Your Email",
        label: "Email",
        name: "email",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "email",
        disabled: false,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 3,
        heading: "Your Phone Number",
        label: "Phone Number Format: (123) 456-7890",
        name: "phone",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "phone",
        rules: { required: true, maxLength: 14, pattern: /^[^@#%&$]*$/ },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          // required: "Phone No is Required",
          pattern: "Phone no does not contain any special characters (@#%&$)",
        },
      },

      {
        id: 4,
        heading: "Potential Pharmacy Partner Name",
        label: "Potential Pharmacy Partner Name",
        name: "prospectbusinessname",
        className: "form_item inputBlock inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 5,
        heading: "Prospect's Title",
        label: "Prospect's Title",
        name: "prospecttitle",
        className: "form_item inputBlock inputBlock1line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 6,
        heading: "Prospect's First Name",
        label: "Prospect's First Name",
        name: "prospectfirstname",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 7,
        heading: "Prospect's Last Name",
        label: "Prospect's Last Name",
        name: "prospectlastname",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 8,
        heading: "Prospect's Email",
        label: "Prospect's Email",
        name: "prospectemail",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 9,
        heading: "Prospect's Phone Number ",
        label: "Prospect's Phone Number Format: (123) 456-7890",
        name: "secondary_phone",
        className: "form_item inputBlock inputBlock4line",
        type: "text",
        inputType: "phone",
        rules: { required: true, pattern: /^[^@#%&$]*$/ },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Phone no does not contain any special characters (@#%&$)",
          maxLength: "Phone number can not be greater than 10 digits",
        },
      },

      {
        id: 42,
        heading: "Town / City",
        label: "Town / City",
        name: "city",
        className: "formfild inputBlock inputBlock2line city",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 41,
        heading: "State",
        label: "State",
        name: "state",
        className: "formfild inputBlock inputBlock2line state",
        type: "select",
        // other: true,
        values: StateArray,
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: "",
      },

      {
        id: 10,
        heading: "Type of business or group",
        name: "medicalfacilitytype",
        className: "form_radio inputBlock1line",
        values: [
          { key: "Affinity Group", val: "Affinity Group" },
          // { key: "Chamber of Commerce", val: "Chamber of Commerce" },
          { key: "MLM", val: "MLM" },
          { key: "Affiliate Organization", val: "Affiliate Organization" },
          { key: "Employer", val: "Employer" },
          { key: "Other", val: "Other" },
        ],
        type: "radio",
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        // defaultValue: "No"
      },

      {
        id: 11,
        heading: "What is your relationship with the prospect?  ",
        name: "natureofrelationship",
        className: "form_radio inputBlock1line",
        // values: [{ key: 'Client', val: 'Client' }, { key: 'Client/Friend', val: 'Client/Friend' }, { key: 'Friend', val: 'Friend' }, { key: 'Cold', val: 'Cold' }],
        values: [
          { key: "Client", val: "Client" },
          { key: "Client/Friend", val: "Client/Friend" },
          { key: "Friend", val: "Friend" },
        ],

        type: "radio",
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
        // defaultValue: "No"
      },
      {
        id: 51,
        heading: "Please describe your relationship",
        placeholder: "Please describe your relationship",
        label: "Please describe your relationship",
        name: "relationshipdescription",
        className: "formfild inputBlock1line grossreceipts",
        type: "text",
        // inputType: "email",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid",
        // },
      },
      {
        id: 17,
        heading:
          "Subscription Membership Opportunity Lead (Group must be over 250 Members or Employees)",
        // label: "Submitted Opportunities must have a reach of 5000 or more",
        name: "subscription_membership",
        className: "form_radio inputBlock1line",
        type: "singleCheckbox",
        values: {
          key: "Yes",
          val: "Yes, this group has a reach of 250+ Members or Employees",
        },
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 40,
        heading: "Number of Members or Employees ",
        label: "How many people are in your organization? ",
        name: "number_of_people_type",
        className: "formfild formfild4 employeeNum",
        type: "text",
        // inputType: "number",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 31,
        heading: "Website",
        label: "Website",
        name: "company_website",
        className: "formfild formfild1 webSite",
        type: "text",
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 13,
        // heading: "Any other information we should know?",
        placeholder: "Any other information we should know?",
        label: "Any other information we should know?",
        name: "other_info",
        className: "formfild textareafild inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 14,
        // heading: "Must add extensive notes for this to be reviewed for qualification",
        placeholder:
          "Must add extensive notes for this to be reviewed for qualification",
        label:
          "Must add extensive notes for this to be reviewed for qualification",
        name: "extensive_notes",
        className: "formfild textareafild inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
        },
      },
    ],
  });

  //******************Table for rep notes in modal********* /

  var modifyTableHeadersforRepnotes = [
    { val: "notes", name: "Notes" },
    { val: "addedby_name", name: "Added By" },
    // { val: "addedfor_name", name: "AE/REP Name" },
    {
      val: "createdon_datetime",
      name: "Creation Time",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
  ];

  let tableDataForRepNotes = useRef({
    tableId: "repnotes_list",
    tableTitle: "Note About AE/REP",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/bluecoast_under_note_list_for_rep",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/bluecoast_under_note_list_for_rep",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        notes_for: temprowDtafornote?.rep_id,
      },
      project: {},
      token: token,
      email: "",
      count: false,
    },

    rowsPerPageOptions: [30],
    sortFields: [
      "createdon_datetime",
      "notes",
      "addedby_name",
      "addedfor_name",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this Note ? This can't be undone. </p>",
        modalHeader: "<p> Alert!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/deletebluecoastundernotelistforrep",
          body: {
            // source: "users",

            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search AE/REP Notes",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchNotesForm",
        formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 10,
            label: "Search by Notes",
            name: "notessearch",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "bclusers/autocompletenoteforrep",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: { notes_for: temprowDtafornote?.rep_id },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "notes" },
          },

          {
            id: 1,
            label: "Search by Added By",
            name: "addedby",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL + "bclusers/autocompleteforaddedby",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: { notes_for: temprowDtafornote?.rep_id },
              type: "fullname",
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "notes_by" },
          },

          // {
          //   id: 5,
          //   label: "Search By AE/REP",
          //   name: "addedfor",
          //   type: "autoComplete",
          //   className: 'list_search_item countDiv__column--col6 ',
          //   base_url: process.env.REACT_APP_API_URL + "lead-manage/autocompleteforaerepname",
          //   reqBody: {
          //     "condition": {
          //       "limit": 5,
          //       "skip": 0
          //     },
          //     "sort": {
          //       "type": "asc",
          //       "field": "email"
          //     },
          //     "searchcondition": { notes_for: temprowDtafornote?.rep_id },
          //     "type": "fullname",
          //   },
          //   sx: { m: 1, minWidth: 300 },
          //   payloadFormat: { key: "notes_by" },

          // },

          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "createdon_Start_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
            className: "list_search_item countDiv__column--col4 ",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "createdtedon_datetime",
            className: "list_search_item countDiv__column--col4 ",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'MM/DD/YYYY',
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },
        ],
      },
    },
  });

  /********************************Handle close for rep notes modal******* */

  const handleCloseRepNotesModal = () => {
    setnotesforrepmodal(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(clearBlueCoastRepNotesList());
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }))
  };

  // ************************UseEffect for Transfer ownership Modal************************
  const TrnsfrOwnrshpClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.trasfer_ownership
      ? state.tableSlice.customButtonClicked.bluecoastdata.trasfer_ownership
      : false
  );
  const ownerShipRowData = useSelector((state) =>
    state.tableSlice?.tempRowData?.bluecoastdata
      ? state.tableSlice.tempRowData.bluecoastdata
      : null
  );
  const [changeOwnerID, setChangeOwnerID] = useState(null);
  const [ownershipRowData, setOwnershipRowData] = useState(null);
  // const [addNotesTransferOwner, setAddNotesTransferOwner] = useState('');
  // const handleAddNote = events => {

  //   setAddNotesTransferOwner(events.target.value);
  //   console.log('Notesssssssssssssssssssssssssss', events.target.value);
  // };

  const handleOwnershipChange = async (event) => {
    console.warn(
      "event.target.value>>>>>>>>>1234",
      event.target.value,
      // process.env.REACT_APP_ENVIRONMENT,
      // ownershipRowData,
      changeOwnerID
    );

    // if (changeOwnerID && event.target.value && event.target.value != changeOwnerID) {
    setChangeOwnerID(event.target.value);
    let betoId =
      process.env.REACT_APP_ENVIRONMENT == "dev"
        ? "642551962ec2465a2d30831a"
        : "6426b8597fec19a24e5186fa";

    // let travisId =
    //   process.env.REACT_APP_ENVIRONMENT == "dev"
    //     ? "642679d3179b46420e44ae36"
    //     : "6426b9d0fab6d78fa2e2f06b";
    let jeannetteId = process.env.REACT_APP_ENVIRONMENT == "prod" ? "659e1ee5ef5e0549a683aad8" : "";
    // console.log("betoId====123", betoId, travisId);
    if (event.target.value == betoId || event.target.value == jeannetteId) {
      // console.log("betoId>>>>>", betoId);
      if (
        (ownershipRowData.bluecoastPh == true ||
          ownershipRowData.bluecoastphblue == true) &&
        event.target.value == betoId
      ) {
        // console.log("ownershipRowData>>>>213", ownershipRowData);
        setSendEmailtoSpecialEntity(true);
      }
      // if (
      //   ((!ownershipRowData.bluecoastPh && !ownershipRowData.bluecoastphblue) ||
      //     ownershipRowData.outsideData == true) &&
      //   event.target.value == travisId
      // ) {
      //   // console.log("ownershipRowData>>>>213", ownershipRowData);
      //   setSendEmailtoTravisModal(true);
      // }
      if (((!ownershipRowData.bluecoastPh && !ownershipRowData.bluecoastphblue) ||
        ownershipRowData.outsideData == true) && event.target.value == jeannetteId) {
        setSendEmailtoTravisModal(true);
      }
    } else {
    }

    // }
  };

  useEffect(() => {
    if (TrnsfrOwnrshpClicked && ownerShipRowData) {

      console.log("ownerShipRowData=============>>>", ownerShipRowData);
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: rowData._id,
          load: true,
        })
      );

      setOwnershipRowData(ownerShipRowData);
      setChangeOwnerID(ownerShipRowData.owner);
      setTransferOwnershipModlaOpen(true);
      setTimeout(() => {
        dispatch(
          setEnableRowLoader({
            tableId: "bluecoastdata",
            rowId: rowData._id,
            load: false,
          })
        );
      }, 2000);
    }
  }, [TrnsfrOwnrshpClicked, JSON.stringify(ownerShipRowData)]);

  const CloseOwnershipModal = () => {
    tranceFormOwershipText.current = null;
    tranceFormOwershipTextValue.current.value = null;
    setSendContractTo(null);

    setTransferOwnershipModlaOpen(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
  };

  const changeOwnershp = async (val) => {
    // console.log(val, "vale1234", ownershipRowData)

    let dataSetOwner = {
      _id: ownershipRowData._id,
      owner: changeOwnerID,
      notes: tranceFormOwershipText.current,
      added_by: userInfo?._id,
      token: token,
    };
    if (sendContractTo && sendContractTo == "Beto") {
      dataSetOwner["sendcontract"] = 1;
      console.log("dataSetOwner====>>>>1", dataSetOwner, sendContractTo);
    }
    // if (sendContractTo && sendContractTo == "Travis") {
    //   dataSetOwner["sendcontract"] = 2;
    //   console.log("dataSetOwner====>>>>2", dataSetOwner, sendContractTo);
    // }
    if (sendContractTo && sendContractTo == "Jeannette") {
      dataSetOwner["sendcontract"] = 2;
      console.log("dataSetOwner====>>>>2", dataSetOwner, sendContractTo);
    }
    setOwnerShipLoader(true);

    await dispatch(getownerShipTransferData(dataSetOwner));

    setOwnerShipLoader(false);
    tranceFormOwershipText.current = null;
    tranceFormOwershipTextValue.current.value = null;
    setSendContractTo(null);

    dispatch(
      setSnackbar({
        open: true,
        message: "Ownership Transfered Successfully!",
        status: "success",
      })
    );

    let dataSet = {
      addedby: userInfo._id,
      addedfor: ownershipRowData._id,
      disposition_name: "Advisor/AE Contacted",
      action_added_time_human: timeConverter({
        time: Math.round(new Date().getTime()),
        format: "MM/DD/YYYY",
      }),
    };
    if (sendContractTo && sendContractTo == "Beto") {
      dataSet["sendcontract"] = 1;
      dispatch(getDisposition(dataSet));
    }
    // if (sendContractTo && sendContractTo == "Travis") {
    //   dataSet["sendcontract"] = 2;
    //   dispatch(getDisposition(dataSet));
    // }
    if (sendContractTo && sendContractTo == "Jeannette") {
      dataSet["sendcontract"] = 2;
      dispatch(getDisposition(dataSet));
    }
    setTimeout(() => {
      CloseOwnershipModal();
    }, 3000);
    // await dispatch(getDisposition(dataSet))
  };

  // const CloseOwnershipModal = () => {
  //   tranceFormOwershipText.current = null
  //   tranceFormOwershipTextValue.current.value = null
  //   setSendContractTo(null)

  //   setTransferOwnershipModlaOpen(false);
  //   dispatch(setReloadTableFlag({ tableId: 'bluecoastdata' }))
  // }

  const ChooseSendEmail = (val) => {
    console.log("val====>>>", val);
    setSendContractTo(val);
    if (val == "Beto") {
      handleCloseEmailtoSpecialEntity();
    } else {
      handleCloseEmailtoTravis();
    }
  };

  /***********************Date Time Picker Form in Disposition select Value Modal */
  const dateTimeForm = {
    id: "datetimeform",
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "Formsubmit_button",
    submitBtnName: "Submit",
    // // submitBtnStartIcon: 'image_search_icon',
    // // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {},
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 32,
        heading: "Set Meeting Date",
        // label: "Set Meeting Date Time",
        name: "dateTimePicker",
        className: "dateTimePicker",
        type: "datePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          invalid: "Invalid Date Time Format",
          custom: "Value is Invalid",
        },
        sx: { m: 1, width: 300 },
        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
        defaultValue: new Date().getTime(), // Always use new Date().getTime() to convert into miliseconds
        minDate: new Date().getTime(),
      },
      {
        id: 37,
        heading: "Set Meeting Time",
        // label: "Set Meeting Date Time",
        name: "time",
        className: "dateTimePicker",
        type: "timePicker",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          invalid: "Invalid Date Time Format",
          custom: "Value is Invalid",
        },
        sx: { m: 1, width: 300 },
        // format: 'MM/DD/YYYY', //////////////////////////// MM should be capital //////////////////
        defaultValue: new Date().getTime(), // Always use new Date().getTime() to convert into miliseconds
        // minDate: new Date().getTime( moment()),
        // minDate: moment(new Date().getTime()),
      },
    ],
  };

  /**************************************Table List for Disposition Call************** */

  var modifyTableHeadersforDispCall = [
    { val: "disposition_name", name: "Disposition Name" },
    { val: "addedby_name", name: "Added By" },
    // { val: "createdon_datetime", name: "Creation Time", type: "datetime", format: "MM/DD/YYYY" },
    {
      val: "set_up_date",
      name: "Meeting Date",
      type: "datetime",
      format: "MM/DD/YYYY",
    },
    { val: "set_up_time", name: "Meeting Time" },
  ];

  let tableDataForDispCall = useRef({
    tableId: "disp_call_list",
    tableTitle: "All Meeting List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/disposition_call_datalist",
      // deleteSingleUserEndpoint: "training-manage/delete-training",
      tableCountEndpoint: "lead-manage/disposition_call_datalist",
    },

    reqBody: {
      condition: {
        limit: 30,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {
        addedfor: saveRowdata?._id,
      },
      project: {},
      token: token,
      email: "",
      count: false,
    },

    rowsPerPageOptions: [30],
    sortFields: [
      "addedby_name",
      "set_up_time",
      "disposition_name",
      "set_up_date",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [],
    buttons: [],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },
    searchData: {
      heading: "Search All Meeting",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: "searchNotesForm",
        formWrapperClass: "searchBarHead formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "DrawerBox", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 10,
            label: "Search by Disposition",
            name: "notessearch",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/allmeetingdispositionserach",
            reqBody: {
              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: { addedfor: saveRowdata?._id },
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "_id" },
          },
          {
            id: 25,
            label: "Search by Added By",
            name: "user",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            // multiple: true,
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/spcialautocompletename",
            reqBody: {
              // "condition": {
              //   "limit": 5,
              //   "skip": 0
              // },
              // "sort": {
              //   "type": "asc",
              //   "field": "email"
              // },
              // "searchcondition": { "addedfor": saveRowdata?._id },
              // // "type": "prospectemail",
              // // "source": "bluecoast_leads"

              condition: {
                limit: 5,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "email",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "addedby" },
          },
        ],
      },
    },
  });

  /*************************Disposition list Button CLicked****** */

  const dispositionCallButtonClicked = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.dist_call_list
      ? state.tableSlice.customButtonClicked.bluecoastdata.dist_call_list
      : false
  );

  const dispositionCallData = useSelector((state) =>
    state.BlueCoastSlice?.dispositioncalllistdata &&
      state.BlueCoastSlice?.dispositioncalllistdata.length > 0
      ? state.BlueCoastSlice.dispositioncalllistdata
      : []
  );

  const loadinginDispCall = useSelector((state) =>
    state.BlueCoastSlice?.dispcallloading
      ? state.BlueCoastSlice.dispcallloading
      : false
  );

  useEffect(() => {
    if (
      dispositionCallButtonClicked &&
      saveRowdata &&
      Object.keys(saveRowdata).length > 0
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: rowData._id,
          load: true,
        })
      );

      // setDispositionCallListModalOpen(true)

      dispositionCall(saveRowdata);
    }
  }, [dispositionCallButtonClicked, JSON.stringify(saveRowdata)]);

  const dispositionCall = async (val) => {
    setsaveRowdata(val);

    tableDataForDispCall.current.reqBody.searchcondition.addedfor = val._id;
    tableDataForDispCall.current.searchData.formData.fields[0].reqBody.searchcondition.addedfor =
      val._id;
    // tableDataForDispCall.current.searchData.formData.fields[1].reqBody.searchcondition.addedfor = val._id

    await dispatch(dispositionCalllist(tableDataForDispCall.current.reqBody));
    setDispositionCallListModalOpen(true);

    dispatch(
      setEnableRowLoader({
        tableId: "bluecoastdata",
        rowId: rowData._id,
        load: false,
      })
    );
  };

  const handleCloseDispositionListModal = () => {
    setsaveRowdata(null);
    setDispositionCallListModalOpen(false);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
  };

  let lastloginTime = useSelector((state) => state.loginSlice.last_login_time);

  let rep_new_dashboard = useSelector((state) =>
    state.loginSlice.userInfo?.rep_with_new_dashboard
      ? state.loginSlice.userInfo.rep_with_new_dashboard
      : false
  );
  // const cookies = new Cookies();

  const gotoadmindashboard = () => {
    let cockiedata = cloneDeep(userInfo);
    // let userType = logedmeInUserdata.main_role;
    // let otherrole = logedmeInUserdata.other_role;
    cookies.remove("userinfo", { path: "/" });
    if (cockiedata.other_role) {
      let index = cockiedata.other_role.indexOf("is_developer");
      if (index !== -1) {
        cockiedata.other_role.splice(index, 1);
      }
      cockiedata.other_role.push("special_entities");
    }
    cockiedata = { ...cockiedata, main_role: "is_developer" };

    cookies.set("userinfo", cockiedata, { path: "/" });
    let userData = {
      userinfo: {
        ...cockiedata,
      },
      userType: cockiedata.main_role,
      token: token,
      lastLoginTime: lastloginTime,
    };
    // console.log("userInfotoken2", userData);

    // setTimeout(() => {
    store.dispatch(setLogedinUserInfo(userData));

    // }, 500);
    setTimeout(() => {
      window.location.replace("/dashboard");
    }, 1000);

    // cookies.remove("lastLoginTime", { path: "/" });
  };
  const gotorepdashboard = () => {
    let cockiedata = cloneDeep(userInfo);
    // let userType = logedmeInUserdata.main_role;
    // let otherrole = logedmeInUserdata.other_role;
    cookies.remove("userinfo", { path: "/" });
    if (cockiedata.other_role) {
      let index = cockiedata.other_role.indexOf("is_rep");
      if (index !== -1) {
        cockiedata.other_role.splice(index, 1);
      }
      cockiedata.other_role.push("special_entities");
    }
    cockiedata = { ...cockiedata, main_role: "is_rep" };

    cookies.set("userinfo", cockiedata, { path: "/" });
    let userData = {
      userinfo: {
        ...cockiedata,
      },
      userType: cockiedata.main_role,
      token: token,
      lastLoginTime: lastloginTime,
    };
    // console.log("userInfotoken2", userData);

    // setTimeout(() => {
    store.dispatch(setLogedinUserInfo(userData));

    // }, 500);
    setTimeout(() => {
      if (rep_new_dashboard) {
        window.location.replace("/new-ae-dashboard");
        // resolvefunction(navigate, '/new-ae-dashboard', getlandingpageForRep(reqLandingPagebody))
      } else {
        window.location.replace("/ae-dashboard");

        // resolvefunction(navigate, '/ae-dashboard', getDataForRepDashboardCard(reqcardBodyFordashboard))
      }
    }, 1000);

    // cookies.remove("lastLoginTime", { path: "/" });
  };

  /*************************annulBilling list Button CLicked****** */

  const annulBillingClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.annul_billing
      ? state.tableSlice.customButtonClicked.bluecoastdata.annul_billing
      : false
  );

  const annualBillingData = useSelector((state) =>
    state.BlueCoastSlice?.annualBillingData?.annual_billing
      ? state.BlueCoastSlice.annualBillingData.annual_billing
      : null
  );

  const blueCoastData = useSelector((state) =>
    state.BlueCoastSlice?.annualBillingData
      ? state.BlueCoastSlice.annualBillingData
      : null
  );

  const annualBillingDataLoadingstate = useSelector((state) =>
    state.BlueCoastSlice?.annualBillingDataLoadingstate
      ? state.BlueCoastSlice.annualBillingDataLoadingstate
      : null
  );
  const [annulBillingModaOpenl, setannulBillingModalOpen] = useState(false);
  // const [annulBillingValue, setannulBillingValue] = useState(null);
  // const [govtPay, setgovtPay] = useState(null);
  // const [privatePay, setprivatePay] = useState(null);

  let firstPayer = useRef(null);
  let annulBillingValue = useRef(null);

  let secondPayer = useRef(null);

  let thiredPayer = useRef(null);
  let govtPay = useRef();
  let privatePay = useRef(null);

  const [loaderannulBillingValue, setLoaderannulBillingValue] = useState(null);

  // console.log("annualBillingDataLoadingstate", annualBillingDataLoadingstate);

  useEffect(() => {
    if (
      annulBillingClick &&
      saveRowdata &&
      Object.keys(saveRowdata).length > 0
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata._id,
          load: true,
        })
      );
      dispatch(
        getbluecoastannualbilling({
          _id: saveRowdata._id,
          source: "bluecoast_leads",
          token: token,
        })
      );
    }
  }, [annulBillingClick, JSON.stringify(saveRowdata)]);

  useEffect(() => {
    if (annualBillingDataLoadingstate === 2 && saveRowdata) {
      // console.log("annualBillingDataLoadingstate", annualBillingDataLoadingstate);
      setannulBillingModalOpen(true);
      govtPay.current =
        blueCoastData?.govtPay !== null ? blueCoastData?.govtPay : undefined;

      firstPayer.current =
        blueCoastData?.firstPayer !== null
          ? blueCoastData?.firstPayer
          : undefined;
      secondPayer.current =
        blueCoastData?.secondPayer !== null
          ? blueCoastData?.secondPayer
          : undefined;
      thiredPayer.current =
        blueCoastData?.thiredPayer !== null
          ? blueCoastData?.thiredPayer
          : undefined;
      privatePay.current =
        blueCoastData?.privatePay !== null
          ? blueCoastData?.privatePay
          : undefined;
      annulBillingValue.current =
        annualBillingData !== null ? annualBillingData : undefined;

      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata._id,
          load: false,
        })
      );
    }
  }, [annualBillingDataLoadingstate]);

  const CloseAnnulBillingModaOpenl = () => {
    setannulBillingModalOpen(false);
    // setannulBillingValue(null)
    annulBillingValue.current = null;
    firstPayer.current = null;
    secondPayer.current = null;
    thiredPayer.current = null;
    govtPay.current = null;
    privatePay.current = null;

    setsaveRowdata(null);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(clearAnnualBillingData());
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
    // dispatch(clearPriorityData())
  };

  const handleChangeAnnualBilling = async (event) => {
    setLoaderannulBillingValue(true);

    await dispatch(
      blueCoastaddannualBilling({
        annual_billing: annulBillingValue.current,
        firstPayer: firstPayer.current,
        secondPayer: secondPayer.current,
        thiredPayer: thiredPayer.current,
        govtPay: parseFloat(govtPay.current),
        privatePay: parseFloat(privatePay.current),
        addedfor: saveRowdata._id,
        source: "bluecoast_leads",
      })
    );
    setLoaderannulBillingValue(false);
    CloseAnnulBillingModaOpenl();
    // setannulBillingModalOpen(false);
    // dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(
      setSnackbar({
        open: true,
        message: "Annual Billing Verified added  Successfully",
        status: "success",
      })
    );
  };

  /*************************Underpayment list Button CLicked****** */

  const underpaymentClick = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.bluecoastdata?.under_payment
      ? state.tableSlice.customButtonClicked.bluecoastdata.under_payment
      : false
  );

  const underPaymentData = useSelector((state) =>
    state.BlueCoastSlice?.underPaymentData?.under_payment
      ? state.BlueCoastSlice.underPaymentData.under_payment
      : null
  );

  const underPaymentLoadingstate = useSelector((state) =>
    state.BlueCoastSlice?.underPaymentLoadingstate
      ? state.BlueCoastSlice.underPaymentLoadingstate
      : null
  );

  const [underPaymentModaOpenl, setunderPaymentModaOpenl] = useState(false);
  const [underPaymentValue, setunderPaymentValue] = useState(null);
  const [loaderUnderForPayment, setLoaderUnderForPayment] = useState(null);
  // const [loaderannulBillingValue, setLoaderannulBillingValue] = useState(null);

  useEffect(() => {
    if (
      underpaymentClick &&
      saveRowdata &&
      Object.keys(saveRowdata).length > 0
    ) {
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: rowData._id,
          load: true,
        })
      );
      dispatch(
        getbluecoastunderpayment({
          _id: saveRowdata._id,
          source: "bluecoast_leads",
          token: token,
        })
      );

      // setDispositionCallListModalOpen(true)

      // dispositionCall(saveRowdata)
    }
  }, [underpaymentClick, JSON.stringify(saveRowdata)]);

  useEffect(() => {
    if (underPaymentLoadingstate === 2 && saveRowdata) {
      // console.log("underPaymentLoadingstate", underPaymentLoadingstate);
      setunderPaymentModaOpenl(true);
      dispatch(
        setEnableRowLoader({
          tableId: "bluecoastdata",
          rowId: saveRowdata._id,
          load: false,
        })
      );
    }
  }, [underPaymentLoadingstate]);

  const CloseUnderPaymentModaOpenl = () => {
    setunderPaymentModaOpenl(false);
    setunderPaymentValue(null);
    setsaveRowdata(null);
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    dispatch(clearUnderPaymentData());
    // dispatch(deleteTempSearchEndpoint({ tableId: "bluecoastdata" }));
  };

  const handleChangeUnderPayment = async (event) => {
    setLoaderUnderForPayment(true);

    await dispatch(
      blueCoastaddunderpayment({
        under_payment: parseFloat(underPaymentValue),
        addedfor: saveRowdata._id,
        source: "bluecoast_leads",
        token: token,
      })
    );
    setLoaderUnderForPayment(false);
    // setunderPaymentModaOpenl(false);
    CloseUnderPaymentModaOpenl();
    dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));

    dispatch(
      setSnackbar({
        open: true,
        message: "Under Payment opportunity  added  Successfully",
        status: "success",
      })
    );
  };

  const [btcyapicall, setbtcyapicall] = useState(false);

  useEffect(() => {
    if (
      userInfo?.email &&
      (userInfo?.email == "bbutters@aspirepartnersusa.com" ||
        userInfo?.email == "wayne@aspirepartnersusa.com" ||
        userInfo?.email == "sellisha@aspirepartnersusa.com" ||
        userInfo?.email == "butters@yopmail.com" ||
        userInfo?.email == "selisha.test@yopmail.com" ||
        userInfo?.email == "wayne.entity@yopmail.com") &&
      !btcyapicall
    ) {
      // console.log("Hitt btcy");
      if (!leadid) {

        mainListbtnClicked(2);
      }
      setbtcyapicall(true);
    } else {
      if (!leadid) {
        mainListbtnClicked(1);
      }
    }
  }, [JSON.stringify(userInfo)]);

  // let data123=textareayvalueforrep.current

  // console.log("this data is for bluecosete", leaddataforlist);

  // ---------------Delete Modal-----------------------

  const deleteFlage = useSelector((state) =>
    state.BlueCoastSlice?.deleteFlage ? state.BlueCoastSlice.deleteFlage : 0
  );
  // console.log("deleteFlage====>>>", deleteFlage);
  const statusTogalloading = useSelector((state) =>
    state.BlueCoastSlice?.statusTogalloading
      ? state.BlueCoastSlice.statusTogalloading
      : false
  );
  useEffect(() => {
    if (delete_lead === true) {
      setDeleteModal(true);
    }
  }, [delete_lead]);

  const CloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handelBlucoastLeadStatus = () => {
    dispatch(bluecoastLeadStatusChang({ id: saveRowdata._id }));
  };

  useEffect(() => {
    if (deleteFlage === 3 || deleteFlage === 2) {
      setDeleteModal(false);
      dispatch(setReloadTableFlag({ tableId: "bluecoastdata" }));
    }
  }, [deleteFlage]);



  const numberWithCommas = (x) => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };



  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);

  const addTaskModal = () => {
    setAddTaskModalOpen(true);
  };



  /********Task Status Change modal******** */

  const [taskStatusChangeModalOpen, setTaskStatusChangeModalOpen] =
    useState(false);
  const [taskStatusValue, setTaskStatusValue] = useState(null);

  const taskStatus = useSelector((state) =>
    state.tableSlice?.customButtonClicked?.alltaskdata?.Block1
      ? state.tableSlice.customButtonClicked.alltaskdata.Block1
      : false
  );

  const taskStatusVal = useSelector((state) =>
    state.tableSlice.tempRowData?.alltaskdata?.markasdone
      ? state.tableSlice.tempRowData.alltaskdata.markasdone
      : null
  );

  useEffect(() => {
    if (taskStatusVal) {
      setTaskStatusValue(taskStatusVal);
    }
    // console.log("taskStatusValue", taskStatusValue);
  }, [taskStatusVal]);

  useEffect(() => {
    if (taskStatus) {
      setTaskStatusChangeModalOpen(true);
    }
  }, [taskStatus]);

  const handleCloseStatusUpdate = () => {
    // dispatch(clearTablerowData({ tableId: "alltaskdata" }));
    setSaveRowAllTask(null);
    dispatch(
      clearCustomButtonClicked({ tableId: "alltaskdata", buttonId: "Block1" })
    );
    setTaskStatusChangeModalOpen(false);
  };

  const handleStatusUpdate = (markasdone) => {
    const body = {
      _id: saveRowAllTask?._id,
      status: markasdone,
    };
    dispatch(statusChangeTask(body));

    setTimeout(() => {
      dispatch(
        setSnackbar({
          open: true,
          message: "Task Status Updated Successfully",
          status: "success",
        })
      );
    }, 1000);

    dispatch(setReloadTableFlag({ tableId: "alltaskdata" }));

    setTimeout(() => {
      handleCloseStatusUpdate();
    }, 3000);
  };

  // **********************************All task Buttons*******************************

  const [taskListToggle, setTaskListToggle] = useState(1);

  const taskListMenuSelect = (val) => {
    setTaskListToggle(val);
    if (val === 2) {
      tableDataForAllTask.current.reqBody.searchcondition = {};

      tableDataForAllTask.current.reqBody.searchcondition = {
        assignee: userInfo?._id,
      };
      tableDataForAllTask.current.reqBody.condition["skip"] = 0;

      dispatch(DispositonTaskList(tableDataForAllTask.current.reqBody));
    } else if (val === 1) {
      tableDataForAllTask.current.reqBody.searchcondition = {};
      tableDataForAllTask.current.reqBody.condition["skip"] = 0;
      dispatch(DispositonTaskList(tableDataForAllTask.current.reqBody));
    } else if (val === 3) {
      tableDataForAllTask.current.reqBody.searchcondition = {};

      tableDataForAllTask.current.reqBody.searchcondition = {
        tasktargetdate: { $lte: new Date().getTime() },
        markasdone: 1,
      };
      tableDataForAllTask.current.reqBody.condition["skip"] = 0;

      dispatch(DispositonTaskList(tableDataForAllTask.current.reqBody));
    }
  };

  // **********************************All task Buttons*******************************


// wark space
  useEffect(()=>{

    if(route.pathname.includes('access-deal')){
      console.log("tableDataForUsers2",tableDataForUsers);
      tableDataForUsers.current['cellEdit']=true
      tableDataForUsers.current['editField']=['prospectbusinessname_type','approxgrossreciept','dc_date','other_info','contarct_review_date','confidence','next_action_date','next_steps_view','lost_declined','why']
      tableDataForUsers.current['editFieldEndPoint'] = "addupdate-users"
      tableDataForUsers.current['searchData']={}

      tableDataForUsers.current['searchData']={
        'heading': "Search Blue Coast Access Deal",
        'type': "drawer",
        'formData':{
          'id':"search_bluecoast_access_deal",
          'formWrapperClass': "formWrapperClass", 
          'formInlineCLass': "userForm", 
          'formButtonClass': "submitbtnsection",
          'submitBtnName': "Search",
          'submitBtnEndIcon': "search",
          'resetBtnName': "Refresh",
          'resetBtnEndIcon': "replay",
          'resetForm': true,
          'formAdditionalSubmissionData': {},
          'fields':[
            {
              'id': 0,
              'label': "Search by Confidence (H, M, L)",
              'name': "confidence",
              'type': "select",
              'values': [{ "name": "High", "val": "High" }, { "name": "Medium", "val": "Medium" }, { "name": "Low", "val": "Low" }],
              'className': "list_search_item countDiv__column--col6 ",
              'preload': false,
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "confidence" },
            },
            {
              'id': 1,
              'label': "Search by AE/REP Name",
              'name': "fullname",
              'type': "autoComplete",
              'className': "list_search_item countDiv__column--col6 ",
              'base_url':process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
              'preload': false,
              'reqBody': {
                'condition': {'limit': 5,'skip': 0,},
                'sort': {'type': "asc",'field': "name",},
                'searchcondition':  {
                  "outsideData": {
                    "$eq": null
                },
                "bluecoastPh": {
                    "$eq": null
                },
                  "is_deleted": {"$eq": null},
                },
              'type': "fullname",
              'source': "bluecoast_leads",
              },
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "rep_id" },
            },
            {
              'id': 2,
              'label': "Search by AE/REP Email",
              'name': "email",
              'type': "autoComplete",
              'className': "list_search_item countDiv__column--col6 ",
              'base_url':process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
              'preload': false,
              'reqBody': {
                'condition': {'limit': 5,'skip': 0,},
                'sort': {'type': "asc",'field': "email",},
                'searchcondition':  {
                  "outsideData": {"$eq": null},
                  "bluecoastPh": {"$eq": null},
                  "is_deleted": {"$eq": null},
                },
              'type': "email",
              'source': "bluecoast_leads",
              },
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "rep_id" },
            },
            {
              'id': 3,
              'label': "Search by Client Name",
              'name': "prospectfullname",
              'type': "autoComplete",
              'className': "list_search_item countDiv__column--col6 ",
              'base_url':process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
              'reqBody': {
                'condition': {'limit': 5,'skip': 0,},
                'sort': {'type': "asc",'field': "email",},
                'searchcondition': {'outsideData': { '$eq': null },'bluecoastPh': { '$eq': null },},
                'type': "prospect_name",
                'source': "bluecoast_leads",
              },
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "_id" },
            },
            {
              'id': 4,
              'label': "Search by Client Mail",
              'name': "prospectemail",
              'type': "autoComplete",
              'className': "list_search_item countDiv__column--col6 ",
              'base_url':process.env.REACT_APP_API_URL +"bclusers/bcl-leadlist-autocomplete",
              'reqBody': {
                'condition': {'limit': 5,'skip': 0,},
                'sort': {'type': "asc",'field': "email",},
                'searchcondition': {'outsideData': { '$eq': null },'bluecoastPh': { '$eq': null },},
                'type': "prospectemail",
                'source': "bluecoast_leads",
              },
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "_id" },
            },
            {
              'id': 5,
              'label': "Search by Medical Facility Business Name",
              'name': "prospectbusinessname",
              'type': "text",
              'className': "list_search_item countDiv__column--col6 ",
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "prospectbusinessname", 'param': "$regex", 'options': "i" },
            },
            {
              'id': 6,
              'label': "Search by DC Date",
              'name': "dc_date",
              'type': "datePicker",
              'className': "list_search_item countDiv__column--col6 ",
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "dc_date", },
            },
            {
              'id': 7,
              'label': "Search by Contarct Review Date",
              'name': "contarct_review_date",
              'type': "datePicker",
              'className': "list_search_item countDiv__column--col6 ",
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "contarct_review_date", },
            },
            {
              'id': 8,
              'label': "Search by Next Action Date",
              'name': "next_action_date",
              'type': "datePicker",
              'className': "list_search_item countDiv__column--col6 ",
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "next_action_date", },
            },
            {
              'id': 9,
              'label': "Search by Lost/Declined",
              'name': "lost_declined",
              'type': "select",
              'values':[{ "name": "Lost", "val": "Lost" }, { "name": "Declined", "val": "Declined" }],
              'className': "list_search_item countDiv__column--col6 ",
              'preload': false,
              'sx': { 'm': 1, 'minWidth': 300 },
              'payloadFormat': { 'key': "lost_declined" },
            },
            {
              'id': 109,
              'label': "Search by Keyword",
              'name': "all_field",
              'className': "list_search_item countDiv__column--col6 ",
              'type': "text",
              'payloadFormat': { 'key': "all_field", 'param': "$regex", 'options': "i" },
            }

          ]
        }
      }




    }
  }, [route])











  return (
    <>


      <Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast dataTransfer_modal"
        open={chosePathModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "10px solid #0569ad!important",
            background: "#fff",
            boxShadow: 24,
            textAlign: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h5">
            <p className="alertPara2">
              Choose an option where you want to transfer data
            </p>
            {/* <p> {`Choose an option where you want to transfer data`}  </p> */}
          </Typography>
          <div style={{ padding: "20px" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              onChange={(e) => setListType(e.target.value)}
              name="radio-buttons-group"
            >
              {menuSelect !== 1 && (
                <FormControlLabel
                  value="normalbluecoast"
                  control={<Radio />}
                  label="UNDERPAYMENTS LEAD (BLUE COAST)"
                  disabled={
                    saveRowdata?.bluecoastPh == null &&
                      saveRowdata?.bluecoastphblue == null &&
                      saveRowdata?.outsideData == null
                      ? true
                      : false
                  }
                />
              )}
              {menuSelect !== 2 && (
                <FormControlLabel
                  value="outsideData"
                  control={<Radio />}
                  label="UNDERPAYMENTS (ASPIRE)"
                  disabled={saveRowdata?.outsideData ? true : false}
                />
              )}
              {menuSelect !== 5 && (
                <FormControlLabel
                  value="bluecoastPh"
                  control={<Radio />}
                  label="PHARMACY LEAD (ASPIRE)"
                  disabled={saveRowdata?.bluecoastPh ? true : false}
                />
              )}
              {menuSelect !== 6 && (
                <FormControlLabel
                  value="bluecoastphblue"
                  control={<Radio />}
                  label="PHARMACY LEAD (BLUE COAST)"
                  disabled={saveRowdata?.bluecoastphblue ? true : false}
                />
              )}
            </RadioGroup>
          </div>

          {listType !== null && listType !== undefined && (
            // <Button style={{backgroundColor: "#0569ad", marginBottom: "10px" }} onClick={HandelDataTreancefer} >
            //   <Typography variant='h4' component="h4" >
            //     <p style={{ color: "white" }}>OK</p>
            //   </Typography>
            // </Button>
            <Button
              variant="contained"
              style={{ marginBottom: "15px" }}
              onClick={HandelDataTreancefer}
              className="modalBTN"
            >
              Ok
            </Button>
          )}

          {bluecoastDataTranceferLoader ? (
            <LinearProgress sx={{ marginBottom: "10px" }} />
          ) : (
            ""
          )}

          <Button onClick={CloseDataTrancferMosal} className="closeModalBtn">
            <Tooltip title="Close" placement="right-start">
              <CloseIcon />
            </Tooltip>
          </Button>
        </Box>
      </Modal>



      {/* ********************* annulBilling Modal**************** */}

      <Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast"
        open={annulBillingModaOpenl}
        onClose={CloseAnnulBillingModaOpenl}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={CloseAnnulBillingModaOpenl}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className="priority_Box_Main">
            <h2 className="previewModalTitle ">
              {saveRowdata?.prospectfullname && (
                <div className="modal_title modal_titlenew">
                  {" "}
                  Total Gross Insurance Gross Receipts for{" "}
                  {saveRowdata?.prospectfullname}
                </div>
              )}
            </h2>
            <Box sx={{ minWidth: 120 }} className="mpdal_healper">
              <TextField
                id="annual_billing"
                label="Annual Billing"
                fullWidth
                // value={numberWithCommas(annulBillingValue?.current)}
                defaultValue={
                  annualBillingData !== null ? annualBillingData : undefined
                }
                onChange={(e) => {
                  annulBillingValue.current = e.target.value;
                }}
                // onKeyPress={(e) => {
                //   e.target.value
                //     .toString()
                //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //   console.log(
                //     "e=================>",
                //     e.target.value
                //       .toString()
                //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                //   );
                // }}
                // onKeyDown={(e) => {
                //   console.log("e=================>",e.target.value);
                //    e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                // }}
                // type="number"
                // onChange={handleChangeUnderPayment}
                // helperText="This should be dollar amount"
                // variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                className="modal_text_field_cls"
                id="firstPayer"
                label="First Payer"
                fullWidth
                // value={firstPayer.current}
                defaultValue={
                  blueCoastData?.firstPayer !== null
                    ? blueCoastData?.firstPayer
                    : undefined
                }
                onChange={(e) => {
                  // setfirstPayer(e.target.value);
                  // console.log("firstPayer",firstPayer);
                  firstPayer.current = e.target.value;
                }}

              // helperText="This should be dollar amount"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}
              />

              <TextField
                className="modal_text_field_cls"
                id="secondPayer"
                label="Second Payer"
                fullWidth
                // value={secondPayer}
                defaultValue={
                  blueCoastData?.secondPayer !== null
                    ? blueCoastData?.secondPayer
                    : undefined
                }
                onChange={(e) => {
                  secondPayer.current = e.target.value;
                }}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}
              />

              <TextField
                className="modal_text_field_cls"
                id="thiredPayer"
                label="Third Payer"
                fullWidth
                // value={thiredPayer}
                defaultValue={
                  blueCoastData?.thiredPayer !== null
                    ? blueCoastData?.thiredPayer
                    : undefined
                }
                onChange={(e) => {
                  thiredPayer.current = e.target.value;
                }}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}
              />

              <TextField
                className="modal_text_field_cls"
                id="govtPay"
                label="Government %"
                fullWidth
                // value={govtPay}
                defaultValue={
                  blueCoastData?.govtPay !== null
                    ? blueCoastData?.govtPay
                    : undefined
                }
                onChange={(e) => {
                  govtPay.current = e.target.value;
                }}
                // onChange={(e) => handleKeyPress(e)}

                onKeyPress={(e) => {
                  console.log(
                    "e=============>",
                    e.target.value,
                    e.which,
                    e.key
                  );
                  let tval = e.target.value.toString();
                  if (isNaN(e.key) && e.key !== ".") e.preventDefault();
                  if (tval == null || tval === undefined || isNaN(tval))
                    tval = 0;
                  let tempval = "";
                  // let tempval = parseFloat(tval.toString() +''+ (e.key).toString());
                  tempval = tval.toString().concat("", e.key.toString());
                  // console.log(tval, "tv==al", e.key.toString());
                  // console.log(tempval, "tval", tval);
                  if (tempval != null && tempval !== undefined) {
                    tempval = parseFloat(tempval).toFixed(2);
                    // e.target.value = tempval;
                    let tempsplit = tval.split(".");
                    if (
                      tempsplit[1] != null &&
                      tempsplit[1] !== undefined &&
                      tempsplit[1].toString().length >= 2
                    ) {
                      e.preventDefault();
                    }
                  }
                  if (tempval > 100 || isNaN(tempval)) {
                    e.preventDefault();
                  }
                }}
                type="text"
              // onChange={handleChangeUnderPayment}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}
              />

              <TextField
                id="privatePay"
                className="modal_text_field_cls"
                label="Private Pay %"
                fullWidth
                // value={privatePay}
                defaultValue={
                  blueCoastData?.privatePay !== null
                    ? blueCoastData?.privatePay
                    : undefined
                }
                onChange={(e) => {
                  // setprivatePay(Number(e.target.value));
                  privatePay.current = e.target.value;
                }}
                onKeyPress={(e) => {
                  console.log(
                    "e=============>",
                    e.target.value,
                    e.which,
                    e.key
                  );
                  let tval = e.target.value.toString();
                  if (isNaN(e.key) && e.key !== ".") e.preventDefault();
                  if (tval == null || tval === undefined || isNaN(tval))
                    tval = 0;
                  let tempval = "";
                  // let tempval = parseFloat(tval.toString() +''+ (e.key).toString());
                  tempval = tval.toString().concat("", e.key.toString());
                  console.log(tval, "tv==al", e.key.toString());
                  // console.log(tempval, "tval", tval);
                  if (tempval != null && tempval !== undefined) {
                    tempval = parseFloat(tempval).toFixed(2);
                    // e.target.value = tempval;
                    let tempsplit = tval.split(".");
                    if (
                      tempsplit[1] != null &&
                      tempsplit[1] !== undefined &&
                      tempsplit[1].toString().length >= 2
                    ) {
                      e.preventDefault();
                    }
                  }
                  if (tempval > 100 || isNaN(tempval)) {
                    e.preventDefault();
                  }
                }}
                type="text"
              // onChange={handleChangeUnderPayment}
              // helperText="This should be dollar amount"
              // variant="filled"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <AttachMoneyIcon />
              //     </InputAdornment>
              //   ),
              // }}
              />
            </Box>
            <div className="Formsubmit_button modal_sbBtn">
              <Button
                variant="contained"
                onClick={() => handleChangeAnnualBilling()}
              >
                Submit
              </Button>
            </div>
            {loaderannulBillingValue && (
              <Box
                className="loaderProgress"
                sx={{ width: "100%", mt: "10px" }}
              >
                <LinearProgress />
              </Box>
            )}
          </div>
        </Box>
      </Modal>

      {/* ********************* Under Payment Modal**************** */}

      <Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast"
        open={underPaymentModaOpenl}
        onClose={CloseUnderPaymentModaOpenl}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={CloseUnderPaymentModaOpenl}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className="priority_Box_Main">
            <h2 className="previewModalTitle ">
              {saveRowdata?.prospectfullname && (
                <div className="modal_title modal_titlenew">
                  {" "}
                  Underpayment Opportunity for {saveRowdata?.prospectfullname}
                </div>
              )}
            </h2>
            <Box sx={{ minWidth: 120 }} className="mpdal_healper">
              <TextField
                id="under_payment"
                label="Underpayment Opportunity "
                value={underPaymentValue}
                defaultValue={
                  underPaymentData !== null ? underPaymentData : undefined
                }
                fullWidth
                type="number"
                onChange={(e) => {
                  setunderPaymentValue(e.target.value);
                }}
                // onChange={handleChangeUnderPayment}

                helperText="This should be dollar amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              // variant="standard"
              />
            </Box>
            <div className="Formsubmit_button modal_sbBtn">
              <Button
                variant="contained"
                onClick={() => handleChangeUnderPayment()}
              >
                Submit
              </Button>
            </div>
            {loaderUnderForPayment && (
              <Box
                className="loaderProgress"
                sx={{ width: "100%", mt: "10px" }}
              >
                <LinearProgress />
              </Box>
            )}
          </div>
        </Box>
      </Modal>

      <div className="bluecoastLead_list_mainwrpr">
        {leadLoading || loader ? <LinearProgress /> : ""}

        <div className="rolelist_mainwrp bluecoastLead_list">
          {!leadid && <div className="click_buttonwp navigationMain">
            {/* {JSON.stringify(userInfo?.email)} */}
            {/* <div>  {process.env.REACT_APP_ENVIRONMENT !== 'prod'  &&  <Checkbox
             checked={checkBoxOne}
            onChange={(e) => {
              //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
              if (e.target.checked === true) {
                setCheckBoxOne(true);
              } else {
                setCheckBoxOne(false);
              }
              // clearErrors(f
            }}

            />}{process.env.REACT_APP_ENVIRONMENT != 'prod' && <Button id="buttonone" className={menuSelect === 8 ? "active" : ""} variant="contained" onClick={() => mainListbtnClicked(8)}>All Leads (Underpayments & Pharmacy)
            </Button>}</div> */}

            <div>
              {/* {process.env.REACT_APP_ENVIRONMENT !== 'prod'  && <Checkbox
             checked={checkBoxSeven}
            onChange={(e) => {
              //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
              if (e.target.checked === true) {
                setCheckBoxSeven(true);
              } else {
                setCheckBoxSeven(false);
              }
              // clearErrors(f
            }}

            />} */}
              {userInfo?.email &&
                userInfo?.email != "bbutters@aspirepartnersusa.com" &&
                userInfo?.email != "wayne@aspirepartnersusa.com" &&
                userInfo?.email !== "sellisha@aspirepartnersusa.com" &&
                userInfo?.email != "butters@yopmail.com" &&
                userInfo?.email != "selisha.test@yopmail.com" &&
                userInfo?.email != "wayne.entity@yopmail.com" && (
                  <Button
                    id="buttonsix"
                    className={menuSelect === 10 ? "active" : ""}
                    variant="contained"
                    onClick={() => mainListbtnClicked(10)}
                  >
                    <p> All UnderPayments Leads </p>
                  </Button>
                )}
            </div>

            <div>
              {/* {process.env.REACT_APP_ENVIRONMENT !== 'prod' && <Checkbox
             checked={checkBoxSix}
            onChange={(e) => {
              //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
              if (e.target.checked === true) {
                setCheckBoxSix(true);
              } else {
                setCheckBoxSix(false);
              }
              // clearErrors(f
            }}

            />} */}
              {userInfo?.email &&
                userInfo?.email != "bbutters@aspirepartnersusa.com" &&
                userInfo?.email != "wayne@aspirepartnersusa.com" &&
                userInfo?.email !== "sellisha@aspirepartnersusa.com" &&
                userInfo?.email != "butters@yopmail.com" &&
                userInfo?.email != "selisha.test@yopmail.com" &&
                userInfo?.email != "wayne.entity@yopmail.com" &&
                userInfo?.email !== "baggett.kyle@gmail.com" && userInfo?.email !== "jeannette@adhcunderpayments.com" && userInfo?.email !== "patrick@adhcunderpayments.com" && (
                  <Button
                    id="buttonsix"
                    className={menuSelect === 9 ? "active" : ""}
                    variant="contained"
                    onClick={() => mainListbtnClicked(9)}
                  >
                    <p> All Pharmacy Leads </p>
                  </Button>
                )}
            </div>

            <div>
              {/* {process.env.REACT_APP_ENVIRONMENT !== 'prod' &&userInfo?.email && (userInfo?.email != 'bbutters@aspirepartnersusa.com' && userInfo?.email != 'wayne@aspirepartnersusa.com' &&
                userInfo?.email !== 'sellisha@aspirepartnersusa.com')   &&  <Checkbox 
                checked={checkBoxTwo}
                 onChange={(e) => {
              //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
              if (e.target.checked === true) {
                setCheckBoxTwo(true);
              } else {
                setCheckBoxTwo(false);
              }
              // clearErrors(fielddata.name);
            }}
              />} */}
              {userInfo?.email &&
                userInfo?.email != "bbutters@aspirepartnersusa.com" &&
                userInfo?.email != "wayne@aspirepartnersusa.com" &&
                userInfo?.email !== "sellisha@aspirepartnersusa.com" &&
                userInfo?.email != "butters@yopmail.com" &&
                userInfo?.email != "selisha.test@yopmail.com" &&
                userInfo?.email != "wayne.entity@yopmail.com" && (
                  <Button
                    id="buttontwo"
                    className={menuSelect === 1 ? "active" : ""}
                    variant="contained"
                    onClick={() => mainListbtnClicked(1)}
                  >
                    <p> Underpayments Leads (Blue Coast) </p>
                  </Button>
                )}
            </div>
            <div>

              <Button
                id="buttonthree"
                className={menuSelect === 2 ? "active" : ""}
                variant="contained"
                onClick={() => mainListbtnClicked(2)}
              >
                <p> Underpayments Leads (Aspire) </p>
              </Button>
            </div>

            <div>
 
              {userInfo?.email !== "baggett.kyle@gmail.com" && userInfo?.email !== "jeannette@adhcunderpayments.com" && userInfo?.email !== "patrick@adhcunderpayments.com" && (
                <Button
                  id="buttonfour"
                  className={menuSelect === 5 ? "active" : ""}
                  variant="contained"
                  onClick={() => mainListbtnClicked(5)}
                >
                  <p> Pharmacy Leads (Aspire) </p>
                </Button>
              )}
            </div>

            <div>
              {userInfo?.email &&
                userInfo?.email != "bbutters@aspirepartnersusa.com" &&
                userInfo?.email != "tammie@bluecoastsavings.com" &&
                userInfo?.email != "wayne@aspirepartnersusa.com" &&
                userInfo?.email != "sellisha@aspirepartnersusa.com" &&
                userInfo?.email != "butters@yopmail.com" &&
                userInfo?.email != "selisha.test@yopmail.com" &&
                userInfo?.email != "wayne.entity@yopmail.com" &&
                userInfo?.email !== "baggett.kyle@gmail.com" && userInfo?.email !== "jeannette@adhcunderpayments.com" && userInfo?.email !== "patrick@adhcunderpayments.com" && (
                  <Button
                    id="buttonfive"
                    className={menuSelect === 6 ? "active" : ""}
                    variant="contained"
                    onClick={() => mainListbtnClicked(6)}
                  >
                    <p> Pharmacy Leads (Blue Coast) </p>
                  </Button>
                )}
            </div>
            <div>
              {process.env.REACT_APP_ENVIRONMENT !== "prod" && (
                <Button
                  id="buttonfourteen"
                  className={menuSelect === 14 ? "active" : ""}
                  variant="contained"
                  onClick={() => mainListbtnClicked(14)}
                >
                  <p> View All Task </p>
                </Button>
              )}
            </div>
          </div>}
          {userInfo &&
            userInfo.main_role == "special_entities" &&
            userInfo.other_role.includes("is_developer") && (
              <div className="gotoadmindashbbtn">
                <Button onClick={() => gotoadmindashboard()}>
                  Go to Admin Dashboard <DashboardIcon />
                </Button>
              </div>
            )}

          {userInfo &&
            userInfo.main_role == "special_entities" &&
            userInfo.other_role.includes("is_rep") && (
              <div className="gotoadmindashbbtn">
                <Button onClick={() => gotorepdashboard()}>
                  Go to Rep Dashboard <DashboardIcon />
                </Button>
              </div>
            )}

          {openAllNotesModalLoader || paymentloading ? <LinearProgress /> : ""}
          {/* 
          {
            <div className="gotoadmindashbbtn">
              <Button onClick={() => gotorepdashboard()}>
                Go to Rep Dashboard <DashboardIcon />
              </Button>
            </div>
          } */}

          {openAllNotesModalLoader || paymentloading ? <LinearProgress /> : ""}

          {!notesViewOpen && (
            <div>
              <div className="listing_heading">
                {menuSelect == 1 ? (
                  <h1>Underpayments Leads (Blue Coast)</h1>
                ) : menuSelect == 2 ? (
                  <h1>Underpayments Leads (Aspire)</h1>
                ) : menuSelect == 5 ? (
                  <h1>Pharmacy Leads (Aspire)</h1>
                ) : menuSelect == 6 ? (
                  <h1>Pharmacy Leads (Blue Coast)</h1>
                ) : menuSelect == 7 ? (
                  <h1>Shawn Hull Blue Coast Leads/Direct Sells</h1>
                ) : menuSelect == 8 ? (
                  <h1>All Leads (UnderPayments && Pharmacy)</h1>
                ) : menuSelect == 9 ? (
                  <h1>All Pharmacy Leads</h1>
                ) : menuSelect == 10 ? (
                  <h1>All UnderPayments Leads</h1>
                ) : menuSelect == 14 ? (
                  <h1>All Task List</h1>
                ) : (
                  <h1>Pharmacy Savings Aspire</h1>
                )}

                {!leadid && <div className="blue_cost_btn_mt">
                  {userInfo &&
                    Object.keys(userInfo).length > 0 &&
                    userInfo.main_role == "special_entities" &&
                    (menuSelect == 1 || menuSelect == 2) && (
                      <button className="addrole_btn" onClick={addFormOpen}>
                        {" "}
                        <AddIcon />
                      </button>
                    )}
                  {userInfo &&
                    Object.keys(userInfo).length > 0 &&
                    userInfo.main_role == "special_entities" &&
                    (menuSelect == 5 || menuSelect == 6) && (
                      <button
                        className="addrole_btn"
                        onClick={addBlueCoastPharmacyFormOpen}
                      >
                        {" "}
                        <AddIcon />
                      </button>
                    )}
                </div>}

                <Modal
                  className="modalBaseStyle addFormBlueCOast"
                  open={addformModalOpen}
                  onClose={addFormClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                      <IconButton
                        onClick={addFormClose}
                        className="modal_icon_button"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                    <div className="dataform2_wrapper">
                      {menuSelect == 2 ? (
                        <div className="adminformbody">
                          <h2 className="addFormTitle modal_title">
                            {" "}
                            {editBlcstDataLead &&
                              Object.keys(editBlcstDataLead).length > 0
                              ? "Edit Underpayments Lead (Aspire)"
                              : "Add Underpayments Lead (Aspire)"}
                          </h2>
                          {/* <div className="highlighted_guides">
                            <span>IMPORTANT:  Once your submitted lead is accepted, you will receive a Next Steps email with detailed instructions and videos to schedule your Strategy Call with Beto and your Discovery Call with Travis. You will receive this email within two business days of submitting your lead. If you do not see this email in your inbox, please check your Spam/junk email. Please review the Next Steps email before scheduling and calendar events with Beto or Travis, following the guidelines for scheduling as instructed. All calendar events scheduled that did not follow the guidelines will be canceled due to the high demand for this service. </span>
                          </div> */}
                          <Form formData={addFormBlueCstLead.current} />
                          {loading && (
                            <Box
                              className="loaderProgress"
                              sx={{ width: "100%", mt: "10px" }}
                            >
                              <LinearProgress />
                            </Box>
                          )}
                        </div>
                      ) : (
                        <div className="adminformbody">
                          <h2 className="addFormTitle modal_title">
                            {" "}
                            {editBlcstDataLead &&
                              Object.keys(editBlcstDataLead).length > 0
                              ? "Edit Underpayments Lead (Blue Coast)"
                              : "Add Underpayments Lead (Blue Coast)"}
                          </h2>
                          {/* <div className="highlighted_guides">
                            <span>IMPORTANT:  Once your submitted lead is accepted, you will receive a Next Steps email with detailed instructions and videos to schedule your Strategy Call with Beto and your Discovery Call with Travis. You will receive this email within two business days of submitting your lead. If you do not see this email in your inbox, please check your Spam/junk email. Please review the Next Steps email before scheduling and calendar events with Beto or Travis, following the guidelines for scheduling as instructed. All calendar events scheduled that did not follow the guidelines will be canceled due to the high demand for this service. </span>
                          </div> */}
                          <Form formData={addFormBlueCstLead.current} />
                          {loading && (
                            <Box
                              className="loaderProgress"
                              sx={{ width: "100%", mt: "10px" }}
                            >
                              <LinearProgress />
                            </Box>
                          )}
                        </div>
                      )}
                    </div>
                  </Box>
                </Modal>

                <Modal
                  className="modalBaseStyle addFormBlueCOast"
                  open={addformBlueCoastPharmacyOpen}
                  onClose={addBlueCoastPharmacyFormClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modalBaseBox mediumModal">
                    <Tooltip title="Close">
                      <IconButton
                        onClick={addBlueCoastPharmacyFormClose}
                        className="modal_icon_button"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                    <div className="dataform2_wrapper">
                      {menuSelect == 5 ? (
                        <div className="adminformbody">
                          <h2 className="addFormTitle modal_title">
                            {" "}
                            {editBlcstDataLead &&
                              Object.keys(editBlcstDataLead).length > 0
                              ? "Edit Pharmacy Lead (Aspire)"
                              : "Add Pharmacy Lead (Aspire)"}
                          </h2>
                          <Form formData={addFormBlueCstPharmacyLead.current} />
                          {loading && (
                            <Box
                              className="loaderProgress"
                              sx={{ width: "100%", mt: "10px" }}
                            >
                              <LinearProgress />
                            </Box>
                          )}
                        </div>
                      ) : (
                        <div className="adminformbody">
                          <h2 className="addFormTitle modal_title">
                            {" "}
                            {editBlcstDataLead &&
                              Object.keys(editBlcstDataLead).length > 0
                              ? "Edit Pharmacy Lead (Blue Coast)"
                              : "Add Pharmacy Lead (Blue Coast)"}
                          </h2>
                          <Form formData={addFormBlueCstPharmacyLead.current} />
                          {loading && (
                            <Box
                              className="loaderProgress"
                              sx={{ width: "100%", mt: "10px" }}
                            >
                              <LinearProgress />
                            </Box>
                          )}
                        </div>
                      )}
                      <h2 className="addFormTitle modal_title"> </h2>
                    </div>
                  </Box>
                </Modal>
              </div>

              {loadinginbluecoast ? (
                <Box
                  className="loaderProgress"
                  sx={{ width: "100%", mt: "10px" }}
                >
                  <LinearProgress />
                </Box>
              ) : null}

              {(menuSelect == 1 ||
                menuSelect == 2 ||
                menuSelect == 5 ||
                menuSelect == 6 ||
                menuSelect == 8 ||
                menuSelect == 9 ||
                menuSelect == 10) && (
                  <div className="rolelist_mainwrp_Table SD_rolelist_mainwrp_Table access_dealWrpr">
                    {/* <TextField id="outlined-basic" label="Keyword Search" variant="outlined" /> */}
                    {/* <p>{JSON.stringify(tableDataForUsers.current.reqBody.searchcondition['all_field'])}</p> 
                   <p>{JSON.stringify(tableDataForUsers.current.reqBody.searchcondition['last_disposition_name'])}</p>  */}
                    {!leadid && <div className="keywordSearch">
                      <SearchKey
                        requestBody={tableDataForUsers.current.reqBody}
                        reQuestNewsearchConditin={
                          tableDataForUsers.current.reqBody.searchcondition
                        }
                        // setLoadingKey={setLoadingkey}
                        menuSelect={menuSelect}
                      />
                    </div>}
                    {!loadinginbluecoast ? (
                      <div>
                        <ListingTable
                          tableData={tableDataForUsers.current}
                          dataset={bluecoastdata}
                          modifyHeaders={modifyTableHeaders}
                        />
                        {!leadid && <div className="blue_cost_btn_mt">
                          {userInfo &&
                            Object.keys(userInfo).length > 0 &&
                            userInfo.main_role == "special_entities" &&
                            (menuSelect == 1 || menuSelect == 2) && (
                              <button
                                className="addrole_btn"
                                onClick={addFormOpen}
                              >
                                {" "}
                                <AddIcon />
                              </button>
                            )}
                          {/* <p>{JSON.stringify(userInfo)}</p> */}
                          {userInfo &&
                            Object.keys(userInfo).length > 0 &&
                            userInfo.main_role == "special_entities" &&
                            (menuSelect == 5 || menuSelect == 6) && (
                              <button
                                className="addrole_btn"
                                onClick={addBlueCoastPharmacyFormOpen}
                              >
                                {" "}
                                <AddIcon />
                              </button>
                            )}
                        </div>}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <TableSkeleton count={5} />{" "}
                      </div>
                    )}
                  </div>
                )}

              {(menuSelect == 4 || menuSelect == 3) && (
                <div className="rolelist_mainwrp_Table">
                  {leadLoading === false ? (
                    <ListingTable
                      tableData={tableDataForLead.current}
                      // dataset={data1}
                      dataset={leaddataforlist}
                      modifyHeaders={modifyTableHeadersForLead}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}
                </div>
              )}
              {menuSelect == 14 && (
                <div className="rolelist_mainwrp_Table All_Task_List">
                  {alltaskloading === false ? (
                    <div className="All_Tasklist_main_wrpr">
                      {process.env.REACT_APP_ENVIRONMENT != "prod" &&
                        userInfo &&
                        Object.keys(userInfo).length > 0 &&
                        userInfo.main_role == "special_entities" &&
                        menuSelect == 14 && (
                          <>
                            <div className="All_task_btn">
                              <Button
                                className={taskListToggle === 1 ? "active" : ""}
                                variant="contained"
                                onClick={() => taskListMenuSelect(1)}
                              >
                                All Task
                              </Button>
                              <Button
                                className={taskListToggle === 2 ? "active" : ""}
                                variant="contained"
                                onClick={() => taskListMenuSelect(2)}
                              >
                                Assigned to Me
                              </Button>
                              <Button
                                className={taskListToggle === 3 ? "active" : ""}
                                variant="contained"
                                onClick={() => taskListMenuSelect(3)}
                              >
                                Past Due Task
                              </Button>
                            </div>
                          </>
                        )}
                      {process.env.REACT_APP_ENVIRONMENT != "prod" &&
                        userInfo &&
                        Object.keys(userInfo).length > 0 &&
                        userInfo.main_role == "special_entities" &&
                        menuSelect == 14 &&
                        (taskListToggle == 1 || taskListToggle == 2) && (
                          <button
                            className="addTask_btn"
                            onClick={() => {
                              addTaskModal();
                            }}
                          >
                            {" "}
                            <AddIcon />
                          </button>
                        )}

                      <ListingTable
                        tableData={tableDataForAllTask.current}
                        // dataset={data1}
                        dataset={dataforalltask}
                        modifyHeaders={modifyTableHeadersForAllTask}
                      />
                    </div>
                  ) : (
                    <TableSkeleton count={5} />
                  )}

                  {/* <p>{JSON.stringify(dataforalltask)}</p> */}
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {/* ======================Ip Info Modal================ */}
          <Modal
            className="modalBaseStyle notesModal_blcoast IPInfo_Modal"
            open={ipInfoModal}
            onClose={handleClose}
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleClose}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="view_details">
                <h2 className="previewModalTitle ">
                  {ipInfo?.fullname && (
                    <div className="modal_title modal_titlenew">
                      {" "}
                      Ip-Info for {ipInfo.fullname}
                    </div>
                  )}
                </h2>
                <div className="modalview_group">
                  {ipInfo?.ipinfo && Object.keys(ipInfo.ipinfo).length > 0 ? (
                    <div className="data_container">
                      <div className="data_block">
                        {ipInfo?.ipinfo?.ip && (
                          <div className="property_container">
                            <label className="property_label">IP:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.ip}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.hostname && (
                          <div className="property_container">
                            <label className="property_label">Host Name:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.hostname}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.city && (
                          <div className="property_container">
                            <label className="property_label">City:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.city}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.region && (
                          <div className="property_container">
                            <label className="property_label">Region:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.region}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.country && (
                          <div className="property_container">
                            <label className="property_label">Country:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.country}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.loc && (
                          <div className="property_container">
                            <label className="property_label">Location:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.loc}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.org && (
                          <div className="property_container">
                            <label className="property_label">
                              Organization:
                            </label>
                            <label className="property_value">
                              {ipInfo.ipinfo.org}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.postal && (
                          <div className="property_container">
                            <label className="property_label">Postal:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.postal}
                            </label>
                          </div>
                        )}
                        {ipInfo?.ipinfo?.timezone && (
                          <div className="property_container">
                            <label className="property_label">Time Zone:</label>
                            <label className="property_value">
                              {ipInfo.ipinfo.timezone}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="nodatafound">No Records Found</div>
                  )}
                </div>
              </div>
            </Box>
          </Modal>

          {/* ===========Common Modal for special action buttons==============*/}
          <Modal
            className="modalBaseStyle specialCommonModal notesModal_blcoast"
            open={specialEntityModal}
            onClose={specialModalClose}
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={specialModalClose}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="view_details">
                <h2 className="previewModalTitle ">
                  <div className="modal_title modal_titlenew"> HEY!</div>
                </h2>
              </div>
              <div className="modalview_group">
                <h3>ARE YOU SURE YOU WANT TO {statesForModalText}?</h3>
                <div className="confirmationBtns">
                  <button
                    className="blucoastList_specialbtn"
                    onClick={() => proceed()}
                  >
                    Yes
                  </button>
                  <button
                    className="blucoastList_specialbtn"
                    onClick={specialModalClose}
                  >
                    No
                  </button>
                </div>

                {!actionhitloader && (
                  <Box
                    className="loaderProgress"
                    sx={{ width: "100%", mt: "10px" }}
                  >
                    <LinearProgress />
                  </Box>
                )}
              </div>
            </Box>
          </Modal>

          {/* =================Modal for Notes about Prospect================ */}

          <Modal
            className="notesModal_blcoast Modal_for_Prospect"
            open={notesModalOpen}
            onClose={handleCloseNotesModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseNotesModal}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="innerWrapperfor_notes">
                <div className="rolelist_mainwrp">
                  <h2 className="previewModalTitle ">
                    {ipInfo?.prospectfullname && (
                      <div className="modal_title modal_titlenew">
                        {" "}
                        Notes for Prospect's ({ipInfo.prospectfullname})
                      </div>
                    )}
                    {/* <h1>{JSON.stringify(ipInfo)}</h1> */}
                  </h2>
                  <div className="rolelist_mainwrp_Table">
                    {/* <p>{JSON.stringify(bluecoastnotedata)}</p> */}

                    {!loadinginbluecoastnotes &&
                      bluecoastnotedata &&
                      bluecoastnotedata.length > 0 && (
                        <ListingTable
                          tableData={tableDataForBlueCoastNotes.current}
                          dataset={bluecoastnotedata}
                          modifyHeaders={modifyTableHeadersfornotes}
                        />
                      )}
                    {loadinginbluecoastnotes && <TableSkeleton count={5} />}
                  </div>
                </div>
                {userInfo &&
                  Object.keys(userInfo).length > 0 &&
                  userInfo.main_role == "special_entities" && (
                    <div>
                      {/* <Form formData={notesdataform.current} /> */}
                      <div className="Notes_Form">
                        <p>Notes</p>
                        <TextareaAutosize
                          placeholder={"Type here..."}
                          // value={textareayvalue.current}
                          ref={inputTextProspect}
                          onChange={(e) => {
                            textareayvalue.current = e.target.value;
                            // console.log("textareayvalue=========>",textareayvalue.current);
                          }}
                        />
                      </div>
                      <div>
                        {otherspicialusers &&
                          otherspicialusers.length > 0 &&
                          otherspicialusers.map((data, idx) => (
                            // console.log("idx====>",idx);
                            <div className="checkboxgroup" key={idx}>
                              {/* { JSON.stringify(idx)} */}
                              {data.firstname !== "Wayne" &&
                                data.firstname !== "Sellisha" && data.firstname !== "Travis" && (
                                  <>
                                    <Checkbox
                                      checked={
                                        checkboxvalues.includes(data._id)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        chnagecheckbox(
                                          e.target.checked,
                                          data._id
                                        );
                                        //////////////////////////// Adding Value in the Array as per Checkbox ////////////////////////////
                                      }}
                                    />
                                    <p>
                                      Send Notification to {data.firstname}{" "}
                                    </p>
                                  </>
                                )}
                            </div>
                          ))}
                      </div>
                      <div>
                        <button
                          className="blucoastList_specialbtn"
                          onClick={() => addnote()}
                        >
                          Add Note
                        </button>
                        {scratchaddnoteflag && (
                          <Box
                            className="loaderProgress"
                            sx={{ width: "100%", mt: "10px" }}
                          >
                            <LinearProgress />
                          </Box>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </Box>
          </Modal>

          {/* /*****all notes list modal*******************************  */}

          <Modal
            className="notesModal_blcoast All_Noteswrpr "
            open={allNotesModal}
            onClose={handleCloseAllNotesModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseAllNotesModal}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="rolelist_mainwrp">
                <h2 className="previewModalTitle ">
                  <div className="modal_title modal_titlenew">
                    {" "}
                    All Notes List for Prospect's{" "}
                  </div>
                </h2>
                <div className="rolelist_mainwrp_Table">
                  {/* <p>{JSON.stringify(bluecoastnotedata)}</p> */}

                  {/* {!openAllNotesModalLoader ? <LinearProgress /> : ''} */}

                  {!loadinginbluecoastallnotes ? (
                    <ListingTable
                      tableData={tableDataForBlueCoastAllNotes.current}
                      dataset={bluecoastnotedata}
                      modifyHeaders={modifyTableHeadersforAllnotes}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}
                </div>
              </div>
            </Box>
          </Modal>
          {/* ************************Manage Dist Modal*******************************/}
          <Modal
            className="mngDispotn_modal All_Noteswrpr"
            open={manageDistModalOpen}
            onClose={handleCloseDistManageModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseDistManageModal}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div>
                <h2 className="previewModalTitle ">
                  {/* <h1>{JSON.stringify(dispositionData)}</h1> */}
                  {ipInfo?.prospectfullname && (
                    <div className="modal_title modal_titlenew">
                      {" "}
                      Manage Disposition for {ipInfo.prospectfullname}
                    </div>
                  )}
                  {/* <h1>{JSON.stringify(ipInfo)}</h1> */}
                  {/* <p>{JSON.stringify(manageDist)}</p> */}
                </h2>
                <div className="inputBlock inputBlock3line">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Manage Disposition
                      </InputLabel>
                      <Select
                      className="disp_dropDown"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={manageDist}
                        label="Manage Disposition"
                        onChange={handleChange}
                      >
                        <MenuItem value={"New Lead"}>New Lead   <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#186A3B"}}></p></MenuItem>
                        <MenuItem value={"Accepted"}>Accepted <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#70f569"}}></p> </MenuItem>
                        <MenuItem value={"Declined"}>Declined <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#ed5151"}}></p></MenuItem>
                        <MenuItem value={"Advisor/AE Contacted"}>Advisor/AE Contacted <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#67a863"}}></p></MenuItem>
                        <MenuItem value={"Advisor/AE Meeting Set"}>Advisor/AE Meeting Scheduled <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#a4bda2"}}></p></MenuItem>
                        <MenuItem value={"Advisor/AE Meeting Completed"}>Advisor/AE Meeting Completed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#a8edbe "}}></p></MenuItem>
                        <MenuItem value={"Advisor/AE Meeting Missed"}>Advisor/AE Meeting Missed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#bf0000"}}></p></MenuItem>
                        <MenuItem value={"Discovery Call Set"}>Discovery Call Scheduled <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#c1ff45"}}></p></MenuItem>
                        <MenuItem value={"Discovery Call Completed"}>Discovery Call Completed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#7d9e3a"}}></p></MenuItem>
                        <MenuItem value={"Discovery Call Cancelled"}>Discovery Call Cancelled <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#ff9187"}}></p></MenuItem>
                        <MenuItem value={"Discovery Call Missed"}>Discovery Call Missed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#8a3932"}}></p></MenuItem>
                        <MenuItem value={"Contract Sent"}>Contract Sent <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#52ff8b"}}></p></MenuItem>
                        <MenuItem value={"Contract Review Scheduled"}>Contract Review Scheduled <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#588767"}}></p></MenuItem>
                        <MenuItem value={"Contract Review Completed"}>Contract Review Completed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#e0ffea"}}></p></MenuItem>
                        <MenuItem value={"Contract Signed"}>Contract Signed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#4df7cd"}}></p></MenuItem>
                        <MenuItem value={"Contract Completed"}>Contract Completed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#649c8e"}}></p></MenuItem>
                        <MenuItem value={"Data Call Requested"}>Data Call Requested <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#cfffd6"}}></p></MenuItem>
                        <MenuItem value={"Data Call Scheduled"}>Data Call Scheduled <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#708c74"}}></p></MenuItem>
                        <MenuItem value={"Data Call Completed"}>Data Call Completed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#276630"}}></p></MenuItem>
                        <MenuItem value={"Link Sent"}>Link Sent <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#a6ff29"}}></p></MenuItem>
                        <MenuItem value={"Has Contracts No Claims Uploaded"}>Has Contracts No Claims Uploaded <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#809149"}}></p></MenuItem>
                        <MenuItem value={"Has Claims No Contracts Uploaded"}>Has Claims No Contracts Uploaded <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#465221"}}></p></MenuItem>
                        <MenuItem value={"Data and Contracts Upload Completed"}>Data and Contracts Upload Completed <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#8eff38"}}></p></MenuItem>
                        <MenuItem value={"Initial Evaluation"}>Initial Evaluation <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#dcf0ce"}}></p></MenuItem>
                        <MenuItem value={"On Hold"}>On Hold <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#63c0e6"}}></p></MenuItem>
                        <MenuItem value={"Collection Begun"}>Collection Begun <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#78fadc"}}></p></MenuItem>
                        <MenuItem value={"Follow-Up Collection"}>Follow-Up Collection <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#386b5f"}}></p></MenuItem>
                        <MenuItem value={"In Settlement"}>In Settlement <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#cfe35f"}}></p></MenuItem>
                        <MenuItem value={"Account Cancelled"}>Account Cancelled <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#848f45"}}></p></MenuItem>
                        <MenuItem value={"Lost"}>Lost <p className="disposition_color_for_dropdown" style={{"backgroundColor":"#f5b5b3"}}></p></MenuItem>
                      </Select>
                      <div className="Formsubmit_button">
                        <Button
                          variant="contained"
                          onClick={() => handleSubmitdisp()}
                        >
                          Submit
                        </Button>
                      </div>
                    </FormControl>
                  </Box>
                </div>
              </div>
              {dispositionSucessLoader ? (
                <Box
                  className="loaderProgress"
                  sx={{ width: "100%", mt: "10px" }}
                >
                  <LinearProgress />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Modal>
          
          {/* *********************Set Prioirty Modal**************** */}
          <Modal
            className="modalBaseStyle Alldisposition_Modal setPriorityMdl"
            open={priorityModalOpen}
            onClose={CloseSetPriorityModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={CloseSetPriorityModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="priority_Box_Main">
                <h2 className="previewModalTitle ">
                  <div className="modal_title modal_titlenew">
                    {" "}
                    Set Priority{" "}
                  </div>
                </h2>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Set Priority
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={managePriority}
                      // label="Manage Set Priority"
                      onChange={handleChangeOnPriority}
                      // input={<OutlinedInput label="Manage Set Priority" />}
                      MenuProps={MenuProps}
                    >
                      {selectpriorities.map((name) => (
                        <MenuItem key={name} value={name}>
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {priorityLoader && (
                  <Box
                    className="loaderProgress"
                    sx={{ width: "100%", mt: "10px" }}
                  >
                    <LinearProgress />
                  </Box>
                )}
              </div>
            </Box>
          </Modal>

          {/* ************************Manage Dist Modal*******************************/}

          <Modal
            className="modalBaseStyle disposition_Modal notesModal_blcoast"
            open={allDispositionModalOpen}
            onClose={handleCloseAllDispositionModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseAllDispositionModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="showDispositionMap modalview_group">
                <h2 className="previewModalTitle ">
                  {/* {ipInfo?.fullname && <div className='modal_title modal_titlenew'> Manage Disposition for {ipInfo.fullname}</div>} */}
                  <div className="modal_title modal_titlenew">
                    {" "}
                    All Disposition{" "}
                  </div>
                  {/* <p>{JSON.stringify(allDispositionData[0])}</p> */}
                </h2>
                <Box>
                  {allDispositionData &&
                    allDispositionData.length > 0 &&
                    allDispositionData[0]?.disposition_data ? (
                    allDispositionData[0]?.disposition_data
                      ?.slice(0)
                      .reverse()
                      .map((item) => (
                        <span className="tagChipwrp">
                          

                          <Card sx={{ minWidth: 275 }}>
                            <CardContent key={item.addedby}>
                            
                              <p> Disposition Name: {item?.disposition_name}</p>
                              <p> Added by: {item.addedby_name}</p>
                              <p> Date: {item.action_added_time_human}</p>
                          
                            </CardContent>
                        
                          </Card>
                        
                        </span>
                      ))
                  ) : (
                    <div className="nodatafound">No Records Found</div>
                  )}
                </Box>
              </div>
            </Box>
          </Modal>

          {/* ************************************Notes AE/Rep Modal******************* */}
          <Modal
            className="notesModal_blcoast"
            open={notesforrepmodal}
            onClose={handleCloseRepNotesModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseRepNotesModal}
                  // className="CloseModal"
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="innerWrapperfor_notes">
                <div className="rolelist_mainwrp">
                  <h2 className="previewModalTitle">
                    {ipInfo?.fullname && (
                      <div className="modal_title">
                        Notes for AE/REP ({ipInfo?.fullname})
                      </div>
                    )}
                    {/* <h4>{JSON.stringify(ipInfo)}</h4> */}
                  </h2>
                  <div className="rolelist_mainwrp_Table">
                    {/* <p>{JSON.stringify(bluecoastrepnotedata)}</p> */}

                    {!loadinginRepnotes &&
                      bluecoastrepnotedata &&
                      bluecoastrepnotedata.length > 0 && (
                        <ListingTable
                          tableData={tableDataForRepNotes.current}
                          dataset={bluecoastrepnotedata}
                          modifyHeaders={modifyTableHeadersforRepnotes}
                        />
                      )}
                    {loadinginRepnotes && <TableSkeleton count={5} />}
                  </div>
                </div>
                {/* {JSON.stringify(textareayvalueforrep.current)} */}
                {userInfo &&
                  Object.keys(userInfo).length > 0 &&
                  userInfo.main_role == "special_entities" && (
                    <div>
                      <div className="Notes_Form">
                        <p>Notes</p>
                        <TextareaAutosize
                          placeholder={"Type here..."}
                          ref={inputRef}
                          value={textareayvalueforrep.current}
                          onChange={(e) => {
                            textareayvalueforrep.current = e.target.value;
                          }}
                        />
                      </div>
                      <div></div>
                      <div>
                        <button
                          className="blucoastList_specialbtn"
                          onClick={() => addnoteforrep()}
                        >
                          Add Note
                        </button>
                        {scratchaddnoteflag && (
                          <Box
                            className="loaderProgress"
                            sx={{ width: "100%", mt: "10px" }}
                          >
                            <LinearProgress />
                          </Box>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </Box>
          </Modal>

          {/* ************************************Notes AE/Rep Modal for pharmacy bluecoast and aspire******************* */}

          {/* **********************************Transfer Ownership Modal******************* */}
          <Modal
            className="modalBaseStyle Alldisposition_Modal Ownership_trnsfer notesModal_blcoast"
            open={transferOwnershipModlaOpen}
            onClose={CloseOwnershipModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={CloseOwnershipModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="priority_Box_Main">
                <h2 className="previewModalTitle ">
                  <div className="modal_title modal_titlenew">
                    {" "}
                    Transfer Ownership
                  </div>
                </h2>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Transfer Ownership
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={changeOwnerID}
                      label="Manage Set Priority"
                      onChange={handleOwnershipChange}
                      MenuProps={MenuProps}
                    >
                      {spicialusersall &&
                        spicialusersall.length > 0 &&
                        spicialusersall.map((data, idx) => (
                          <MenuItem key={idx} value={data._id}>
                            <ListItemText
                              primary={data.firstname + " " + data.lastname}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                    <div className="transfer_owner_notes">
                      <TextareaAutosize
                        label="Add Notes"
                        aria-label="minimum height"
                        minRows={4}
                        placeholder="Add Notes..."
                        // value={addNotesTransferOwner}
                        ref={tranceFormOwershipTextValue}
                        onChange={(e) =>
                          (tranceFormOwershipText.current = e.target.value)
                        }
                        style={{ width: 800 }}
                      />
                    </div>

                    <div className="Formsubmit_button">
                      <Button
                        variant="contained"
                        onClick={() => changeOwnershp()}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormControl>
                </Box>

                {ownerShipLoader && (
                  <Box
                    className="loaderProgress"
                    sx={{ width: "100%", mt: "10px" }}
                  >
                    <LinearProgress />
                  </Box>
                )}
              </div>
            </Box>
          </Modal>

          {/* *************Send Notification to Beto Modal************** */}
          <Modal
            className="modalBaseStyle sendEmail_modal notesModal_blcoast"
            open={sendEmailtoSpecialEntity}
            onClose={handleCloseEmailtoSpecialEntity}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={() => handleCloseEmailtoSpecialEntity()}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {/* <h2 className='previewModalTitle modal_title'>
                {(changeOwnerID == 'Beto Parades' || changeOwnerID == 'Travis Baggett') && "Are you want send notification?"}
              </h2> */}
              <div className="notes_main_header">
                <p>Attention!</p>
                <div className="modalview_group">
                  <p>Send Contact Email to AE?</p>
                  <div className="confirmationBtns">
                    <button
                      className="blucoastList_specialbtn"
                      onClick={() => ChooseSendEmail("Beto")}
                    >
                      Yes
                    </button>
                    <button
                      className="blucoastList_specialbtn"
                      onClick={() => handleCloseEmailtoSpecialEntity()}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/* *************Send Notification to Travis Modal************** */}
          <Modal
            className="modalBaseStyle sendEmail_modal notesModal_blcoast"
            open={sendEmailtoTravisModal}
            onClose={handleCloseEmailtoTravis}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={() => handleCloseEmailtoTravis()}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="notes_main_header">
                <p>Attention!</p>
                <div className="modalview_group">
                  <p>Send Contact Email to AE?</p>
                  <div className="confirmationBtns">
                    <button
                      className="blucoastList_specialbtn"
                      // onClick={() => ChooseSendEmail("Travis")}
                      onClick={() => ChooseSendEmail("Jeannette")}
                    >
                      Yes
                    </button>
                    <button
                      className="blucoastList_specialbtn"
                      onClick={handleCloseEmailtoTravis}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/* *************Send Notification to Beto PROD************** */}
          <Modal
            className="modalBaseStyle sendEmail_modal notesModal_blcoast"
            open={sendMailBetoModal}
            onClose={handleCloseBetoModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseBetoModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="notes_main_header">
                <p>Attention!</p>
                <div className="modalview_group">
                  <p>Send Contact Email to AE?</p>
                  <div className="confirmationBtns">
                    <button
                      className="blucoastList_specialbtn"
                      onClick={() => sendMailApiCall("Beto")}
                    >
                      Yes
                    </button>
                    <button
                      className="blucoastList_specialbtn"
                      onClick={handleCloseBetoModal}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/* *************Send Notification to Travis PROD************** */}
          <Modal
            className="modalBaseStyle sendEmail_modal notesModal_blcoast"
            open={sendMailTravisModal}
            onClose={handleClosetravisModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleClosetravisModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="notes_main_header">
                <p>Attention!</p>
                <div className="modalview_group">
                  <p>Send Contact Email to AE?</p>
                  <div className="confirmationBtns">
                    <button
                      className="blucoastList_specialbtn"
                      // onClick={() => sendMailApiCall("Travis")}
                      onClick={() => sendMailApiCall("Jeannette")}
                    >
                      Yes
                    </button>
                    <button
                      className="blucoastList_specialbtn"
                      onClick={handleClosetravisModal}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          {/* *********Modal For Disposition Selected Value******  */}

          <Modal
            className="modalBaseStyle setTime_Modal notesModal_blcoast"
            open={dispositionSelectValueModal}
            onClose={handleCloseDistSelectValueModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseDistSelectValueModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 className="previewModalTitle modal_title">
                {manageDist == "Discovery Call Set"
                  ? "Discovery Call Scheduled"
                  : manageDist == "Advisor/AE Meeting Set"
                    ? "Advisor/AE Meeting Scheduled"
                    : manageDist}
              </h2>
              <div>
                <Form formData={dateTimeForm} />
              </div>
            </Box>
          </Modal>

          {/* *********Modal For Disposition List***********  */}

          <Modal
            className="modalBaseStyle allMeetingMOdal notesModal_blcoast"
            open={dispositionCallListModalOpen}
            onClose={handleCloseDispositionListModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalBaseBox mediumModal">
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseDispositionListModal}
                  className="modal_icon_button"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div className="rolelist_mainwrp">
                <h2 className="previewModalTitle">
                  <div className="modal_title">All Meeting List </div>
                </h2>
                <div className="rolelist_mainwrp_Table">
                  {!loadinginDispCall &&
                    dispositionCallData &&
                    dispositionCallData.length > 0 ? (
                    <ListingTable
                      tableData={tableDataForDispCall.current}
                      dataset={dispositionCallData}
                      modifyHeaders={modifyTableHeadersforDispCall}
                    />
                  ) : (
                    <div>
                      <h3>No Records Found</h3>
                    </div>
                  )}

                  {loadinginDispCall && <TableSkeleton count={5} />}
                </div>
              </div>
            </Box>
          </Modal>
        </div>

        {/* <div className='loadingforredireect'>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </div> */}
      </div>

      {/* *********Preview modal for lead  List***********  */}

      <div>
        <Modal
          className="modalBaseStyle  preview_details"
          open={previewModal}
          onClose={handleClosePreview}
        >
          <Box className="modalBaseBox mediumModal">
            <Tooltip title="Close">
              <IconButton
                onClick={handleClosePreview}
                // className="CloseModal"
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className="view_details">
              {/* <h2 className="previewModalTitle ">
                <div className="modal_title modal_titlenew"> Lead Details </div>

                <Button id="create_pdf" onClick={() => printFunction()}>
                  Button
                </Button>
              </h2> */}

              <div className="modal_title modal_titlenew SD_bluecoast_preview">
                {" "}
                {/* <h6>{JSON.stringify(previewModalData)}</h6> */}
                <h2 className="previewModalTitle "> Lead Details</h2>
                <div className="icon_wrppr_preiew">
                  <Tooltip title="Download">
                    <Button
                      id="create_pdf"
                      onClick={() => printFunction(previewModalData?.fullname)}
                    >
                      <DownloadIcon />
                    </Button>
                    {/* <button type="button" onClick={Print} > Print div</button> */}
                  </Tooltip>
                  <Tooltip title="Notes Details">
                    <Button
                      onClick={() => notesDetailsPreview()}
                      className="modalBTN"
                    >
                      <SummarizeIcon />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              {/* <p>{JSON.stringify(previewNotesPropspect)}</p> */}
              <div className="modalview_group">
                {previewModalData ? (
                  <div className="data_container data_containerscroll">
                    {/* <h6>{JSON.stringify(previewModalData)}</h6> */}

                    <div className="data_block">
                      {previewModalData?.fullname && (
                        <div className="property_container">
                          <label className="property_label">Name:</label>
                          <label className="property_value">
                            {previewModalData.fullname}
                          </label>
                        </div>
                      )}
                      {previewModalData?.email && (
                        <div className="property_container">
                          <label className="property_label">Email:</label>
                          <label className="property_value">
                            {previewModalData.email}
                          </label>
                        </div>
                      )}
                      {previewModalData?.phone && (
                        <div className="property_container">
                          <label className="property_label">
                            Phone Number:
                          </label>
                          <label className="property_value">
                            {previewModalData.phone}
                          </label>
                        </div>
                      )}

                      {/* <p>{JSON.stringify(previewModalData.state)}</p> */}

                      {/* <p>{JSON.stringify(previewModalData.prospectbusinessname)}</p>
                    <p>{JSON.stringify(previewModalData.bluecoastphblue)}</p> */}

                      {previewModalData?.prospectbusinessname && (
                        <div className="property_container">
                          <label className="property_label">
                            {previewModalData?.prospectbusinessname &&
                              (previewModalData.bluecoastPh === true ||
                                previewModalData.bluecoastphblue === true)
                              ? "Potential Pharmacy Partner Name:"
                              : "Prospect's Medical Facility Business Name:"}
                          </label>
                          <label className="property_value">
                            {previewModalData.prospectbusinessname}
                          </label>
                        </div>
                      )}

                      {previewModalData?.prospecttitle && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Title:
                          </label>
                          <label className="property_value">
                            {previewModalData.prospecttitle}
                          </label>
                        </div>
                      )}
                      {previewModalData?.rep_name && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Title:
                          </label>
                          <label className="property_value">
                            {previewModalData.rep_name}
                          </label>
                        </div>
                      )}
                      {previewModalData?.prospectfullname && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Name:
                          </label>
                          <label className="property_value">
                            {previewModalData.prospectfullname}
                          </label>
                        </div>
                      )}
                      {previewModalData?.prospectemail && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Email:
                          </label>
                          <label className="property_value">
                            {previewModalData.prospectemail}
                          </label>
                        </div>
                      )}
                      {previewModalData?.prospectcontactnumber && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Phone Number:
                          </label>
                          <label className="property_value">
                            {previewModalData.prospectcontactnumber}
                          </label>
                        </div>
                      )}

                      {previewModalData?.city && (
                        <div className="property_container">
                          <label className="property_label">City:</label>
                          <label className="property_value">
                            {previewModalData.city}
                          </label>
                        </div>
                      )}

                      {previewModalData?.state && (
                        <div className="property_container">
                          <label className="property_label">State:</label>
                          <label className="property_value">
                            {previewModalData.state}
                          </label>
                        </div>
                      )}

                      {/* <p>{JSON.stringify(previewModalData.medicalfacilitytype)}</p> */}
                      {/* {previewModalData?.medicalfacilitytype && <div className='property_container'><label className='property_label'>{previewModalData?.medicalfacilitytype && (previewModalData.bluecoastPh === true || previewModalData.bluecoastphblue === true) ? 'Type of business or group:' : "Type of medical facility:"}</label><label className='property_value'>{previewModalData.medicalfacilitytype}</label></div>} */}

                      {previewModalData?.medicalfacilitytype &&
                        previewModalData?.medicalfacilitytype == "Other" ? (
                        <div className="property_container">
                          <label className="property_label">
                            {previewModalData?.medicalfacilitytype &&
                              (previewModalData.bluecoastPh === true ||
                                previewModalData.bluecoastphblue === true)
                              ? "Type of business or group:"
                              : "Type of medical facility:"}
                          </label>
                          <label className="property_value">
                            {previewModalData.other}
                          </label>
                        </div>
                      ) : (
                        previewModalData?.medicalfacilitytype && (
                          <div className="property_container">
                            <label className="property_label">
                              {previewModalData?.medicalfacilitytype &&
                                (previewModalData.bluecoastPh === true ||
                                  previewModalData.bluecoastphblue === true)
                                ? "Type of business or group:"
                                : "Type of medical facility:"}
                            </label>
                            <label className="property_value">
                              {previewModalData.medicalfacilitytype}
                            </label>
                          </div>
                        )
                      )}

                      {previewModalData?.natureofrelationship && (
                        <div className="property_container">
                          <label className="property_label">
                            Relationship With the Prospect:
                          </label>
                          <label className="property_value">
                            {previewModalData.natureofrelationship}
                          </label>
                        </div>
                      )}
                      {previewModalData?.relationshipdescription && (
                        <div className="property_container">
                          <label className="property_label">
                            Relationship Description:
                          </label>
                          <label className="property_value">
                            {previewModalData.relationshipdescription}
                          </label>
                        </div>
                      )}

                      {previewModalData?.approxgrossreciept && (
                        <div className="property_container">
                          <label className="property_label">
                            {previewModalData?.approxgrossreciept &&
                              (previewModalData.bluecoastphblue !== true ||
                                previewModalData.bluecoastPh !== true) &&
                              "Annual Revenue Must be a minimum of $50M:"}
                          </label>
                          <label className="property_value">
                            {previewModalData.approxgrossreciept}
                          </label>
                        </div>
                      )}

                      {previewModalData?.subscription_membership && (
                        <div className="property_container">
                          <label className="property_label">
                            {previewModalData?.subscription_membership &&
                              (previewModalData.bluecoastPh === true ||
                                previewModalData.bluecoastphblue === true) &&
                              "Subscription Membership Opportunity Lead:"}
                          </label>
                          <label className="property_value">
                            {previewModalData.subscription_membership == true
                              ? "Yes (this group has a reach of 250+)"
                              : "No"}
                          </label>
                        </div>
                      )}

                      {previewModalData?.number_of_people_type && (
                        <div className="property_container">
                          <label className="property_label">
                            {previewModalData?.number_of_people_type &&
                              (previewModalData.bluecoastPh === true ||
                                previewModalData.bluecoastphblue === true) &&
                              "Number of Members or Employees:"}
                          </label>
                          <label className="property_value">
                            {previewModalData.number_of_people_type}
                          </label>
                        </div>
                      )}

                      {previewModalData?.company_website && (
                        <div className="property_container">
                          <label className="property_label">Website:</label>
                          <label className="property_value">
                            {previewModalData.company_website}
                          </label>
                        </div>
                      )}

                      {previewModalData?.other_info && (
                        <div className="property_container">
                          <label className="property_label">
                            Any other information we should know?:
                          </label>
                          <label className="property_value">
                            {previewModalData.other_info}
                          </label>
                        </div>
                      )}
                      {/* {previewModalData?.medicalfacilitytype && <div className='property_container'><label className='property_label'>Type of medical facility:</label><label className='property_value'>{previewModalData.medicalfacilitytype}</label></div>} */}

                      {/* <p>{JSON.stringify(previewModalData.subscription_membership)}</p> */}

                      {previewModalData?.extensive_notes && (
                        <div className="property_container">
                          <label className="property_label">
                            {" "}
                            Extensive notes to be reviewed:
                          </label>
                          <label className="property_value">
                            {previewModalData.extensive_notes}
                          </label>
                        </div>
                      )}
                      {previewModalData?.createdon_datetime && (
                        <div className="property_container">
                          <label className="property_label">Added On:</label>
                          <label className="property_value">
                            {format(
                              previewModalData.createdon_datetime,
                              "dd-MM-yyyy HH:mm"
                            )}
                          </label>
                        </div>
                      )}

                    </div>
                  </div>
                ) : (
                  <div className="nodatafound">No Records Found</div>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* ********************************** Delete Modal ***************************** */}
      <div>
        <Modal
          className="modalblock"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          open={deliteModal}
          onClose={CloseDeleteModal}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              border: "10px solid #0569ad!important",
              background: "#fff",
              boxShadow: 24,
              textAlign: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h5" component="h5">
              <p className="alertPara2"> Alert!</p>
              {saveRowdata?.prospectfullname && (
                <p>
                  Are you sure that you want to delete this record(s) for{" "}
                  {saveRowdata.prospectfullname}?
                </p>
              )}
              {/* <p>{JSON.stringify(saveRowdata)}</p> */}
            </Typography>
            <div style={{ padding: "20px" }}>
              <Button
                variant="contained"
                onClick={handelBlucoastLeadStatus}
                className="modalBTN"
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={CloseDeleteModal}
                className="modalBTN"
              >
                No
              </Button>
            </div>
            {statusTogalloading ? <LinearProgress /> : ""}
            <Button onClick={CloseDeleteModal} className="closeModalBtn">
              <Tooltip title="Close" placement="right-start">
                <CloseIcon />
              </Tooltip>
            </Button>
          </Box>
        </Modal>
      </div>

      {/* ---------------------------------This model is for  payment Detail List------------------------  */}

      {/* ********************* Payment Details Modal**************** */}

      <Modal
        className="modalBaseStyle Alldisposition_Modal notesModal_blcoast payment_modal_mt"
        open={paymentDetailsModal}
        onClose={closePymentDetals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={closePymentDetals}
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div className="priority_Box_Main op_modal_mt">
            <h2 className="previewModalTitle ">
              {
                <div className="modal_title modal_titlenew">
                  {" "}
                  Opportunity Report{" "}
                </div>
              }
            </h2>
            {paymentloading && <LinearProgress />}

            <div className="rolelist_mainwrp_Table">
              {bluecoastPaymentList ? (
                <ListingTable
                  tableData={tableDataForUsersPayment.current}
                  dataset={bluecoastPaymentList}
                  modifyHeaders={paymentModifyTableHeaders}
                />
              ) : (
                <TableSkeleton count={5} />
              )}
            </div>

            <div>
              <ul className="total_bill_mt">
                <li>
                  <span className="total_bill_mt_text">
                    {" "}
                    Total Annual Billing{" "}
                  </span>{" "}
                  <span className="total_bill_mt_amount">
                    {singalAnnualBill !== null && singalAnnualBill !== undefined
                      ? singalAnnualBill
                      : ""}
                  </span>
                </li>
                <li>
                  <span className="total_bill_mt_text">
                    {" "}
                    Total Under Payment{" "}
                  </span>{" "}
                  <span className="total_bill_mt_amount">
                    {" "}
                    {sinaglUnderPayment !== null &&
                      sinaglUnderPayment !== undefined
                      ? sinaglUnderPayment
                      : ""}
                  </span>
                </li>
              </ul>
            </div>

            {/* {loaderannulBillingValue && (<Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}><LinearProgress /></Box>)} */}
          </div>
        </Box>
      </Modal>

      {/* ======================================================================================================== */}

      {/* ----------------------------------Payment preview Modal -------------------------------------------- */}

      <div>
        <Modal
          className="modalBaseStyle  preview_details"
          open={paymentPreviewModal}
          onClose={handleClosePreview}
        >
          <Box className="modalBaseBox mediumModal">
            <Tooltip title="Close">
              <IconButton
                onClick={handleClosePreview}
                // className="CloseModal"
                className="modal_icon_button"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <div className="view_details">
              <h2 className="previewModalTitle ">
                <div className="modal_title modal_titlenew"> Lead Details </div>
              </h2>

              <div className="modalview_group">
                {paymentRowData ? (
                  <div className="data_container data_containerscroll">
                    <div className="data_block">
                      {paymentRowData?.fullname && (
                        <div className="property_container">
                          <label className="property_label">Name:</label>
                          <label className="property_value">
                            {paymentRowData.fullname}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.phone && (
                        <div className="property_container">
                          <label className="property_label">
                            Phone Number:
                          </label>
                          <label className="property_value">
                            {paymentRowData.phone}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.email && (
                        <div className="property_container">
                          <label className="property_label">Email:</label>
                          <label className="property_value">
                            {paymentRowData.email}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.prospectbusinessname && (
                        <div className="property_container">
                          <label className="property_label">
                            {paymentRowData?.prospectbusinessname &&
                              (paymentRowData.bluecoastPh === true ||
                                paymentRowData.bluecoastphblue === true)
                              ? "Potential Pharmacy Partner Name:"
                              : "Prospect's Medical Facility Business Name:"}
                          </label>
                          <label className="property_value">
                            {paymentRowData.prospectbusinessname}
                          </label>
                        </div>
                      )}

                      {paymentRowData?.prospecttitle && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Title:
                          </label>
                          <label className="property_value">
                            {paymentRowData.prospecttitle}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.rep_name && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Title:
                          </label>
                          <label className="property_value">
                            {paymentRowData.rep_name}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.prospectemail && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Email:
                          </label>
                          <label className="property_value">
                            {paymentRowData.prospectemail}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.prospectcontactnumber && (
                        <div className="property_container">
                          <label className="property_label">
                            Prospect's Phone Number:
                          </label>
                          <label className="property_value">
                            {paymentRowData.prospectcontactnumber}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.natureofrelationship && (
                        <div className="property_container">
                          <label className="property_label">
                            The Nature of Relationship With the Prospect:
                          </label>
                          <label className="property_value">
                            {paymentRowData.natureofrelationship}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.other_info && (
                        <div className="property_container">
                          <label className="property_label">
                            Any other information we should know?:
                          </label>
                          <label className="property_value">
                            {paymentRowData.other_info}
                          </label>
                        </div>
                      )}
                      {/* {paymentRowData?.medicalfacilitytype && <div className='property_container'><label className='property_label'>Type of medical facility:</label><label className='property_value'>{paymentRowData.medicalfacilitytype}</label></div>} */}

                      {paymentRowData?.medicalfacilitytype && (
                        <div className="property_container">
                          <label className="property_label">
                            {paymentRowData?.medicalfacilitytype &&
                              (paymentRowData.bluecoastPh === true ||
                                paymentRowData.bluecoastphblue === true)
                              ? "Type of business or group:"
                              : "Type of medical facility:"}
                          </label>
                          <label className="property_value">
                            {paymentRowData.medicalfacilitytype}
                          </label>
                        </div>
                      )}

                      {paymentRowData?.extensive_notes && (
                        <div className="property_container">
                          <label className="property_label">
                            {" "}
                            Extensive notes to be reviewed:
                          </label>
                          <label className="property_value">
                            {paymentRowData.extensive_notes}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.owner_name && (
                        <div className="property_container">
                          <label className="property_label"> Owner Name:</label>
                          <label className="property_value">
                            {paymentRowData.owner_name}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.annual_billing && (
                        <div className="property_container">
                          <label className="property_label">
                            {" "}
                            Annual Billing:
                          </label>
                          <label className="property_value">
                            {paymentRowData.annual_billing}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.under_payment && (
                        <div className="property_container">
                          <label className="property_label">
                            {" "}
                            Under Payment:
                          </label>
                          <label className="property_value">
                            {paymentRowData.under_payment}
                          </label>
                        </div>
                      )}
                      {paymentRowData?.createdon_datetime && (
                        <div className="property_container">
                          <label className="property_label">Added On:</label>
                          <label className="property_value">
                            {format(
                              paymentRowData.createdon_datetime,
                              "dd-MM-yyyy HH:mm"
                            )}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="nodatafound">No Records Found</div>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* ========================================================================================================== */}

      <DispositionAfterContract
        click={operational_dispositionClicked}
        rowData={rowData}
      />

      {notesModel && Object.keys(singalRowData).length > 0 && (
        <DNotes
          modalComonId={singalRowData._id}
          openMoadlforNotesUsertype={"lead"}
          gridData={singalRowData}
          openMoadl={notesModel}
          closeModal={setNotesModel}
          userName={{ fullname: singalRowData.fullname }}
          types="LeadNotes"
        />
      )}

      {/* -----------------------------------------configration of Payment Priority set------------------------------------ */}
      {paymentPriority && paymentRowData && (
        <PrioritySetForPharmacyAspire
          paymentPriority={paymentPriority}
          setPaymentPriority={setPaymentPriority}
          rowData={paymentRowData}
          setPaymentRowData={setPaymentRowData}
          setForLoading={setForLoading}
        />
      )}
      {/* =================================================================================================== */}

      {/* --------------------------- Payment Transfer Ownership configaration----------------------------------- */}

      {paymentTransferOwnerModal && paymentRowData && (
        <TransFerOwnerForPharmacyAspire
          ownerShipRowData={paymentRowData}
          paymentTransferOwnerModal={paymentTransferOwnerModal}
          setPaymentTransferOwnerModal={setPaymentTransferOwnerModal}
          spicialusersall={spicialusersall}
          setPaymentRowData={setPaymentRowData}
          setForLoading={setForLoading}
        />
      )}
      {/* ========================================================================================== */}

      {/* ------------------------------------Configration of Manage Dispotion for Payment modal list---------------------------- */}

      {paymentDisposition && paymentRowData && (
        <ManageDispositionForPharmacyAspire
          paymentDisposition={paymentDisposition}
          setPaymentDisposition={setPaymentDisposition}
          rowData={paymentRowData}
          setForLoading={setForLoading}
          setPaymentRowData={setPaymentRowData}
          setPaymentAnnulBillingModalOpen={setPaymentAnnulBillingModalOpen}
        />
      )}

      {/* ==================================================================================================== */}

      {paymentDispositionCycle && paymentRowData && allDispositionData && (
        <DispositionCycleForPharmacyAspire
          paymentDispositionCycle={paymentDispositionCycle}
          setPaymentDispositionCycle={setPaymentDispositionCycle}
          rowData={paymentRowData}
          allDispositionData={allDispositionData}
          setPaymentRowData={setPaymentRowData}
        />
      )}

      {/* ------------------------------------------------Configration Meeting list for Payment Modal list------------------------------- */}

      {paymentRowData && (
        <MeetingListForPharmacyAspire
          rowData={paymentRowData}
          click={dispositionCallButtonClickedForPharmaAspire}
          setPaymentRowData={setPaymentRowData}
        />
      )}

      {/* ======================================================================================================================================= */}

      {paymentNotesforRepmodal && paymentRowData && (
        <PaymentAeRepNotes
          rowdata={paymentRowData}
          notesforrepmodal={paymentNotesforRepmodal}
          setnotesforrepmodal={setPaymentNotesforRepmodal}
          setForLoading={setForLoading}
          setPaymentRowData={setPaymentRowData}
        />
      )}

      {paymentNotesModalOpen && paymentRowData && (
        <PaymentProspectNotes
          rowdata={paymentRowData}
          paymentNotesModalOpen={paymentNotesModalOpen}
          setPaymentNotesModalOpen={setPaymentNotesModalOpen}
          setForLoading={setForLoading}
          setPaymentRowData={setPaymentRowData}
        />
      )}

      {paymentIpInfoModal && paymentRowData && (
        <PaymentIpInfo
          paymentRowData={paymentRowData}
          paymentIpInfoModal={paymentIpInfoModal}
          setPaymentIpInfoModal={setPaymentIpInfoModal}
          setPaymentRowData={setPaymentRowData}
        />
      )}

      {/* ---------------------------------------Opportunity Report Total Gross Insurance Component configration---------------------- */}

      {paymentAnnulBillingModaOpenl && paymentRowData && (
        <PaymentTotalGrossInsu
          rowdata={paymentRowData}
          paymentAnnulBillingModaOpenl={paymentAnnulBillingModaOpenl}
          setPaymentAnnulBillingModalOpen={setPaymentAnnulBillingModalOpen}
          setForLoading={setForLoading}
          setPaymentRowData={setPaymentRowData}
        />
      )}
      {/* =============================================================================================================================== */}

      {/* ----------------------------------------Opportunity Report Underpayment Opportunity Component configration----------------------------------- */}

      {paymentUnderPaymentModaOpenl && paymentRowData && (
        <PaymentOpportunityUnderpayment
          rowdata={paymentRowData}
          paymentUnderPaymentModaOpenl={paymentUnderPaymentModaOpenl}
          setPaymentUnderPaymentModaOpenl={setPaymentUnderPaymentModaOpenl}
          setForLoading={setForLoading}
          setPaymentRowData={setPaymentRowData}
        />
      )}

      {/* ============================================================================================================================== */}

      {/* ---------------------------------------Notes View List--------------------------------------------------- */}
      {notesViewOpen && (
        <NotesView
          notesViewList={notesViewOpen}
          reqBody={tableDataForUsers.current.reqBody.searchcondition}
          setNotesViewOpen={setNotesViewOpen}
          setOpenAllNotesModalLoader={setOpenAllNotesModalLoader}
          menuSelect={menuSelect}
          notesReqBody={
            tableDataForBlueCoastAllNotes.current.reqBody.searchcondition
          }
          dispatchMainTable={tableDataForUsers.current.reqBody}
        // dispatchPorspectNote={tableDataForBlueCoastNotes.current.reqBody}
        />
      )}

      {/* ============================================================================================================================================= */}

      {/* -------------------------------------------------Sent Mail Full List to AEs------------------------------------------------ */}
      {sentMailAEsOpen && (
        <SendContactMailList
          sentMailAEsOpen={sentMailAEsOpen}
          SentMailAEsClose={setSentMailAEsOpen}
          rowData={saveRowdata}
          setsaveRowdata={setsaveRowdata}
        />
      )}

      {/* ================================================================================================================================================ */}

      {/* ---------------------------------------------Disposition Leads Summary---------------------------------------- */}
      {dispLeadCountMOdalOpn && (
        <DispositionLeadsSummary
          dispLeadCountMOdalOpn={dispLeadCountMOdalOpn}
          setDispLeadCountMOdalOpn={setDispLeadCountMOdalOpn}
          reqBody1={tableDataForUsers.current.reqBody.searchcondition}
          setMenuSelect={setMenuSelect}
          dispLeadsSummryBtnClicked={dispLeadsSummryBtnClicked}
          menuSelect={menuSelect}
        />
      )}

      {/*********************Disposition Task List Modal************* */}

      {dispoTaskListModalOpen && (
        <DispositionTaskList
          dispTaskOpen={dispoTaskListModalOpen}
          dispTaskClose={dispTaskListClose}
          rowData={saveRowdata}
          setsaveRowdata={setsaveRowdata}
        />
      )}

      {addTaskModalOpen && (
        <AddTaskForm
          taskFormOpen={addTaskModalOpen}
          taskFormClose={setAddTaskModalOpen}
          // tableIDtask={tableDataForAllTask.current.tableId = "alltaskdata"}
          editTaskbtn={editTaskBtnClicked}
          editRowtaskData={editDataTask}
        // taskaddcond={taskaddcond}
        />
      )}

      {taskStatusChangeModalOpen && (
        <TaskStatusChangeModal
          openStatusModal={taskStatusChangeModalOpen}
          taskStatusFormClose={handleCloseStatusUpdate}
          handleStatusChange={handleStatusUpdate}
          value={taskStatusValue}
        />
      )}

      {businessManagerModalOpen && (
        <BusinessManagerModal
          modalOpen={businessManagerModalOpen}
          setModalClose={businessManagerModalClose}
          rowData={saveRowdata}
          userData={user}
        />
      )}

      {/* ======================================================================================================================================= */}
      {snakebarModalStrate ? (
        <SnackBarComp
          setSnackFire={setSnakebarModalState}
          resMessage={snakebarMassage}
          snackFire={snakebarModalStrate}
          endpointResStatus={snakebarStatus}
        />
      ) : (
        ""
      )}

      {/*------------------image Modal---------------------------*/}
      <div>
        <Modal
          classname="last_note_modal modalBaseStyle"
          open={openImgModal}
          onClose={closeImageModalView}
          closeAfterTransition
          sx={{ overflow: "hidden", overflowY: "auto" }}
        >
          <Box
            className="modalBaseBox"
            sx={{ width: "750px", maxWidth: "95%" }}
          >
            <Tooltip title="Close">
              <IconButton
                className="modal_icon_button"
                onClick={closeImageModalView}
              >
                <CloseIcon className="modal_close_icon" />
              </IconButton>
            </Tooltip>

            {/* <h2 className="previewModalTitle ">
              {ipInfo?.prospectfullname && (
                <div className="modal_title modal_titlenew">
                  Last NOte for {ipInfo.prospectfullname}
                </div>
              )}
            </h2> */}

            {lastNoteOpen && (
              <div
                className="Notes_last"
                style={{
                  maxHeight: "450px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: lastNoteOpen }}></span>

                {/* <p>{lastNoteOpen}</p> */}
              </div>
            )}
          </Box>
        </Modal>
      </div>

      <div className="modalview_group pdfdownload" id="template1">
        <div className="modal_title modal_titlenew"> Lead Details </div>

        {previewModalData ? (
          <div className="data_container data_containerscroll">
            {/* <p>{JSON.stringify(previewModalData)}</p> */}

            <div className="data_block">
              {previewModalData?.fullname && (
                <div className="property_container">
                  <label className="property_label">Name:</label>
                  <label className="property_value">
                    {previewModalData.fullname}
                  </label>
                </div>
              )}
              {previewModalData?.email && (
                <div className="property_container">
                  <label className="property_label">Email:</label>
                  <label className="property_value">
                    {previewModalData.email}
                  </label>
                </div>
              )}
              {previewModalData?.phone && (
                <div className="property_container">
                  <label className="property_label">Phone Number:</label>
                  <label className="property_value">
                    {previewModalData.phone}
                  </label>
                </div>
              )}

              {/* <p>{JSON.stringify(previewModalData.prospectbusinessname)}</p>
                    <p>{JSON.stringify(previewModalData.bluecoastphblue)}</p> */}

              {previewModalData?.prospectbusinessname && (
                <div className="property_container">
                  <label className="property_label">
                    {previewModalData?.prospectbusinessname &&
                      (previewModalData.bluecoastPh === true ||
                        previewModalData.bluecoastphblue === true)
                      ? "Potential Pharmacy Partner Name:"
                      : "Prospect's Medical Facility Business Name:"}
                  </label>
                  <label className="property_value">
                    {previewModalData.prospectbusinessname}
                  </label>
                </div>
              )}

              {previewModalData?.prospecttitle && (
                <div className="property_container">
                  <label className="property_label">Prospect's Title:</label>
                  <label className="property_value">
                    {previewModalData.prospecttitle}
                  </label>
                </div>
              )}
              {previewModalData?.rep_name && (
                <div className="property_container">
                  <label className="property_label">Prospect's Title:</label>
                  <label className="property_value">
                    {previewModalData.rep_name}
                  </label>
                </div>
              )}
              {previewModalData?.prospectfullname && (
                <div className="property_container">
                  <label className="property_label">Prospect's Name:</label>
                  <label className="property_value">
                    {previewModalData.prospectfullname}
                  </label>
                </div>
              )}
              {previewModalData?.prospectemail && (
                <div className="property_container">
                  <label className="property_label">Prospect's Email:</label>
                  <label className="property_value">
                    {previewModalData.prospectemail}
                  </label>
                </div>
              )}
              {previewModalData?.prospectcontactnumber && (
                <div className="property_container">
                  <label className="property_label">
                    Prospect's Phone Number:
                  </label>
                  <label className="property_value">
                    {previewModalData.prospectcontactnumber}
                  </label>
                </div>
              )}

              {previewModalData?.city && (
                <div className="property_container">
                  <label className="property_label">City:</label>
                  <label className="property_value">
                    {previewModalData.city}
                  </label>
                </div>
              )}

              {previewModalData?.state && (
                <div className="property_container">
                  <label className="property_label">State:</label>
                  <label className="property_value">
                    {previewModalData.state}
                  </label>
                </div>
              )}

              {/* <p>{JSON.stringify(previewModalData.medicalfacilitytype)}</p> */}
              {/* {previewModalData?.medicalfacilitytype && <div className='property_container'><label className='property_label'>{previewModalData?.medicalfacilitytype && (previewModalData.bluecoastPh === true || previewModalData.bluecoastphblue === true) ? 'Type of business or group:' : "Type of medical facility:"}</label><label className='property_value'>{previewModalData.medicalfacilitytype}</label></div>} */}

              {previewModalData?.medicalfacilitytype &&
                previewModalData?.medicalfacilitytype == "Other" ? (
                <div className="property_container">
                  <label className="property_label">
                    {previewModalData?.medicalfacilitytype &&
                      (previewModalData.bluecoastPh === true ||
                        previewModalData.bluecoastphblue === true)
                      ? "Type of business or group:"
                      : "Type of medical facility:"}
                  </label>
                  <label className="property_value">
                    {previewModalData.other}
                  </label>
                </div>
              ) : (
                previewModalData?.medicalfacilitytype && (
                  <div className="property_container">
                    <label className="property_label">
                      {previewModalData?.medicalfacilitytype &&
                        (previewModalData.bluecoastPh === true ||
                          previewModalData.bluecoastphblue === true)
                        ? "Type of business or group:"
                        : "Type of medical facility:"}
                    </label>
                    <label className="property_value">
                      {previewModalData.medicalfacilitytype}
                    </label>
                  </div>
                )
              )}

              {previewModalData?.natureofrelationship && (
                <div className="property_container">
                  <label className="property_label">
                    Relationship With the Prospect:
                  </label>
                  <label className="property_value">
                    {previewModalData.natureofrelationship}
                  </label>
                </div>
              )}

              {previewModalData?.relationshipdescription && (
                <div className="property_container">
                  <label className="property_label">
                    Relationship Description:
                  </label>
                  <label className="property_value">
                    {previewModalData.relationshipdescription}
                  </label>
                </div>
              )}

              {previewModalData?.approxgrossreciept && (
                <div className="property_container">
                  <label className="property_label">
                    {previewModalData?.approxgrossreciept &&
                      (previewModalData.bluecoastphblue !== true ||
                        previewModalData.bluecoastPh !== true) &&
                      "Annual Revenue Must be a minimum of $50M:"}
                  </label>
                  <label className="property_value">
                    {previewModalData.approxgrossreciept}
                  </label>
                </div>
              )}

              {previewModalData?.subscription_membership && (
                <div className="property_container">
                  <label className="property_label">
                    {previewModalData?.subscription_membership &&
                      (previewModalData.bluecoastPh === true ||
                        previewModalData.bluecoastphblue === true) &&
                      "Subscription Membership Opportunity Lead:"}
                  </label>
                  <label className="property_value">
                    {previewModalData.subscription_membership == true
                      ? "Yes (this group has a reach of 250+)"
                      : "No"}
                  </label>
                </div>
              )}

              {previewModalData?.number_of_people_type && (
                <div className="property_container">
                  <label className="property_label">
                    {previewModalData?.number_of_people_type &&
                      (previewModalData.bluecoastPh === true ||
                        previewModalData.bluecoastphblue === true) &&
                      "Number of Members or Employees:"}
                  </label>
                  <label className="property_value">
                    {previewModalData.number_of_people_type}
                  </label>
                </div>
              )}

              {previewModalData?.company_website && (
                <div className="property_container">
                  <label className="property_label">Website:</label>
                  <label className="property_value">
                    {previewModalData.company_website}
                  </label>
                </div>
              )}

              {previewModalData?.other_info && (
                <div className="property_container">
                  <label className="property_label">
                    Any other information we should know?:
                  </label>
                  <label className="property_value">
                    {previewModalData.other_info}
                  </label>
                </div>
              )}
              {/* {previewModalData?.medicalfacilitytype && <div className='property_container'><label className='property_label'>Type of medical facility:</label><label className='property_value'>{previewModalData.medicalfacilitytype}</label></div>} */}

              {/* <p>{JSON.stringify(previewModalData.subscription_membership)}</p> */}

              {previewModalData?.extensive_notes && (
                <div className="property_container">
                  <label className="property_label">
                    {" "}
                    Extensive notes to be reviewed:
                  </label>
                  <label className="property_value">
                    {previewModalData.extensive_notes}
                  </label>
                </div>
              )}
              {previewModalData?.createdon_datetime && (
                <div className="property_container">
                  <label className="property_label">Added On:</label>
                  <label className="property_value">
                    {format(
                      previewModalData.createdon_datetime,
                      "dd-MM-yyyy HH:mm"
                    )}
                  </label>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="nodatafound">No Records Found</div>
        )}
      </div>
      {/* <button> <a href="www.google.com" target="_blank">dds</a></button> */}
      {/* <p>{JSON.stringify(annualrevenueBtnClicked)}</p> */}
    </>
  );
}

export default BlueCoastList;
