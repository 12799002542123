import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, IconButton, LinearProgress, Modal, Snackbar, TextField, TextareaAutosize, Typography } from "@mui/material";
import { timeConverter } from "../../../helper/helperFunctions";
import { addEditAttendeeData } from "../calenderReducer";
import { useDispatch, useSelector } from "react-redux";
import { stepUpFncEcecutn } from "../../login/loginReducer";
import CloseIcon from '@mui/icons-material/Close';
import { setEnableRowLoader, setReloadTableFlag } from "../../../listing/listReducer";
import { setSnackbar } from "../../Layout/layoutReducer";

const AddEditAttendeeModal = ({ attendeeOpen, setAttendeeOpen, rowData }) => {

  const dispatch = useDispatch();


  const [formArrayData, setFormArrayData] = useState({ 'shareholders': [{ 'name': "", 'email': "" }] })
  const [attendeeError, setAttendeeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState([])
  const [chekEmailState, setChekEmailState] = useState([])
  const [snackOpen, setSnackOpen] = useState(false)
  const snackMessage = useSelector(state => state.calenderEventsStore?.addAttendeeSuccess ? state.calenderEventsStore.addAttendeeSuccess : "")

  const attenLoaderSub = useSelector(state => state.calenderEventsStore?.attendeeLoader ? state.calenderEventsStore.attendeeLoader : false)

  useEffect(() => {
    // console.log("jfdfdh===>>>", snackMessage)
  }, [snackMessage])


  const userInfo = useSelector((state) =>
    state.loginSlice && state.loginSlice?.userInfo
      ? state.loginSlice?.userInfo
      : null
  );

  const currenttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const abbrs = {
    HST: 'Hawaii Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
    IST: 'India Standard Time',
    AKDT: 'Alaska Standard Time'
  };

  useEffect(() => {
    // console.log("dsgfgdfgdgh", attendeeOpen, rowData);
  }, [attendeeOpen, rowData])

  const handleModalClose = () => {
    setAttendeeOpen(false)
  }



  const handleShareholderNameChange = (e, index) => {
    let eventVal = e.target.value
    if (eventVal === '') {
      let mailindexerr = [...emailError]
      mailindexerr[index] = ""
      setEmailError([])
      let erraray = [...chekEmailState]
      erraray[index] = undefined
      setChekEmailState(erraray)
    }
    let currentObj = formArrayData.shareholders[index]
    if (currentObj && currentObj.email) {
      let erraray = [...chekEmailState]
      erraray[index] = true
      setChekEmailState(erraray)
      let error = validateEmail(currentObj.email);
      if (!error) {
        let mailindexerr = [...emailError]
        mailindexerr[index] = "Invalid email format"
        setEmailError(mailindexerr)
      } else {
        let mailindexerr = [...emailError]
        mailindexerr[index] = undefined
        setEmailError(mailindexerr)
        let erraray = [...chekEmailState]
        erraray[index] = undefined
        setChekEmailState(erraray)
      }
    } else {
      setEmailError([])
      setChekEmailState([])
    }
    // setEmailError
    const newShareholders = formArrayData.shareholders.map((shareholder, sidx) => {
      if (index !== sidx) return shareholder;
      return { ...shareholder, [e.target.name]: eventVal };
    });
    setFormArrayData({ shareholders: newShareholders });
    setAttendeeError(false);
  };



  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(String(email).toLowerCase());
  };


  useEffect(() => {
  }, [chekEmailState, emailError])



  const handleAddShareholder = () => {
    const attendeeNameNotExists = formArrayData.shareholders.find((data) => !data.name)
    const attendeeEmailNotExists = formArrayData.shareholders.find((data) => !data.email)
    if (attendeeNameNotExists) setAttendeeError(true)
    else if (attendeeEmailNotExists) setAttendeeError(true)
    else {
      let concatdata = { 'shareholders': formArrayData.shareholders.concat([{ name: "", email: "" }]), }
      setFormArrayData(concatdata);
      setAttendeeError(false);
    }
  };


  const handleRemoveShareholder = (index) => () => {
    setFormArrayData({ 'shareholders': formArrayData.shareholders.filter((s, sidx) => index !== sidx) });
  };


  useEffect(() => {
    if (rowData.atten_email.length > 0) {
      let array = [formArrayData.shareholders]
      rowData.atten_email.map((item, index) => {
        array[index] = { 'name': item.name, 'email': item.email }
      })
      setFormArrayData({ ...formArrayData, 'shareholders': array })
    }
  }, [rowData.atten_email])

  const attendeeFormSubmit = async () => {

    setLoading(true)
    let attaendeeDetailsData = [];
    for (let k in formArrayData.shareholders) {
      if ((formArrayData.shareholders[k].name !== null) & formArrayData.shareholders[k].name !== "") {
        attaendeeDetailsData.push({
          name: formArrayData.shareholders[k].name,
          email: formArrayData.shareholders[k].email
        })
      }
    }

    let newDataLoad = {
      "atten_email": attaendeeDetailsData,
      "identifier": rowData?.identifier
    }
    await dispatch(addEditAttendeeData(newDataLoad))
    await dispatch(stepUpFncEcecutn())

    setTimeout(() => {
      setAttendeeOpen(false)

    }, 800);

    dispatch(
      setSnackbar({
        open: true,
        message: "Attendee Details Updated Successfully!",
        status: "Re-schedule Event",
      })
    );
  }

  const handleCloseSnack = () => {
    setSnackOpen(false);
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnack}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Modal className="modalblock bookingModal attendeeModal" open={attendeeOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Button type="button" className="closeModalBtn" onClick={handleModalClose}>x</Button>
          <Box className="modalBox">
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: "18px" }}>{rowData.event_title}</Typography>
            <div id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="calender_block bookNowModal">
                <p> <span> Booking Date : </span> {timeConverter({ time: new Date(rowData.startUnixVal), format: "L", timezone: currenttimezone })}</p>
                <p> <span>Slot : </span> {timeConverter({ time: new Date(rowData.startUnixVal), format: "h:mm:ss A", timezone: currenttimezone })} - {timeConverter({ time: new Date(rowData.endUnixVal), format: "h:mm:ss A", timezone: currenttimezone })}</p>
                <p> <span>Timezone : </span> {abbrs[timeConverter({ time: new Date(rowData.endUnixVal), format: "zz", timezone: currenttimezone })]}</p>
              </div>
            </div>
            <div>
            </div>
            <h4>Add Attendee</h4>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ fontSize: 12 }} color="#005684">
                  <strong>Note*: "Type Your Name"</strong> & <strong>"Type Your Email"</strong> are mandatory to fill. After filling up, your name with email reflects on the organizer's Google calendar, and the organizer gets notified of your filled details.
                </Typography>
              </CardContent>
            </Card>
            <div className="addEmail_main" id="addEmail" >
              {formArrayData.shareholders.map((fields, index) => (
                <div className="shareholder addemailbox" key={index}>
                  <TextField className="addName" type="text" name="name" placeholder="Type your name" value={fields.name} onChange={(e) => handleShareholderNameChange(e, index)} />
                  <TextField className="addemail" type="text" name="email" placeholder="Type your email" value={fields.email} onChange={(e) => handleShareholderNameChange(e, index)} />
                  {/* helperText={emailError[index]} */}
                  {/* {chekEmailState[index] && <p> {emailError[index]} </p>} */}
                  <button type="button" onClick={handleRemoveShareholder(index)} className="small addemailBTN"> - </button>
                </div>
              ))}
            </div>
            {attendeeError && <p className="warn">Attendee email and name is mandatory to book an event</p>}
            <div className="modalSUBbtn_wrapper">
              <Button type="button" onClick={handleAddShareholder} className="small modalSUBbtn" >Add Attendee</Button>
            </div>
            {/*<h4>Notes</h4>*/}
            {/* <TextareaAutosize minRows={4} style={{ width: "100%", border: "solid 1px" }} value={noteData} onChange={(e) => handleNotesChange(e)} /> */}
            <div className="modalSUBbtn_wrapper">
              <Button className="modalSUBbtn" variant="contained" onClick={attendeeFormSubmit}>Submit</Button>
            </div>

            {attenLoaderSub ? <LinearProgress sx={{ marginTop: '10px' }} /> : ""}
          </Box>
        </Box>
      </Modal>
      {/*  */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
        message={snackMessage == "Re-schedule Event" ? "Attendee Details Updated Successfully" : "Kindly fill proper details"}
        action={action}
      />
    </>
  );
};

export default AddEditAttendeeModal;
